import React, { useCallback, useEffect, useState } from 'react'
import {
  Column,
  ErrorBox,
  GridItem,
  LoadingSpinner,
  Row,
  ShadowBox,
  Table,
  Text,
  TextLink,
  TableRow,
  TableCell,
  Grid,
  Header,
} from '@mattilsynet/mt-ui'
import { useDispatch } from 'react-redux'
import dayjs from 'dayjs'
import * as R from 'ramda'
import { useTypedSelector } from '../../common/custom-hooks'

import {
  meldingsHistorikkActions,
  meldingsHistorikkSelectors,
} from '../../ducks/meldingshistorikk'
import { goBack } from '../../common/common-router'
import { kodeverk } from '@mattilsynet/mt-common'
import { IMelding } from '../../ducks/melding/types'
import { FunnBox } from '../../components/funn-box'
import { findAktivitetForTilsynsobjekt } from '../../ducks/tilsynsobjekt/helpers'
import Observasjon = IKjoettkontrollFunnRemote.Observasjon
import {
  MeldingStatus,
  meldingStatusIsOneOf,
} from '../../ducks/melding/helpers'
import { getSortableTableHeader, sortByPath } from '../../common/sorting'
import { meldingSelectors } from '../../ducks/melding/selectors'
import { meldingActions } from '../../ducks/melding/actions'

export const MeldingsHistorikkForVirksomhet = ({ match }: { match: any }) => {
  const dispatch = useDispatch()
  const produsentTilsynsobjektId = match.params.produsentTilsynsobjektId
  const { loading, error, loaded, funnLoaded, tilsynUtfoertLoaded } =
    useTypedSelector(meldingsHistorikkSelectors.getLoadingStatus)
  const meldingsHistorikk = useTypedSelector(
    meldingsHistorikkSelectors.getMeldingsHistorikkForVirksomhet
  )
  const virksomhet = useTypedSelector(meldingsHistorikkSelectors.getVirksomhet)
  const openRows = useTypedSelector(meldingsHistorikkSelectors.getOpenRows)
  const allowedStatuses = useTypedSelector(meldingSelectors.getAllowedStatuses)
  const allowedStatusBegrunnelser = useTypedSelector(
    meldingSelectors.getAllowedStatusBegrunnelser
  )

  const { data: aktivitetKodeverk } = kodeverk.hooks.useKodeverk('AKTIVITET', {
    rootCode: 'DYR_MAT',
  })

  const [sortColumn, setSortColumn] = useState('sistRedigert')
  const [sortArrow, setSortArrow] = useState<'DOWN' | 'UP'>('DOWN')
  const [listSorted, setListSorted] = useState<IMelding[] | undefined>(
    meldingsHistorikk
  )

  const source = new URLSearchParams(location.search).get('source')

  useEffect(() => {
    if (meldingsHistorikk && meldingsHistorikk.length === 0) {
      setListSorted([])
    }

    if (virksomhet && aktivitetKodeverk?.length && meldingsHistorikk?.length) {
      const updatedListSorted = R.pipe(
        R.map((melding) => {
          const tilsynsobjekt = virksomhet?.tilsynsobjekter?.find(
            (tilsynsobjekt) =>
              tilsynsobjekt.idstring === melding.produsentTilsynsobjektId
          )
          const aktivitet = findAktivitetForTilsynsobjekt(
            tilsynsobjekt,
            aktivitetKodeverk
          )
          return { ...melding, aktivitet: aktivitet?.displayNames?.no }
        }),
        sortByPath(sortColumn.split('.'), sortArrow !== 'UP')
      )(meldingsHistorikk)

      setListSorted(updatedListSorted)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    virksomhet,
    meldingsHistorikk?.length,
    aktivitetKodeverk?.length,
    sortColumn,
    sortArrow,
    funnLoaded,
    tilsynUtfoertLoaded,
  ])

  const fetchData = useCallback(() => {
    dispatch(
      meldingsHistorikkActions.fetchMeldingsHistorikkForVirksomhet(
        produsentTilsynsobjektId
      )
    )
  }, [dispatch, produsentTilsynsobjektId])

  useEffect(() => {
    fetchData()
  }, [fetchData])

  const fetchAllowedStatuses = useCallback(() => {
    dispatch(meldingActions.fetchAllowedStatuses())
  }, [dispatch])

  const fetchAllowedStatusBegrunnelser = useCallback(() => {
    dispatch(meldingActions.fetchAllowedBegrunnelser())
  }, [dispatch])

  useEffect(() => {
    fetchAllowedStatuses()
    fetchAllowedStatusBegrunnelser()
  }, [fetchAllowedStatuses, fetchAllowedStatusBegrunnelser])

  const toggleOpenRow = (meldingId) => {
    const updatedOpenRows = openRows.includes(meldingId)
      ? openRows.filter((id) => id !== meldingId)
      : [...openRows, meldingId]
    dispatch(meldingsHistorikkActions.setOpenRows(updatedOpenRows))
  }

  const goBackdispatch = () => {
    dispatch(goBack('/'))
  }

  if (error) {
    return (
      <ErrorBox
        errorText="Kunne ikke laste inn meldingshistorikk."
        errorAction={() => fetchData()}
        errorActionText="Prøv å laste innhold på nytt"
      />
    )
  }
  if ((loading && !loaded) || !meldingsHistorikk || !listSorted) {
    return <LoadingSpinner title="Laster meldingshistorikk" />
  }

  const tableHeader = getSortableTableHeader(
    [
      { display: 'Mottatt', sortable: true, sortKey: 'sistRedigertAv' },
      { display: 'Aktivitet', sortable: true, sortKey: 'aktivitet' },
      { display: 'Kategori' },
      { display: 'Diagnose' },
      { display: 'Status', sortable: true, sortKey: 'vurdering.status' },
      { display: 'Saksnummer for melding' },
      { display: 'Saksnummer for oppfølging' },
      { display: '' },
    ],
    sortColumn,
    sortArrow,
    setSortColumn,
    setSortArrow
  )

  return (
    <Grid>
      <GridItem xl={[1, -1]} lg={[1, -1]} md={[1, -1]} sm={[1, -1]}>
        <Column spacing={0}>
          {source && (
            <GridItem as="nav">
              <Row margin={[0, 0, 1.5, 0]}>
                <TextLink onClick={goBackdispatch} leftArrow>
                  Tilbake
                </TextLink>
              </Row>
            </GridItem>
          )}
          <Column spacing={2}>
            <Row>
              <Header as="h1" size="heading1">
                Meldingshistorikk for virksomhet
              </Header>
            </Row>
            <ShadowBox>
              <Column spacing={1} padding={2.5} align="center">
                <Text
                  size="heading3"
                  uppercase
                  weight="bold"
                  margin={[0, 0, 1, 0]}
                >
                  {virksomhet?.navn}
                </Text>
                <Text size="heading3">
                  {(virksomhet?.tilsynsobjekter || [])
                    .filter((tilsynsobjekt) =>
                      findAktivitetForTilsynsobjekt(
                        tilsynsobjekt,
                        aktivitetKodeverk
                      )
                    )
                    .map((tilsynsobjekt) => {
                      const aktivitet = findAktivitetForTilsynsobjekt(
                        tilsynsobjekt,
                        aktivitetKodeverk
                      )
                      return aktivitet?.displayNames?.no || tilsynsobjekt.navn
                    })
                    .join(', ')}
                </Text>
                <Text size="heading3" weight="light">
                  Org.nr.: {virksomhet?.organisasjonsnummer}
                </Text>
              </Column>
            </ShadowBox>
            <ShadowBox>
              {loaded && meldingsHistorikk?.length === 0 ? (
                <Column padding={3} align="center">
                  <Text>Virksomheten har ingen meldinger</Text>
                </Column>
              ) : (
                <Table header={tableHeader}>
                  {listSorted?.map((melding: IMelding) => {
                    const showMore = openRows.some(
                      (id) => id === melding.meldingId
                    )
                    const observasjoner: Observasjon[] | undefined =
                      melding.funnList?.reduce(
                        (acc: Observasjon[], funn) =>
                          funn && funn.observasjoner
                            ? [...acc, ...funn.observasjoner]
                            : [...acc],
                        []
                      )
                    const diagnoser = R.pipe(
                      R.map((observasjon) => observasjon.diagnose?.beskrivelse),
                      R.uniq
                    )(observasjoner)

                    return (
                      <React.Fragment key={melding.meldingId}>
                        <TableRow
                          key={melding.meldingId}
                          header={tableHeader.map((item) =>
                            String(item.display)
                          )}
                        >
                          <TableCell>
                            {dayjs(melding.sistRedigert).format('D. MMM YYYY')}
                          </TableCell>
                          <TableCell>{melding.aktivitet}</TableCell>

                          <TableCell>
                            <Column spacing={1}>
                              {melding.kategorier.map((kategori) => (
                                <Column key={kategori.kode} spacing={1}>
                                  <Text>{kategori.beskrivelse}</Text>
                                </Column>
                              ))}
                            </Column>
                          </TableCell>
                          <TableCell>
                            <Column spacing={1}>
                              {diagnoser?.map((diagnose) => (
                                <Column key={diagnose}>
                                  <Text>{diagnose}</Text>
                                </Column>
                              ))}
                            </Column>
                          </TableCell>
                          <TableCell>
                            <Column spacing={2}>
                              <Text>
                                {
                                  allowedStatuses?.[
                                    melding?.vurdering?.status || ''
                                  ]
                                }
                              </Text>
                              {!meldingStatusIsOneOf(
                                melding?.vurdering?.status,
                                [MeldingStatus.NY]
                              ) && (
                                <Column>
                                  <Text weight="light">
                                    Status satt:{' '}
                                    {dayjs(
                                      melding?.vurdering?.sistRedigert
                                    ).format('DD.MM.YY')}
                                  </Text>
                                  <Text weight="light">
                                    Behandlet av:{' '}
                                    {melding?.vurdering?.sistRedigertAv}
                                  </Text>
                                </Column>
                              )}

                              {melding?.vurdering?.statusBegrunnelse && (
                                <div>
                                  <Text
                                    as="span"
                                    weight="light"
                                    margin={[0, 0.5, 0, 0]}
                                  >
                                    Begrunnelse:
                                  </Text>
                                  <Text as="span" weight="light">
                                    {allowedStatusBegrunnelser?.find(
                                      (begrunnelse) =>
                                        begrunnelse.status ===
                                        melding?.vurdering?.statusBegrunnelse
                                    )?.beskrivelse ||
                                      melding?.vurdering?.statusBegrunnelse}
                                  </Text>
                                </div>
                              )}

                              {melding?.tilsynskvitteringStatus && (
                                <Text weight="light">
                                  Tilsynskvittering:{' '}
                                  {melding?.tilsynskvitteringStatus?.status}
                                </Text>
                              )}
                            </Column>
                          </TableCell>
                          <TableCell>{melding.saksnummer}</TableCell>
                          <TableCell>
                            {melding?.vurdering?.tilknyttetSaksnummer ? (
                              <Text>
                                {melding?.vurdering?.tilknyttetSaksnummer}
                              </Text>
                            ) : (
                              <Text weight="light">Sak er ikke lagt til</Text>
                            )}
                          </TableCell>
                          <TableCell>
                            <TextLink
                              onClick={() => toggleOpenRow(melding.meldingId)}
                              small
                            >
                              {showMore ? 'Skjul melding' : 'Vis melding'}
                            </TextLink>
                          </TableCell>
                        </TableRow>
                        {showMore && (
                          <TableRow>
                            <TableCell colSpan={8}>
                              <Column padding={1}>
                                <ShadowBox>
                                  <Column padding={3} spacing={3}>
                                    <Column spacing={2}>
                                      <Text size="heading3" weight="bold">
                                        Avsender
                                      </Text>
                                      <Text>{melding.avsenderNavn}</Text>
                                    </Column>
                                    <Column spacing={2}>
                                      <Text size="heading3" weight="bold">
                                        Slakteri
                                      </Text>
                                      <Text>
                                        {melding.slakteriNavn} - Efta-nummer:{' '}
                                        {melding.eftanummer}
                                      </Text>
                                    </Column>

                                    <Column spacing={2}>
                                      <Text size="heading3" weight="bold">
                                        Tilsynsobjekt meldingen gjelder
                                      </Text>
                                      <Text>{melding.aktivitet}</Text>
                                    </Column>

                                    <Column spacing={2}>
                                      <Text size="heading3" weight="bold">
                                        Begrunnelse for bekymring
                                      </Text>
                                      <Text>{melding.begrunnelse}</Text>
                                    </Column>

                                    <Column spacing={2}>
                                      <Text size="heading3" weight="bold">
                                        Relevante funn
                                      </Text>
                                      {melding.funnList?.map?.((funn) => (
                                        <FunnBox
                                          key={funn.funnId}
                                          funn={funn}
                                        />
                                      ))}
                                    </Column>
                                  </Column>
                                </ShadowBox>
                              </Column>
                            </TableCell>
                          </TableRow>
                        )}
                      </React.Fragment>
                    )
                  })}
                </Table>
              )}
            </ShadowBox>
          </Column>
        </Column>
      </GridItem>
    </Grid>
  )
}
