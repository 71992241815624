import React, { useCallback, useContext, useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import * as R from 'ramda'
import {
  Button,
  ErrorBox,
  LoadingSpinner,
  Row,
  ShadowBox,
  ThemeContext,
} from '@mattilsynet/mt-ui'
import { kodeverk } from '@mattilsynet/mt-common'
import { useTypedSelector } from '../../common/custom-hooks'
import {
  meldingsHistorikkActions,
  meldingsHistorikkSelectors,
} from '../../ducks/meldingshistorikk'
import { IMelding, IMeldingState } from '../../ducks/melding/types'
import { getSortableTableHeader, sortByPath } from '../../common/sorting'
import { MeldingRow } from '../meldingshistorikk-for-rolle/melding-row'
import { StyledMeldingsHistorikkTable } from '../meldingshistorikk-for-rolle/historikk'

export const MineMeldinger = ({
  allowedStatuses,
  allowedStatusBegrunnelser,
}: {
  allowedStatuses: IMeldingState['allowedStatuses']
  allowedStatusBegrunnelser: IMeldingState['allowedStatusBegrunnelser']
}) => {
  const dispatch = useDispatch()
  const theme = useContext(ThemeContext)
  const { loading, error, loaded, funnLoaded, tilsynUtfoertLoaded } =
    useTypedSelector(meldingsHistorikkSelectors.getLoadingStatus)
  const mineMeldinger = useTypedSelector(
    meldingsHistorikkSelectors.getMineMeldinger
  )
  const pageMetaData = useTypedSelector(
    meldingsHistorikkSelectors.getMineMeldingerPageMetaData
  )
  const openRows = useTypedSelector(meldingsHistorikkSelectors.getOpenRows)
  const accessType = useTypedSelector(meldingsHistorikkSelectors.getAccessType)

  const { data: aktivitetKodeverk } = kodeverk.hooks.useKodeverk('AKTIVITET', {
    rootCode: 'DYR_MAT',
  })

  const [sortColumn, setSortColumn] = useState('sistRedigertDayjs')
  const [sortArrow, setSortArrow] = useState<'DOWN' | 'UP'>('DOWN')
  const [listSorted, setListSorted] = useState<IMelding[] | undefined>(
    mineMeldinger
  )

  const toggleOpenRow = (meldingId) => {
    const updatedOpenRows = openRows.includes(meldingId)
      ? openRows.filter((id) => id !== meldingId)
      : [...openRows, meldingId]
    dispatch(meldingsHistorikkActions.setOpenRows(updatedOpenRows))
  }

  const fetchData = useCallback(
    (fetchMore = false, fetchSilentUntilPage?: number) => {
      dispatch(
        meldingsHistorikkActions.fetchMineMeldinger(
          fetchMore,
          fetchSilentUntilPage
        )
      )
    },
    [dispatch]
  )

  useEffect(() => {
    fetchData()
  }, [fetchData])

  useEffect(() => {
    if (aktivitetKodeverk?.length && mineMeldinger?.length) {
      const updatedListSorted = R.pipe(
        R.map((melding) => {
          return {
            ...melding,
            aktivitet: melding?.tilsynsobjekt?.aktivitet?.beskrivelse,
          }
        }),
        sortByPath(sortColumn.split('.'), sortArrow !== 'UP')
      )(mineMeldinger)
      setListSorted(updatedListSorted)
    } else {
      setListSorted(mineMeldinger)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    mineMeldinger,
    aktivitetKodeverk?.length,
    sortColumn,
    sortArrow,
    funnLoaded,
    tilsynUtfoertLoaded,
  ])

  if (error) {
    return (
      <ErrorBox
        errorText="Kunne ikke laste inn meldinger."
        errorAction={() => fetchData()}
        errorActionText="Prøv å laste innhold på nytt"
      />
    )
  }
  if ((loading && !loaded) || !mineMeldinger || !listSorted) {
    return <LoadingSpinner title="Laster meldinger" />
  }

  const tableHeader = getSortableTableHeader(
    [
      { display: 'Mottatt' },
      {
        display: 'Virksomhet',
      },
      { display: 'Produsentnr.' },
      { display: 'Aktivitet' },
      { display: 'Kategori' },
      { display: 'Oppfølging' },
      { display: 'Ansvarlig' },
      { display: ' ' },
    ],
    sortColumn,
    sortArrow,
    setSortColumn,
    setSortArrow
  )

  return (
    <ShadowBox>
      <StyledMeldingsHistorikkTable header={tableHeader} theme={theme}>
        {listSorted?.map((melding: IMelding, index: number) => {
          return (
            <MeldingRow
              key={melding.meldingId}
              index={index}
              melding={melding}
              openRows={openRows}
              toggleOpenRow={toggleOpenRow}
              allowedStatuses={allowedStatuses}
              allowedStatusBegrunnelser={allowedStatusBegrunnelser}
              tableHeader={tableHeader}
              accessType={accessType}
            />
          )
        })}
      </StyledMeldingsHistorikkTable>

      {pageMetaData.number !== undefined &&
        pageMetaData.totalPages !== undefined &&
        pageMetaData?.number < pageMetaData?.totalPages - 1 && (
          <Row justify="center" padding={1}>
            <Button onClick={() => fetchData(true)} loading={loading}>
              Last inn neste 50 meldinger
            </Button>
          </Row>
        )}
    </ShadowBox>
  )
}
