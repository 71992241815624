import { IImage } from './types'

export const addOrUpdateImage = (
  imageList: IImage[],
  inputImage: IImage
): IImage[] => {
  const duplicateIndex = imageList.findIndex(
    (image) => image.id === inputImage.id
  )
  if (duplicateIndex === -1) {
    return [
      ...imageList,
      {
        ...inputImage,
      },
    ]
  } else {
    return imageList.map((image, index) => {
      if (index === duplicateIndex) {
        return {
          ...image,
          ...inputImage,
        }
      } else {
        return image
      }
    })
  }
}
