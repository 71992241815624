import React, { useCallback, useEffect, useState } from 'react'
import { ModalWrapper } from '../modal-wrapper'
import { meldingActions } from '../../ducks/melding/actions'
import { useDispatch } from 'react-redux'
import { useTypedSelector } from '../../common/custom-hooks'
import { meldingSelectors } from '../../ducks/melding/selectors'
import { FilterList, IDisplayAndValue } from '../filter-list'
import { Column } from '@mattilsynet/mt-ui'
import { sortByKey } from '../../common/sorting'
import { MeldingStatus } from '../../ducks/melding/helpers'
import { IMeldingFilterParams } from '../../common/melding-helpers'

export const OppfoelgingFilterModal = ({
  isOpen,
  onCancel,
  filterParams,
  setFilterParams,
}: {
  isOpen: boolean
  onCancel: () => void
  filterParams: IMeldingFilterParams
  setFilterParams: (params: IMeldingFilterParams) => void
}) => {
  const dispatch = useDispatch()
  const allowedStatuses = useTypedSelector(meldingSelectors.getAllowedStatuses)
  const fetchAllowedStatuses = useCallback(() => {
    dispatch(meldingActions.fetchAllowedStatuses())
  }, [dispatch])
  const [selected, setSelected] = useState<IDisplayAndValue[]>(
    filterParams.oppfoelginger
  )

  let oppfoelginger = Object.entries(allowedStatuses || {})
    .map(([value, display]) => ({
      value,
      display,
    }))
    .filter((o) => o.value !== MeldingStatus.UTKAST)
  oppfoelginger = sortByKey('display', false)([...oppfoelginger])

  const handleUpdateFilters = () => {
    setFilterParams({
      ...filterParams,
      oppfoelginger: selected,
    })
    onCancel()
  }

  useEffect(() => {
    fetchAllowedStatuses()
    const { oppfoelginger } = filterParams
    setSelected([...oppfoelginger])
  }, [fetchAllowedStatuses])

  const renderContent = () => {
    return (
      <Column>
        <FilterList
          treeData={oppfoelginger}
          onChange={(data) => setSelected(data)}
          selectedNodes={selected}
        />
      </Column>
    )
  }

  return (
    <ModalWrapper
      isOpen={isOpen}
      onCancel={onCancel}
      paddingHorizontal={0}
      title="Type oppfølging"
      buttonOkText="Bruk valgte filter"
      onOk={handleUpdateFilters}
    >
      <Column padding={[3, 0]}>{renderContent()}</Column>
    </ModalWrapper>
  )
}
