import { ofType, combineEpics, Epic } from 'redux-observable'
import { AnyAction } from 'redux'
import { mergeMap, catchError, withLatestFrom, switchMap } from 'rxjs/operators'
import { of, concat, EMPTY } from 'rxjs'
import { ICommonApi } from '../../api'
import { IStoreState } from '../../reducers/types'
import { sakActions, sakActionTypes } from './index'
import { API_ROUTES } from '../../epics'
import { meldingActions } from '../melding/actions'
import { createQueryStringFromObject } from '@mattilsynet/mt-common/lib/utils/createQueryStringFromObject'

export const getSakEpic =
  (commonApi: ICommonApi): Epic<AnyAction, AnyAction, IStoreState> =>
  (action$, state$) =>
    action$.pipe(
      ofType(sakActionTypes.GET_SAK),
      withLatestFrom(state$),
      mergeMap(([{ sakNumber, shouldUpdateVurdering }, state]) => {
        const meldingId = state.melding.melding?.meldingId

        return of(state).pipe(
          commonApi.get(`${API_ROUTES.ARKIV}/arkiv/saker/${sakNumber}`, state),
          mergeMap((sak) => {
            return of(state).pipe(
              mergeMap(() =>
                concat(
                  of(
                    sakActions.getSakOk(
                      sak.saksnummer,
                      sak.tittel,
                      sak.lukket,
                      sak.tilgangskode ? sak.tilgangskode.id : '',
                      sak.skjermingshjemmel
                        ? sak.skjermingshjemmel.beskrivelse
                        : '',
                      shouldUpdateVurdering
                    )
                  ),
                  meldingId && shouldUpdateVurdering
                    ? of(
                        meldingActions.updateVurderingLocal({
                          tilknyttetSaksnummer: sak.saksnummer,
                        })
                      )
                    : EMPTY
                )
              )
            )
          }),
          catchError((err) => {
            if (err.status === 404) {
              return concat(
                of(sakActions.getSakNotFound(sakNumber)),
                of(
                  meldingActions.updateVurderingLocal({
                    tilknyttetSaksnummer: undefined,
                  })
                )
              )
            } else {
              return concat(
                of(sakActions.getSakFailed(sakNumber)),
                of(
                  meldingActions.updateVurderingLocal({
                    tilknyttetSaksnummer: undefined,
                  })
                )
              )
            }
          })
        )
      })
    )

export const createSakEpic =
  (commonApi: ICommonApi): Epic<AnyAction, AnyAction, IStoreState> =>
  (action$, state$) =>
    action$.pipe(
      ofType(sakActionTypes.CREATE_SAK),
      withLatestFrom(state$),
      switchMap(([{ meldingId, status }, state]) => {
        return of(state).pipe(
          commonApi.post(
            `${
              API_ROUTES.MELDING_TIL_LOKALT_MATTILSYN
            }/v1/vurderinger/${meldingId}/saker${createQueryStringFromObject(
              '?'
            )({
              status,
            })}`,
            state,
            { status }
          ),
          mergeMap(({ response }) => {
            return concat(
              of(sakActions.createSakOk(response.tittel, response.saksnummer)),
              of(
                meldingActions.updateVurderingLocal({
                  tilknyttetSaksnummer: response.saksnummer,
                })
              )
            )
          }),
          catchError((err) => {
            return of(sakActions.createSakFailed(err.message))
          })
        )
      })
    )

export default (commonApi: ICommonApi) =>
  combineEpics(getSakEpic(commonApi), createSakEpic(commonApi))
