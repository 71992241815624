import * as R from 'ramda'
import { combineEpics, Epic } from 'redux-observable'
import { of } from 'rxjs'
import {
  catchError,
  filter,
  map,
  mergeMap,
  withLatestFrom,
} from 'rxjs/operators'

import { ICommonApi } from '../../../api'

import {
  IOrgenhetActions,
  orgenhetActions,
  orgenhetActionsTypes,
} from '../store'
import { IStoreState } from '../../../reducers/types'
import { commonRedux } from '@mattilsynet/mt-common'
import { API_ROUTES } from '../../../epics'

const { isOfType } = commonRedux

export const fetchOrgenhetFromRemoteEpic =
  (
    commonApi: ICommonApi
  ): Epic<IOrgenhetActions, IOrgenhetActions, IStoreState> =>
  (action$, state$) =>
    action$.pipe(
      filter(isOfType(orgenhetActionsTypes.FETCH_ID)),
      withLatestFrom(state$),
      mergeMap(([{ id }, state]) =>
        of(id).pipe(
          commonApi.get(
            `${API_ROUTES.ORGENHET}/orgenheter/username/${id}`,
            state
          ),
          map((data) => orgenhetActions.fetchIdOk(R.omit(['children'], data))),
          catchError((err) => of(orgenhetActions.fetchIdFail(id, err.message)))
        )
      )
    )

export default (commonApi: ICommonApi) =>
  combineEpics(fetchOrgenhetFromRemoteEpic(commonApi))
