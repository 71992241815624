import { ReplaySubject, combineLatest } from 'rxjs'
import {
  UserManager,
  WebStorageStateStore,
  UserManagerSettings,
} from 'oidc-client'
import { map } from 'rxjs/operators'

import { config$, oidcConfig$ } from '../config'

const getClientSettings = (
  authority: string,
  certs: string[],
  links: any,
  kc_idp_hint = ''
): UserManagerSettings => {
  const pathArr = window.location.href.split('?path=')
  const path = pathArr.length > 1 ? pathArr[1] : ''
  return {
    authority: authority,
    client_id: 'melding-til-lokalt-mattilsyn-ui',
    redirect_uri: `${window.location.protocol}//${window.location.hostname}${
      window.location.port ? `:${window.location.port}` : ''
    }/login/callback`,
    post_logout_redirect_uri: `${window.location.protocol}//${
      window.location.hostname
    }${window.location.port ? `:${window.location.port}` : ''}/logout/callback`,
    silent_redirect_uri: `${window.location.protocol}//${
      window.location.hostname
    }${
      window.location.port ? `:${window.location.port}` : ''
    }/silent-callback.html`,
    response_type: 'id_token token',
    scope: 'openid profile',
    filterProtocolClaims: true,
    loadUserInfo: false,
    automaticSilentRenew: true,
    revokeAccessTokenOnSignout: true,
    accessTokenExpiringNotificationTime: 30,
    userStore: new WebStorageStateStore({
      store: localStorage,
    }),
    metadata: links,
    signingKeys: certs,
    extraQueryParams: {
      kc_idp_hint: kc_idp_hint,
    },
  }
}

const authSubject: ReplaySubject<UserManager> = new ReplaySubject(1)

combineLatest(config$, oidcConfig$)
  .pipe(
    map(([config, oidcConfig]: [any, any]) =>
      getClientSettings(
        config?.oidcUrl,
        oidcConfig?.certs,
        oidcConfig?.links,
        config?.kcIdpHint
      )
    )
  )
  .subscribe((clientSettings) =>
    authSubject.next(new UserManager(clientSettings))
  )

export const authManagerSubject = authSubject
