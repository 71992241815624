import { toast } from '@mattilsynet/mt-common'

export const successToast = (text: string, timeoutSeconds = 5) =>
  toast.actions.showToast({
    text,
    timeoutSeconds,
    dismissible: true,
    type: 'SUCCESS',
  })

export const warningToast = (text: string, timeoutSeconds = 5) =>
  toast.actions.showToast({
    text: text,
    timeoutSeconds,
    dismissible: true,
    type: 'WARN',
  })

export const dangerToast = (text: string, timeoutSeconds = 5) =>
  toast.actions.showToast({
    text: text,
    timeoutSeconds,
    dismissible: true,
    type: 'DANGER',
  })
