import React, { useCallback, useEffect, useState } from 'react'
import { ModalWrapper } from '../modal-wrapper'
import { meldingActions } from '../../ducks/melding/actions'
import { useDispatch } from 'react-redux'
import { useTypedSelector } from '../../common/custom-hooks'
import { meldingSelectors } from '../../ducks/melding/selectors'
import { FilterList, IDisplayAndValue, ITreeData } from '../filter-list'
import { Column, Header, Row, TextLink } from '@mattilsynet/mt-ui'
import { sortByKey } from '../../common/sorting'
import { IMeldingFilterParams } from '../../common/melding-helpers'
import { MeldingStatus, StatusBegrunnelse } from '../../ducks/melding/helpers'
import { IStatus } from '../../ducks/melding/types'
import {
  hasBegrunnelserForStatusSenereOppfoelging,
  hasBegrunnelserForStatusVurdert,
} from './index'

export const begrunnelserForStatusVurdert = [
  StatusBegrunnelse.INGEN_BEKYMRING,
  StatusBegrunnelse.GJENNOMFORT,
  StatusBegrunnelse.RESSURSMANGEL,
  StatusBegrunnelse.UNDER_ENDRING,
]

export const begrunnelserForStatusSenereOppfoelging = [
  StatusBegrunnelse.MIDLERTIDIG_RESSURSMANGEL,
  StatusBegrunnelse.SESONGBETONT_PROBLEMSTILLING,
]

const getBegrunnelserForOppfoelging = (
  oppfoelging: IStatus,
  begrunnelser: { value: string; display: string }[]
): ITreeData[] => {
  if (oppfoelging === MeldingStatus.SENERE_OPPFØLGING) {
    return (begrunnelser || []).filter(({ value }) =>
      begrunnelserForStatusSenereOppfoelging.some(
        (begrunnelse) => begrunnelse === value
      )
    )
  } else if (oppfoelging === MeldingStatus.VURDERT) {
    return (begrunnelser || []).filter(({ value }) =>
      begrunnelserForStatusVurdert.some((begrunnelse) => begrunnelse === value)
    )
  }
  return []
}

export const BegrunnelseFilterModal = ({
  isOpen,
  onCancel,
  filterParams,
  setFilterParams,
}: {
  isOpen: boolean
  onCancel: () => void
  filterParams: IMeldingFilterParams
  setFilterParams: (params: IMeldingFilterParams) => void
}) => {
  const dispatch = useDispatch()
  const allowedStatusBegrunnelser = useTypedSelector(
    meldingSelectors.getAllowedStatusBegrunnelser
  )
  const allowedStatuses = useTypedSelector(meldingSelectors.getAllowedStatuses)

  const fetchAllowedStatuses = useCallback(() => {
    dispatch(meldingActions.fetchAllowedStatuses())
  }, [dispatch])
  const fetchAllowedStatusBegrunnelser = useCallback(() => {
    dispatch(meldingActions.fetchAllowedBegrunnelser())
  }, [dispatch])
  const [selectedBegrunnelser, setSelectedBegrunnelser] = useState<
    IDisplayAndValue[]
  >(filterParams.begrunnelser)

  let begrunnelser = (allowedStatusBegrunnelser || []).map(
    ({ status, beskrivelse }) => ({
      value: status,
      display: beskrivelse,
    })
  )
  begrunnelser = sortByKey('display', false)([...begrunnelser])

  const selectedSenereOppfoelgingFilter = filterParams.oppfoelginger.find(
    (oppfoelging) => oppfoelging.value === MeldingStatus.SENERE_OPPFØLGING
  )
  const selectedStatusVurdertFilter = filterParams.oppfoelginger.find(
    (oppfoelging) => oppfoelging.value === MeldingStatus.VURDERT
  )

  const handleUpdateFilters = () => {
    setFilterParams({
      ...filterParams,
      begrunnelser: selectedBegrunnelser,
    })
    onCancel()
  }

  useEffect(() => {
    fetchAllowedStatusBegrunnelser()
    fetchAllowedStatuses()
  }, [fetchAllowedStatusBegrunnelser, fetchAllowedStatuses])

  const renderContent = () => {
    return (
      <Column>
        <Row justify="flex-end" padding={[1, 2]}>
          <TextLink small onClick={() => setSelectedBegrunnelser([])}>
            Fjern alle valg
          </TextLink>
        </Row>

        {(!!selectedSenereOppfoelgingFilter ||
          hasBegrunnelserForStatusSenereOppfoelging(filterParams)) && (
          <Column spacing={1}>
            <Row padding={[1, 2]}>
              <Header as="h2">
                {allowedStatuses?.[MeldingStatus.SENERE_OPPFØLGING]}
              </Header>
            </Row>
            <FilterList
              treeData={getBegrunnelserForOppfoelging(
                MeldingStatus.SENERE_OPPFØLGING,
                begrunnelser
              )}
              onChange={setSelectedBegrunnelser}
              selectedNodes={selectedBegrunnelser}
              hideRemoveAll
            />
          </Column>
        )}

        {(!!selectedStatusVurdertFilter ||
          hasBegrunnelserForStatusVurdert(filterParams)) && (
          <Column spacing={1}>
            <Row padding={[2, 2]}>
              <Header as="h2">
                {allowedStatuses?.[MeldingStatus.VURDERT]}
              </Header>
            </Row>
            <FilterList
              treeData={getBegrunnelserForOppfoelging(
                MeldingStatus.VURDERT,
                begrunnelser
              )}
              onChange={setSelectedBegrunnelser}
              selectedNodes={selectedBegrunnelser}
              hideRemoveAll
            />
          </Column>
        )}
      </Column>
    )
  }

  return (
    <ModalWrapper
      isOpen={isOpen}
      onCancel={onCancel}
      paddingHorizontal={0}
      title="Begrunnelse"
      buttonOkText="Bruk valgte filter"
      onOk={handleUpdateFilters}
    >
      <Column padding={[3, 0]}>{renderContent()}</Column>
    </ModalWrapper>
  )
}
