import { combineEpics, Epic } from 'redux-observable'
import { concat, Observable, of } from 'rxjs'
import {
  catchError,
  filter,
  map,
  mergeMap,
  switchMap,
  withLatestFrom,
} from 'rxjs/operators'
import { AnyAction } from 'redux'

import { userActionTypes, userActions } from '../actions'

import { ICommonApi } from '../../../api'
import { IStoreState } from '../../../reducers/types'
import { commonRedux, notifications } from '@mattilsynet/mt-common'
import { IUserActions } from '../types'
import { API_ROUTES } from '../../../epics'

const { isOfType } = commonRedux

export const getUserOrgenhet =
  (commonApi: ICommonApi) =>
  (state: IStoreState): Observable<IUserActions> =>
    of(state).pipe(
      commonApi.get(`${API_ROUTES.ORGENHET}/v1/me/orgenhet`, state),
      map((resp) => userActions.fetchUserOrgenhetOk(resp)),
      catchError((err) => of(userActions.fetchUserOrgenhetFail(err.message)))
    )

export const getUserAvdelingEpic =
  (commonApi: ICommonApi): Epic<IUserActions, AnyAction, IStoreState> =>
  (action$, state$) =>
    action$.pipe(
      filter(isOfType(userActionTypes.FETCH_USER_ORGENHET_OK)),
      withLatestFrom(state$),
      switchMap(([{ me }, state]) =>
        of(state).pipe(
          commonApi.get(
            `${API_ROUTES.ORGENHET}/orgenheter/parenttype/Avdeling/id/${me.id}`,
            state
          ),
          mergeMap((resp) => {
            return concat(
              of(userActions.fetchUserAvdelingOk(resp)),
              of(
                notifications.actions.startPolling(30000, [
                  // @ts-ignore
                  'Melding til lokalt Mattilsyn',
                ])
              )
            )
          }),
          catchError((err) =>
            of(userActions.fetchUserAvdelingFail(err.message))
          )
        )
      )
    )

export const getUserRegionEpic =
  (commonApi: ICommonApi): Epic<IUserActions, IUserActions, IStoreState> =>
  (action$, state$): Observable<AnyAction> =>
    action$.pipe(
      filter(isOfType(userActionTypes.FETCH_USER_ORGENHET_OK)),
      withLatestFrom(state$),
      switchMap(([{ me }, state]) =>
        of(state).pipe(
          commonApi.get(`/api/orgenheter/parenttype/Region/id/${me.id}`, state),
          map((resp) => userActions.fetchUserRegionOk(resp)),
          catchError((err) => of(userActions.fetchUserRegionFail(err.message)))
        )
      )
    )

export default (commonApi: ICommonApi) =>
  combineEpics(getUserAvdelingEpic(commonApi), getUserRegionEpic(commonApi))
