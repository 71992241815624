import {
  IStatusObject,
  IStatus,
  IVurdering,
  IVurderingPayload,
  IRemoteStatus,
} from './types'

export enum MeldingStatus {
  NY = 'NY',
  UNDER_VURDERING = 'UNDER_VURDERING',
  VURDERT = 'VURDERT',
  DUBLETT = 'DUBLETT',
  INSPEKSJON = 'INSPEKSJON',
  SAKSBEHANDLING = 'SAKSBEHANDLING',
  SENERE_OPPFØLGING = 'SENERE_OPPFØLGING',
  UTKAST = 'UTKAST',
}

export enum StatusBegrunnelse {
  UNDER_ENDRING = 'UNDER_ENDRING',
  GJENNOMFORT = 'GJENNOMFORT',
  INGEN_BEKYMRING = 'INGEN_BEKYMRING',
  RESSURSMANGEL = 'RESSURSMANGEL',
  SESONGBETONT_PROBLEMSTILLING = 'SESONGBETONT_PROBLEMSTILLING',
  MIDLERTIDIG_RESSURSMANGEL = 'MIDLERTIDIG_RESSURSMANGEL',
}

export const convertStatusArrayToObject = (
  statusArray?: IRemoteStatus[]
): IStatusObject => {
  return <IStatusObject>(statusArray || []).reduce(
    (acc: Partial<IStatusObject>, status: IRemoteStatus) => {
      acc[status.status] = status.beskrivelse
      return acc
    },
    {}
  )
}

export const meldingStatusIsOneOf = (
  status: IStatus | undefined,
  oneOf: (string | undefined)[]
) => oneOf.some((meldingStatus) => meldingStatus === status)

export const formatVurderingToBody = (
  vurdering: IVurdering
): IVurderingPayload => ({
  status: vurdering?.status,
  statusBegrunnelse: vurdering?.statusBegrunnelse,
  tilknyttetSaksnummer: vurdering?.tilknyttetSaksnummer,
  paaminnelseDato: vurdering?.paaminnelseDato,
})

export const getVurderingError = (
  vurdering: IVurdering | undefined
): string | null => {
  if (
    !vurdering?.status ||
    meldingStatusIsOneOf(vurdering?.status, [MeldingStatus.NY])
  ) {
    return 'Du må vurdere meldingen før du kan lagre.'
  }

  if (
    meldingStatusIsOneOf(vurdering?.status, [
      MeldingStatus.VURDERT,
      MeldingStatus.SENERE_OPPFØLGING,
    ]) &&
    !vurdering.statusBegrunnelse?.length
  ) {
    return 'Du må velge en begrunnelse for hvorfor saken ikke følges opp før du kan lagre.'
  }

  if (
    (meldingStatusIsOneOf(vurdering?.status, [
      MeldingStatus.DUBLETT,
      MeldingStatus.SAKSBEHANDLING,
      MeldingStatus.INSPEKSJON,
    ]) ||
      (meldingStatusIsOneOf(vurdering.status, [MeldingStatus.VURDERT]) &&
        vurdering.statusBegrunnelse === 'GJENNOMFORT')) &&
    !vurdering.tilknyttetSaksnummer
  ) {
    return 'Du må knytte til en sak før du kan lagre.'
  }

  return null
}
