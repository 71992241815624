import {
  applyMiddleware,
  combineReducers,
  compose,
  createStore,
  Store,
  AnyAction,
} from 'redux'
import { authManagerSubject } from '../auth/config'
// Router
import { connectRouter, routerMiddleware } from 'connected-react-router'
import history from '../history'

// Redux
import { createEpicMiddleware } from 'redux-observable'

// Types
import { IStoreState } from './types'

// Epics
import epics from '../epics'

// Reducers
import { toast, notifications, kodeverk } from '@mattilsynet/mt-common'
import { ansatteReducer } from '../ducks/ansatte/reducer'
import { avdelingerReducer } from '../ducks/avdelinger/reducer'
import { uiReducer } from '../ducks/ui/reducer'
import { userReducer } from '../ducks/user/reducer'
import { meldingReducer } from '../ducks/melding/reducer'
import { meldingsHistorikkReducer } from '../ducks/meldingshistorikk/reducer'
import { orgenhetReducer } from '../ducks/orgenhet'
import { tilsynsobjektReducer } from '../ducks/tilsynsobjekt/store'
import { imagesReducer } from '../ducks/images/store'
import { sakReducer } from '../ducks/sak/reducer'
import { userActions } from '../ducks/user/actions'

const epicMiddleware = createEpicMiddleware<
  AnyAction,
  AnyAction,
  IStoreState,
  void
>()

const composeEnhancers =
  (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose
const enhancers = composeEnhancers(
  applyMiddleware(routerMiddleware(history), epicMiddleware)
)

export const reducers = {
  ansatte: ansatteReducer,
  avdelinger: avdelingerReducer,
  ...imagesReducer,
  ...kodeverk.reducer,
  melding: meldingReducer,
  meldingsHistorikk: meldingsHistorikkReducer,
  ...notifications.reducer,
  ...orgenhetReducer,
  ...tilsynsobjektReducer,
  router: connectRouter(history),
  sak: sakReducer,
  ...toast.reducer,
  ui: uiReducer,
  user: userReducer,
}

const rootReducer = combineReducers(reducers)

const store: Store<IStoreState> = createStore(rootReducer, enhancers)
epicMiddleware.run(epics)

// Sync token with redux-store
authManagerSubject.subscribe((userManager) => {
  userManager.events.addUserLoaded((tokens) => {
    store.dispatch(userActions.updateToken(tokens.access_token))
  })

  userManager.events.addAccessTokenExpired(() => {
    store.dispatch(userActions.userExpired())
  })

  userManager.events.addUserSignedOut(() => {
    store.dispatch(userActions.userSignedOut())
  })
})

export default store
