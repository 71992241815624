import { IUserState } from './types'
import { IStoreState } from '../../reducers/types'

export const userSelectors = {
  getAuthUser: (state: IStoreState): IUserState['authUser'] =>
    state.user.authUser,

  getAvdeling: (state: IStoreState): IUserState['avdeling'] =>
    state.user.avdeling,

  getRegion: (state: IStoreState): IUserState['region'] => state.user.region,

  getMe: (state: IStoreState): IUserState['me'] => state.user.me,
}
