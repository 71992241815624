import { IUiState } from './types'
import { IStoreState } from '../../reducers/types'

export const uiSelectors = {
  getInitializationStatus: (
    state: IStoreState
  ): IUiState['initializationStatus'] => state.ui.initializationStatus,

  getEnvironment: (state: IStoreState): IUiState['environment'] =>
    state.ui.environment,

  getFilterParams: (state: IStoreState): IUiState['filterParams'] =>
    state.ui.filterParams,
}
