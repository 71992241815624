import React, {
  ComponentProps,
  ReactNode,
  useCallback,
  useEffect,
  useState,
} from 'react'
import { useMediaQuery } from 'react-responsive'
import {
  AddCircle,
  Button,
  Column,
  DatePicker,
  DeleteIcon,
  LoadingSpinner,
  PenIcon,
  Row,
  RowItem,
  ShadowBox,
  Text,
  TextLink,
} from '@mattilsynet/mt-ui'
import {
  MeldingStatus,
  meldingStatusIsOneOf,
} from '../../ducks/melding/helpers'
import { VurderingVidereOppfoelgingModal } from '../vurdering-videre-oppfoelging-modal'
import { VurderingAddSakModal } from '../vurdering-add-sak-modal'
import { onKeyDownSaksnummer } from '../../ducks/sak/helpers'
import { sakActions, sakSelectors } from '../../ducks/sak'
import { meldingActions } from '../../ducks/melding/actions'
import { useDispatch } from 'react-redux'
import { useTypedSelector } from '../../common/custom-hooks'
import { meldingSelectors } from '../../ducks/melding/selectors'
import {
  IStatus,
  IStatusBegrunnelse,
  IVurdering,
} from '../../ducks/melding/types'
import { parseSakTitleToHTML } from '../sak-card-info'
import { VurderingBegrunnelseModal } from '../vurdering-begrunnelse-modal'
import { VurderingCreateSakModal } from '../vurdering-create-sak-modal'
import { dangerToast } from '../../ducks/common/toast-actions'
import dayjs from 'dayjs'

const VurderingRowLayout = ({
  title,
  content,
  buttons,
}: {
  title: ReactNode | string
  content: ReactNode | string
  buttons?: ({ text: string; onClick: () => void } | undefined)[]
}) => {
  const isTinyScreen = useMediaQuery({ query: '(max-width: 767px)' })
  const RowOrColumn = (
    props: ComponentProps<typeof Column> | ComponentProps<typeof Row>
  ) => (isTinyScreen ? <Column {...props} /> : <Row {...props} />)

  return (
    <RowOrColumn
      justify="space-between"
      padding={3}
      spacing={isTinyScreen ? 3 : 1}
    >
      <RowItem flex={2}>
        <Text size="heading3" weight="bold">
          {title}
        </Text>
      </RowItem>

      <RowItem flex={3}>{content}</RowItem>

      <RowItem flex={1}>
        <Column spacing={1}>
          {buttons?.map((button) => {
            if (!button) return null
            return (
              <Button
                key={button.text}
                secondary
                onClick={button.onClick}
                width="220px"
              >
                {button.text}
              </Button>
            )
          })}
        </Column>
      </RowItem>
    </RowOrColumn>
  )
}

export const Vurdering = ({}) => {
  const dispatch = useDispatch()
  const melding = useTypedSelector(meldingSelectors.getMelding)
  const vurdering = useTypedSelector(meldingSelectors.getVurderingLocal)
  const saksTittel = useTypedSelector(sakSelectors.getSakTitle)
  const allowedStatuses = useTypedSelector(meldingSelectors.getAllowedStatuses)
  const allowedStatusBegrunnelser = useTypedSelector(
    meldingSelectors.getAllowedStatusBegrunnelser
  )
  const { created: sakCreated } = useTypedSelector(sakSelectors.getSakState)

  const [isStatusModalOpen, setIsStatusModalOpen] = useState(false)
  const [isAddSakModalOpen, setIsAddSakModalOpen] = useState(false)
  const [isCreateSakModalOpen, setIsCreateSakModalOpen] = useState(false)
  const [isBegrunnelseModalOpen, setIsBegrunnelseModalOpen] = useState(false)
  const [saksnummerValue, setSaksnummerValue] = useState(
    vurdering?.tilknyttetSaksnummer
  )

  const isCreateSakAllowed = meldingStatusIsOneOf(vurdering?.status, [
    MeldingStatus.SAKSBEHANDLING,
    MeldingStatus.INSPEKSJON,
  ])

  const fetchSak = useCallback(() => {
    if (vurdering?.tilknyttetSaksnummer) {
      dispatch(sakActions.getSak(vurdering?.tilknyttetSaksnummer, false))
    }
  }, [dispatch, vurdering?.tilknyttetSaksnummer])

  const onOpenAddSakModal = () => {
    if (sakCreated) {
      dispatch(
        dangerToast(
          'Du kan ikke bytte sak siden du allerede har koblet til en nyopprettet sak.'
        )
      )
    } else {
      setIsAddSakModalOpen(true)
    }
  }
  const onOpenCreateSakModal = () => {
    if (sakCreated) {
      dispatch(
        dangerToast(
          'Du kan ikke opprette ny sak siden du allerede har opprettet en.'
        )
      )
    } else {
      setIsCreateSakModalOpen(true)
    }
  }

  const onCreateSak = () => {
    dispatch(
      meldingActions.updateVurderingLocal({
        tilknyttetSaksnummer: undefined,
      })
    )
    if (melding?.meldingId && vurdering?.status) {
      dispatch(sakActions.createSak(melding?.meldingId, vurdering?.status))
    }
  }

  const onAddSak = () => {
    dispatch(sakActions.getSak(saksnummerValue || '', true))
  }

  const onFjernDato = () => {
    dispatch(
      meldingActions.updateVurderingLocal({
        paaminnelseDato: undefined,
      })
    )
  }

  const onCancelSak = () => {
    dispatch(
      meldingActions.updateVurderingLocal({
        tilknyttetSaksnummer: undefined,
      })
    )
    dispatch(sakActions.clearSak())
    setSaksnummerValue('')
  }

  const onSetPaaminnelseDato = (dato?: string) => {
    dispatch(
      meldingActions.updateVurderingLocal({
        paaminnelseDato: dato,
      })
    )
  }

  const onSetStatus = (status: IStatus) => {
    const shouldHaveSaksnummer = meldingStatusIsOneOf(status, [
      MeldingStatus.DUBLETT,
      MeldingStatus.SAKSBEHANDLING,
      MeldingStatus.INSPEKSJON,
      MeldingStatus.SENERE_OPPFØLGING,
    ])
    const shouldHavePaaminnelseDato = meldingStatusIsOneOf(status, [
      MeldingStatus.SENERE_OPPFØLGING,
    ])

    const partialVurdering: Partial<IVurdering> = {}
    partialVurdering.status = status

    if (!shouldHaveSaksnummer) {
      partialVurdering.tilknyttetSaksnummer = undefined
      dispatch(sakActions.clearSak())
    }
    if (!shouldHavePaaminnelseDato) {
      partialVurdering.paaminnelseDato = undefined
    }
    partialVurdering.statusBegrunnelse = undefined
    dispatch(meldingActions.updateVurderingLocal(partialVurdering))
  }

  const onSetStatusBegrunnelse = (statusBegrunnelse: IStatusBegrunnelse) => {
    dispatch(meldingActions.updateVurderingLocal({ statusBegrunnelse }))
  }

  useEffect(() => {
    if (vurdering?.tilknyttetSaksnummer) {
      setSaksnummerValue(vurdering?.tilknyttetSaksnummer)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [vurdering?.tilknyttetSaksnummer])

  useEffect(() => {
    fetchSak()
  }, [fetchSak])

  useEffect(() => {
    if (sakCreated && isCreateSakAllowed && isCreateSakModalOpen) {
      setIsCreateSakModalOpen(false)
    }
  }, [sakCreated, isCreateSakAllowed, isCreateSakModalOpen])

  useEffect(() => {
    return () => {
      dispatch(meldingActions.clearMeldingState())
      dispatch(sakActions.clearSak())
    }
  }, [dispatch])

  if (!vurdering) {
    return (
      <ShadowBox>
        <LoadingSpinner title="Laster vurdering" />
      </ShadowBox>
    )
  }

  return (
    <>
      <ShadowBox>
        <VurderingRowLayout
          title="Videre oppfølging"
          content={<Text>{allowedStatuses?.[vurdering?.status || '']}</Text>}
          buttons={[
            {
              text: meldingStatusIsOneOf(vurdering?.status, [MeldingStatus.NY])
                ? 'Velg oppfølging'
                : 'Endre oppfølging',
              onClick: () => setIsStatusModalOpen(true),
            },
          ]}
        />

        {meldingStatusIsOneOf(vurdering?.status, [
          MeldingStatus.VURDERT,
          MeldingStatus.SENERE_OPPFØLGING,
        ]) && (
          <VurderingRowLayout
            title="Begrunnelse"
            content={
              vurdering?.statusBegrunnelse ? (
                <Text>
                  {allowedStatusBegrunnelser?.find(
                    (begrunnelse) =>
                      begrunnelse.status === vurdering?.statusBegrunnelse
                  )?.beskrivelse || vurdering?.statusBegrunnelse}
                </Text>
              ) : (
                <Text weight="light">Begrunnelse ikke lagt til.</Text>
              )
            }
            buttons={[
              {
                text: !vurdering?.statusBegrunnelse
                  ? 'Velg begrunnelse'
                  : 'Endre begrunnelse',
                onClick: () => {
                  dispatch(
                    meldingActions.fetchAllowedBegrunnelser(vurdering?.status)
                  )
                  setIsBegrunnelseModalOpen(true)
                },
              },
            ]}
          />
        )}

        {(meldingStatusIsOneOf(vurdering?.status, [
          MeldingStatus.DUBLETT,
          MeldingStatus.SAKSBEHANDLING,
          MeldingStatus.INSPEKSJON,
        ]) ||
          (meldingStatusIsOneOf(vurdering.status, [MeldingStatus.VURDERT]) &&
            vurdering.statusBegrunnelse === 'GJENNOMFORT')) && (
          <VurderingRowLayout
            title="Tilknyttet sak"
            content={
              vurdering?.tilknyttetSaksnummer ? (
                <Column spacing={0.5}>
                  <Text>Saksnummer: {vurdering?.tilknyttetSaksnummer}</Text>
                  <Text as="div">
                    <span>Sakstittel: </span>
                    <span
                      dangerouslySetInnerHTML={{
                        __html: parseSakTitleToHTML(saksTittel),
                      }}
                    ></span>
                  </Text>
                </Column>
              ) : (
                <Text weight="light">Sak ikke lagt til</Text>
              )
            }
            buttons={[
              {
                text: !vurdering?.tilknyttetSaksnummer
                  ? 'Knytt til eksisterende sak'
                  : 'Bytt sak',
                onClick: () => onOpenAddSakModal(),
              },
              isCreateSakAllowed
                ? {
                    text: 'Opprett ny sak',
                    onClick: () => onOpenCreateSakModal(),
                  }
                : undefined,
            ]}
          />
        )}

        <VurderingVidereOppfoelgingModal
          isOpen={isStatusModalOpen}
          onCancel={() => setIsStatusModalOpen(false)}
          status={vurdering?.status}
          setStatus={onSetStatus}
        />

        <VurderingBegrunnelseModal
          isOpen={isBegrunnelseModalOpen}
          onCancel={() => setIsBegrunnelseModalOpen(false)}
          statusBegrunnelse={vurdering?.statusBegrunnelse}
          setStatusBegrunnelse={onSetStatusBegrunnelse}
          allowedStatusBegrunnelser={allowedStatusBegrunnelser}
        />

        {isAddSakModalOpen && (
          <VurderingAddSakModal
            isOpen={isAddSakModalOpen}
            onCancel={() => setIsAddSakModalOpen(false)}
            saksnummerValue={saksnummerValue || ''}
            onChange={(e) =>
              setSaksnummerValue(onKeyDownSaksnummer(e, saksnummerValue || ''))
            }
            onAddSak={onAddSak}
            onCancelSak={onCancelSak}
          />
        )}
        {isCreateSakModalOpen && (
          <VurderingCreateSakModal
            isOpen={isCreateSakModalOpen}
            onCancel={() => setIsCreateSakModalOpen(false)}
            onCreateSak={onCreateSak}
          />
        )}
      </ShadowBox>
      {meldingStatusIsOneOf(vurdering?.status, [
        MeldingStatus.SENERE_OPPFØLGING,
      ]) && (
        <Column>
          <Text weight="bold" size="heading3">
            Sett påminnelse (Valgfritt)
          </Text>
          <br />
          <Text>
            Basert på meldingens oppfølging kan du velge å motta en påminnelse
            på e-post for å huske å følge opp meldingen på et senere tidspunkt.
            Dette er valgfritt.
          </Text>
          <br />
          {vurdering?.paaminnelseDato && (
            <>
              <Row spacing={1}>
                <Text>Påminnelse satt: </Text>
                <Text weight={'bold'}>
                  {dayjs(vurdering?.paaminnelseDato).format('DD. MMM YYYY')}
                </Text>
              </Row>
              <br />
            </>
          )}
          {!vurdering?.paaminnelseDato && (
            <DatePicker
              ariaLabel="date"
              date={dayjs(vurdering?.paaminnelseDato).format('YYYY-MM-DD')}
              setDate={onSetPaaminnelseDato}
              minDate={dayjs().add(1, 'day').toISOString()}
              customInput={
                <Row spacing={1}>
                  <AddCircle size={'medium'} />
                  <TextLink>Legg til dato for påminnelse</TextLink>
                </Row>
              }
            />
          )}
          {vurdering?.paaminnelseDato && (
            <Row spacing={2}>
              <DatePicker
                ariaLabel="date"
                date={dayjs(vurdering?.paaminnelseDato).format('YYYY-MM-DD')}
                setDate={onSetPaaminnelseDato}
                minDate={dayjs().add(1, 'day').toISOString()}
                customInput={
                  <Row spacing={1}>
                    <PenIcon size={'medium'} />
                    <TextLink>Endre dato</TextLink>
                  </Row>
                }
              />
              <Row spacing={1}>
                <DeleteIcon size={'medium'} />
                <TextLink onClick={() => onFjernDato()}>Fjern dato</TextLink>
              </Row>
            </Row>
          )}
        </Column>
      )}
    </>
  )
}
