import { commonRedux } from '@mattilsynet/mt-common'
import { IAvdelingerState } from '../types'
import { avdelingerActionTypes } from '../index'

const initialState: IAvdelingerState = {
  loading: false,
  loaded: false,
  error: null,
  avdelinger: [],
}

export const avdelingerReducer = commonRedux.createReducer<
  IAvdelingerState,
  any
>(initialState, {
  [avdelingerActionTypes.FETCH_AVDELINGER]: (state: IAvdelingerState) => ({
    ...state,
    loading: true,
    error: null,
  }),

  [avdelingerActionTypes.FETCH_AVDELINGER_OK]: (
    state: IAvdelingerState,
    { avdelinger }: { avdelinger: IAvdelingerState['avdelinger'] }
  ) => ({
    ...state,
    loading: false,
    loaded: true,
    avdelinger,
  }),

  [avdelingerActionTypes.FETCH_AVDELINGER_FAIL]: (
    state: IAvdelingerState,
    {
      error,
    }: {
      error: string
    }
  ) => ({ ...state, error, loading: false, loaded: true }),
})
