import { combineEpics } from 'redux-observable'
import { apm } from '@elastic/apm-rum'
import { catchError } from 'rxjs/operators'

import { commonApi } from '../api'
import { notifications, kodeverk } from '@mattilsynet/mt-common'
import ansatteEpics from '../ducks/ansatte/epic'
import avdelingerEpics from '../ducks/avdelinger/epic'
import imageEpics from '../ducks/images/epic'
import meldingEpic from '../ducks/melding/epic'
import meldingsHistorikkEpic from '../ducks/meldingshistorikk/epic'
import { orgenhetEpics } from '../ducks/orgenhet'
import produsentEpic from '../ducks/tilsynsobjekt/epic'
import sakEpic from '../ducks/sak/epic'
import uiEpic from '../ducks/ui/epic'
import userEpic from '../ducks/user/epic'

const authTokenPath = ['user', 'authUser', 'accessToken']

export const API_ROUTES = {
  ARKIV: '/api/arkiv-api',
  BILDE: '/api/bilde-api',
  KJOETTKONTROLL_FUNN: '/api/kjoettkontroll-funn-api',
  KODEVERK: '/api/kodeverk-api/kodeverk',
  MELDING_TIL_LOKALT_MATTILSYN: '/api/melding-til-lokalt-mattilsyn-api',
  NOTIFICATION: '/api/notification-api',
  ORGENHET: '/api/orgenhet-api',
  TILSYNSKVITTERING: '/api/tilsynskvittering-api',
  TILSYNSOBJEKT: '/api/tilsynsobjekt-api',
}

const rootEpic = (action$, store$, dependencies) =>
  combineEpics(
    kodeverk.epic(authTokenPath, API_ROUTES.KODEVERK) as any,
    ansatteEpics(commonApi) as any,
    avdelingerEpics(commonApi) as any,
    imageEpics() as any,
    meldingEpic(commonApi) as any,
    meldingsHistorikkEpic(commonApi) as any,
    notifications.epic(authTokenPath, API_ROUTES.NOTIFICATION),
    orgenhetEpics(commonApi) as any,
    produsentEpic as any,
    sakEpic(commonApi) as any,
    uiEpic(commonApi) as any,
    userEpic(commonApi) as any
  )(action$, store$, dependencies).pipe(
    catchError((err, source) => {
      // eslint-disable-next-line no-console
      console.error('catched', err)
      apm.captureError(err)
      return source
    })
  )

export default rootEpic
