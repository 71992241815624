import React, { useContext } from 'react'
import { Column, Text, ThemeContext } from '@mattilsynet/mt-ui'

export const NoAccess = () => {
  const theme = useContext(ThemeContext)
  return (
    <Column padding={[15, 4, 4, 4]} spacing={1} align="center">
      <Text size="heading2" color={theme.gray2}>
        Du har ikke tilgang til oversikten over din avdelings meldinger.
      </Text>
      <Text size="heading2" color={theme.gray2}>
        Lenke til meldinger finner du i virksomhetens dokumentliste i MATS,
        eller i arkivet (Elements).
      </Text>
    </Column>
  )
}
