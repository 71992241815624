import { IUserState } from './types'

export const userActionTypes = {
  SET_LOGGED_IN_USER: <const>'user/SET_LOGGED_IN_USER',
  GET_LOGGED_IN_USER: <const>'user/GET_LOGGED_IN_USER',
  UPDATE_TOKEN: <const>'user/UPDATE_TOKEN',
  USER_EXPIRED: <const>'user/USER_EXPIRED',
  USER_SIGNED_OUT: <const>'user/USER_SIGNED_OUT',
  SET_LAST_LOCATION: <const>'user/SET_LAST_LOCATION',
  FETCH_USER_AVDELING_FAIL: <const>'user/FETCH_USER_AVDELING_FAIL',
  FETCH_USER_AVDELING_OK: <const>'user/FETCH_USER_AVDELING_OK',
  FETCH_USER_ORGENHET_FAIL: <const>'user/FETCH_USER_ORGENHET_FAIL',
  FETCH_USER_ORGENHET_OK: <const>'user/FETCH_USER_ORGENHET_OK',
  FETCH_USER_REGION_OK: <const>'user/FETCH_USER_REGION_OK',
  FETCH_USER_REGION_FAIL: <const>'user/FETCH_USER_REGION_FAIL',
}

export const userActions = {
  setLoggedInUser: (authUser: IUserState['authUser']) => ({
    type: userActionTypes.SET_LOGGED_IN_USER,
    authUser,
  }),

  fetchUserAvdelingFail: (err: string) => ({
    type: userActionTypes.FETCH_USER_AVDELING_FAIL,
    err,
  }),

  fetchUserAvdelingOk: (avdeling: IUserState['avdeling']) => ({
    type: userActionTypes.FETCH_USER_AVDELING_OK,
    avdeling,
  }),

  fetchUserOrgenhetFail: (err: string) => ({
    type: userActionTypes.FETCH_USER_ORGENHET_FAIL,
    err,
  }),

  fetchUserOrgenhetOk: (me: IUserState['me']) => ({
    type: userActionTypes.FETCH_USER_ORGENHET_OK,
    me,
  }),

  fetchUserRegionFail: (err: string) => ({
    type: userActionTypes.FETCH_USER_REGION_FAIL,
    err,
  }),

  fetchUserRegionOk: (region: IUserState['region']) => ({
    type: userActionTypes.FETCH_USER_REGION_OK,
    region,
  }),

  updateToken: (accessToken: IUserState['authUser']['accessToken']) => ({
    type: userActionTypes.UPDATE_TOKEN,
    accessToken,
  }),

  userExpired: () => ({
    type: userActionTypes.USER_EXPIRED,
  }),

  userSignedOut: () => ({
    type: userActionTypes.USER_SIGNED_OUT,
  }),
}
