import React, { useCallback, useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { AboutPage } from '@mattilsynet/mt-ui'
import { goBack } from '../../common/common-router'
import buildInfo from '../../../package.json'

const About = () => {
  const [apiStatus, setApiStatus] = useState({ checked: false, ok: false })

  const dispatch = useDispatch()
  const goBackOnclick = useCallback(() => dispatch(goBack()), [dispatch])

  useEffect(() => {
    fetch('/api')
      .then((cb) => {
        if (cb.ok) {
          setApiStatus({
            checked: true,
            ok: true,
          })
        }
      })
      .catch(() => {
        setApiStatus({
          checked: true,
          ok: false,
        })
      })
  }, [])

  return (
    <AboutPage
      pageUrl="https://makuba.mattilsynet.no/"
      appName="Melding til lokalt Mattilsyn"
      description="Løsningen gir deg oversikt over meldinger fra kjøttkontroller som har bekymring for et dyrehold."
      onGoBackClick={goBackOnclick}
      appVersion={buildInfo.version}
      statusList={[
        { type: 'Server', checked: apiStatus.checked, ok: apiStatus.ok },
      ]}
    />
  )
}

export default About
