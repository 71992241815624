import { init as initApm } from '@elastic/apm-rum'
import { ReplaySubject } from 'rxjs'
import { map } from 'rxjs/operators'
import { config$ } from '../config'
import packageJson from '../../package.json'

const apm$ = new ReplaySubject(1)

config$
  .pipe(
    map((config) =>
      initApm({
        serviceName: 'melding-til-lokalt-mattilsyn-ui',
        serverUrl: config.logserver,
        serviceVersion: packageJson.version,
        environment: config.environment,
      })
    )
  )
  .subscribe((apm) => apm$.next(apm))

export default apm$
