import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import jwtDecode from 'jwt-decode'
import { LoadingSpinner, TextLink } from '@mattilsynet/mt-ui'
import { userActions } from '../../ducks/user/actions'
import { authManagerSubject } from '../../auth/config'
import { pushState, replaceState } from '../../common/common-router'

const excludedPaths = /^\/login|^\/logout|^\/setup|^\/access-denied/
export const pathIfValid = (path: string | null): string =>
  !path || excludedPaths.test(path) ? '/' : path

const checkUserRole = (roles) => {
  return roles?.includes('melding-til-lokalt-mattilsyn-user')
}

const checkUserAndRedirect = (dispatch, user, path) => {
  const accessTokenData = jwtDecode(user.access_token) as Record<string, any>
  const roles = accessTokenData?.realm_access?.roles
  if (checkUserRole(roles)) {
    dispatch(
      userActions.setLoggedInUser({
        accessToken: user.access_token,
        username: user.profile.preferred_username,
        name: user.profile.name,
        email: user.profile.email,
        title: user.profile.title,
      })
    )
    dispatch(replaceState(path || '/'))
  } else {
    dispatch(pushState('/access-denied'))
  }
}

const login = (authManager, dispatch, path) => {
  authManager.getUser().then(() => {
    authManager
      .signinSilent()
      .then((user) => {
        checkUserAndRedirect(dispatch, user, path)
      })
      .catch(() => {
        authManager.signinRedirect()
      })
  })
}

export const Login = () => {
  const dispatch = useDispatch()
  const path = pathIfValid(new URLSearchParams(location.search).get('path'))

  useEffect(() => {
    authManagerSubject.subscribe((authManager) => {
      login(authManager, dispatch, path)
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    authManagerSubject.subscribe((authManager) => {
      authManager.events.addUserSignedOut(() => {
        dispatch(pushState(`/login/callback?path=${path}`))
      })
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return <LoadingSpinner title="Logger inn" />
}

export const LoginCallback = () => {
  const dispatch = useDispatch()
  const path = pathIfValid(new URLSearchParams(location.search).get('path'))

  useEffect(() => {
    authManagerSubject.subscribe((authManager) => {
      authManager
        .signinRedirectCallback()
        .then((user) => {
          checkUserAndRedirect(dispatch, user, path)
        })
        .catch(() => {
          authManager.signinRedirect()
        })
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <div className="logged-in">
      <div className="loggen-in-text">
        <LoadingSpinner title="Logger inn" />
      </div>
    </div>
  )
}

export const Logout = () => {
  useEffect(() => {
    authManagerSubject.subscribe((authManager) => {
      authManager.signoutRedirect()
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return <LoadingSpinner title="Logger ut" />
}

export const LogoutCallback = () => {
  const dispatch = useDispatch()
  return (
    <div className="logged-out">
      <div className="logged-out-text">Du er logget ut</div>
      <TextLink onClick={() => dispatch(pushState('/login'))}>
        Logg inn
      </TextLink>
    </div>
  )
}
