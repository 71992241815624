import React from 'react'
import { SelectList } from '@mattilsynet/mt-ui'
import { ModalWrapper } from '../modal-wrapper'
import { IMeldingState, IStatusBegrunnelse } from '../../ducks/melding/types'

interface IVurderingBegrunnelseModalProps {
  isOpen: boolean
  onCancel: () => void
  statusBegrunnelse: IStatusBegrunnelse | undefined
  setStatusBegrunnelse: (statusBegrunnelse: IStatusBegrunnelse) => void
  allowedStatusBegrunnelser: IMeldingState['allowedStatusBegrunnelser']
}

export const VurderingBegrunnelseModal = ({
  isOpen,
  onCancel,
  statusBegrunnelse,
  setStatusBegrunnelse,
  allowedStatusBegrunnelser,
}: IVurderingBegrunnelseModalProps) => {
  const dataList = (allowedStatusBegrunnelser || []).map(
    ({ status, beskrivelse }) => ({
      value: status,
      label: beskrivelse,
    })
  )

  return (
    <ModalWrapper
      onCancel={onCancel}
      isOpen={isOpen}
      title="Velg begrunnelse"
      onOk={onCancel}
      paddingHorizontal={0}
    >
      <SelectList
        dataList={dataList}
        onClick={setStatusBegrunnelse}
        selected={[String(statusBegrunnelse)]}
      />
    </ModalWrapper>
  )
}
