import { commonRedux } from '@mattilsynet/mt-common'
import { meldingActionTypes } from '../actions'
import { IMelding, IMeldingActions, IMeldingState, IVurdering } from '../types'

const initialState: IMeldingState = {
  loading: false,
  loaded: false,
  error: null,
  melding: undefined,
  saving: false,
  saved: false,
  saveError: null,
  allowedStatuses: undefined,
  allowedStatusBegrunnelser: undefined,
}

export const meldingReducer = commonRedux.createReducer<
  IMeldingState,
  IMeldingActions
>(initialState, {
  [meldingActionTypes.FETCH_MELDING]: (state: IMeldingState) => ({
    ...state,
    loading: true,
    error: null,
    saveError: null,
  }),

  [meldingActionTypes.FETCH_MELDING_OK]: (
    state: IMeldingState,
    { melding }: { melding: IMelding }
  ) => ({
    ...initialState,
    allowedStatuses: state.allowedStatuses,
    allowedStatusBegrunnelser: state.allowedStatusBegrunnelser,
    melding: { ...melding, vurderingLocal: melding.vurdering },
    loaded: true,
  }),

  [meldingActionTypes.FETCH_MELDING_FAIL]: (
    state: IMeldingState,
    { err }: { err: string }
  ) => ({ ...state, error: err, loading: false }),

  [meldingActionTypes.UPDATE_VURDERING_LOCAL]: (
    state: IMeldingState,
    { partialVurdering }: { partialVurdering: Partial<IVurdering> }
  ) => {
    const vurderingLocal = state?.melding?.vurderingLocal || {}
    return {
      ...state,
      melding: state.melding
        ? {
            ...state.melding,
            vurderingLocal: { ...vurderingLocal, ...partialVurdering },
          }
        : undefined,
      saving: false,
      saved: false,
      saveError: null,
    }
  },

  [meldingActionTypes.UPDATE_VURDERING]: (state: IMeldingState) => ({
    ...state,
    saving: true,
    saved: false,
    saveError: null,
  }),

  [meldingActionTypes.UPDATE_VURDERING_OK]: (
    state: IMeldingState,
    { vurdering }: { vurdering: IVurdering }
  ) => ({
    ...state,
    melding: state.melding
      ? {
          ...state.melding,
          vurdering: { ...vurdering },
          vurderingLocal: { ...vurdering },
        }
      : undefined,
    saving: false,
    saved: true,
  }),

  [meldingActionTypes.UPDATE_VURDERING_FAIL]: (
    state: IMeldingState,
    { error }: { error: string }
  ) => ({
    ...state,
    saving: false,
    saved: false,
    saveError: error,
  }),

  [meldingActionTypes.FETCH_ALLOWED_STATUSES_OK]: (
    state: IMeldingState,
    { allowedStatuses }: { allowedStatuses: IMeldingState['allowedStatuses'] }
  ) => ({
    ...state,
    allowedStatuses,
  }),

  [meldingActionTypes.FETCH_ALLOWED_BEGRUNNELSER_OK]: (
    state: IMeldingState,
    {
      allowedStatusBegrunnelser,
    }: { allowedStatusBegrunnelser: IMeldingState['allowedStatusBegrunnelser'] }
  ) => ({
    ...state,
    allowedStatusBegrunnelser,
  }),

  [meldingActionTypes.CLEAR_MELDING_STATE]: (state: IMeldingState) => ({
    ...initialState,
    allowedStatuses: state.allowedStatuses,
    allowedStatusBegrunnelser: state.allowedStatusBegrunnelser,
  }),
})
