import { IUiState } from './types'
import { IMeldingFilterParams } from '../../common/melding-helpers'

export const uiActionTypes = {
  SET_PAGE_TITLE: <const>'ui/SET_PAGE_TITLE',
  INITIALIZE: <const>'ui/INITIALIZE',
  INITIALIZE_FAILED: <const>'ui/INITIALIZE_FAILED',
  INITIALIZE_SUCCESS: <const>'ui/INITIALIZE_SUCCESS',
  SET_ENVIRONMENT: <const>'ui/SET_ENVIRONMENT',
  SET_FILTER_PARAMS: <const>'ui/SET_FILTER_PARAMS',
}

export const uiActions = {
  setPageTitle: (title: string) => ({
    type: uiActionTypes.SET_PAGE_TITLE,
    title: title,
  }),
  setEnvironment: (environment: IUiState['environment']) => ({
    type: uiActionTypes.SET_ENVIRONMENT,
    environment,
  }),
  initialize: () => ({ type: uiActionTypes.INITIALIZE }),
  initializeSuccess: () => ({ type: uiActionTypes.INITIALIZE_SUCCESS }),
  initializeFailed: (error: string) => ({
    type: uiActionTypes.INITIALIZE_FAILED,
    error,
  }),
  setFilterParams: (filterParams: IMeldingFilterParams) => ({
    type: uiActionTypes.SET_FILTER_PARAMS,
    filterParams,
  }),
}
