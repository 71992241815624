import {
  DependencyList,
  EffectCallback,
  useEffect,
  useRef,
  useState,
} from 'react'
import { TypedUseSelectorHook, useSelector } from 'react-redux'
import * as R from 'ramda'
import history from '../history'
import { IStoreState } from '../reducers/types'

export const useTypedSelector: TypedUseSelectorHook<IStoreState> = useSelector

export const useActionOnBackButton = (
  actionOnBackButton: () => void,
  isActive: boolean
) => {
  return useEffect(() => {
    if (isActive) {
      return history.block((_location, action) => {
        if (action === 'POP') {
          actionOnBackButton?.()
          return false
        }
      })
    }
  }, [isActive, actionOnBackButton])
}

export const useDebounce = <T>(value: T, delay: number) => {
  const [debouncedValue, setDebouncedValue] = useState(value)

  useDeepEffect(() => {
    const timeOut = setTimeout(() => {
      setDebouncedValue(value)
    }, delay)

    return () => clearTimeout(timeOut)
  }, [delay, value])

  return debouncedValue
}

const useDeepCompareMemoize = (deps) => {
  const ref = useRef()

  if (!R.equals(deps, ref.current)) {
    ref.current = deps
  }

  return ref.current
}

export const useDeepEffect = (
  effectCallback: EffectCallback,
  deps: DependencyList
) => {
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(effectCallback, useDeepCompareMemoize(deps))
}
