import React from 'react'
import styled from 'styled-components'
import {
  Column,
  LetterIcon,
  Row,
  TeamsIcon,
  Text,
  TextLink,
} from '@mattilsynet/mt-ui'
import { IMelding } from '../../ducks/melding/types'

const AvsenderGrid = styled.div`
  & {
    display: grid;
    grid-template-columns: repeat(15, 1fr);
    width: 95%;
  }
  & .left {
    grid-column: 1;
  }
  & .center {
    grid-column: 5 / 12;
  }
  & .right {
    grid-column: 12 / 16;
  }
`

export const AvsenderCard = ({ melding }: { melding: IMelding }) => {
  return (
    <Column spacing={2} padding={[3, 1]} align="center">
      <AvsenderGrid>
        <Column className="left"> </Column>
        <Column className="center" spacing={0.5} align="center">
          <Text as="h3" weight="bold" size="heading3" uppercase>
            {melding.avsenderNavn}
          </Text>
          <Text as="h3" weight="light" size="heading3">
            {melding.avsenderTittel}
          </Text>
          <Text as="h3" weight="light" size="heading3">
            {melding.avsenderAvdelingNavn}
          </Text>
        </Column>
        <Column className="right" spacing={0.5}>
          {melding.avsenderEpost && (
            <Row justify="flex-end">
              <TextLink
                leftIcon={<TeamsIcon />}
                href={`https://teams.microsoft.com/l/chat/0/0?users=${melding.avsenderEpost}`}
                linkOutside
              >
                Microsoft Teams
              </TextLink>
            </Row>
          )}
          {melding.avsenderEpost && (
            <Row justify="flex-end">
              <TextLink
                leftIcon={<LetterIcon />}
                href={`mailto:${melding.avsenderEpost}`}
                linkOutside
              >
                E-post
              </TextLink>
            </Row>
          )}
        </Column>
      </AvsenderGrid>

      <AvsenderGrid>
        <Column className="left"> </Column>
        <Column className="center" spacing={0.5} align="center">
          <Text size="heading3">{melding.slakteriNavn}</Text>
          <Text size="heading3" weight="light">
            Efta-nummer: {melding.eftanummer}
          </Text>
        </Column>
        <Column className="right"> </Column>
      </AvsenderGrid>
    </Column>
  )
}
