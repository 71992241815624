import { combineEpics, Epic } from 'redux-observable'
import { ICommonApi } from '../../../api'
import { IStoreState } from '../../../reducers/types'
import {
  catchError,
  concat,
  defaultIfEmpty,
  filter,
  forkJoin,
  map,
  mergeMap,
  Observable,
  of,
  switchMap,
  withLatestFrom,
} from 'rxjs'
import * as R from 'ramda'
import { isOfType } from '@mattilsynet/mt-common/lib/common-redux/helpers'
import { API_ROUTES } from '../../../epics'
import { IAvdeling, IAvdelingerActions, IRegion } from '../types'
import { avdelingerActions, avdelingerActionTypes } from '../index'

export const fetchAvdelingerEpic =
  (
    commonApi: ICommonApi
  ): Epic<IAvdelingerActions, IAvdelingerActions, IStoreState> =>
  (action$, state$) =>
    action$.pipe(
      filter(isOfType(avdelingerActionTypes.FETCH_AVDELINGER)),
      withLatestFrom(state$),
      switchMap(([, state]) => {
        return of(state).pipe(
          commonApi.get(`${API_ROUTES.ORGENHET}/avdelinger`, state),
          map((resp) => resp._embedded.avdelingList),
          mergeMap((avdelinger: IAvdeling[]) => {
            const regionIds = R.uniqBy(
              R.prop('regionId'),
              avdelinger.filter((avdeling) => !!avdeling.regionId)
            ).map((avdeling) => avdeling.regionId)

            const regionRequests: Observable<any>[] = regionIds.map(
              (regionId) =>
                commonApi.get(
                  `${API_ROUTES.ORGENHET}/regioner/${regionId}`,
                  state
                )(of(state))
            )

            return forkJoin([...regionRequests]).pipe(
              defaultIfEmpty([]),
              mergeMap((regioner: IRegion[]) => {
                const avdelingerWithRegion = avdelinger.map((avdeling) => {
                  const region = regioner.find(
                    (region) => region.id === avdeling.regionId
                  )
                  return region ? { ...avdeling, region } : avdeling
                })

                return of(
                  avdelingerActions.fetchAvdelingerOk(avdelingerWithRegion)
                )
              })
            )
          }),
          catchError((err) =>
            concat(of(avdelingerActions.fetchAvdelingerFail(err.message)))
          )
        )
      })
    )

export default (commonApi: ICommonApi) =>
  combineEpics(fetchAvdelingerEpic(commonApi))
