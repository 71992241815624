import { IUiState } from '../types'
import { IMeldingFilterParams } from '../../../common/melding-helpers'

export const initialFilterParams: IMeldingFilterParams = {
  avdelinger: [],
  begrunnelser: [],
  oppfoelginger: [],
  aktiviteter: [],
  mottattDatoFrom: null,
  mottattDatoTo: null,
  dyrevelferd: null,
  merkeavvik: null,
}

export const initialState: IUiState = {
  environment: '',
  initializationStatus: {
    loading: false,
    error: null,
    loaded: false,
  },
  title: 'Melding til lokalt MT',
  filterParams: initialFilterParams,
}
