import { IAvdeling } from './types'
import { IStoreState } from '../../reducers/types'

export const STORE_NAME = 'avdelinger'

export const avdelingerActionTypes = {
  FETCH_AVDELINGER: `${STORE_NAME}/FETCH_AVDELINGER`,
  FETCH_AVDELINGER_OK: `${STORE_NAME}/FETCH_AVDELINGER_OK`,
  FETCH_AVDELINGER_FAIL: `${STORE_NAME}/FETCH_AVDELINGER_FAIL`,
}

export const avdelingerActions = {
  fetchAvdelinger: () => ({
    type: avdelingerActionTypes.FETCH_AVDELINGER,
  }),
  fetchAvdelingerOk: (avdelinger: IAvdeling[]) => ({
    type: avdelingerActionTypes.FETCH_AVDELINGER_OK,
    avdelinger,
  }),
  fetchAvdelingerFail: (error: string) => ({
    type: avdelingerActionTypes.FETCH_AVDELINGER_FAIL,
    error,
  }),
}

export const avdelingerSelectors = {
  getLoadingStatus: (
    state: IStoreState
  ): { loading: boolean; loaded: boolean; error: string | null } => ({
    loading: state[STORE_NAME].loading,
    loaded: state[STORE_NAME].loaded,
    error: state[STORE_NAME].error,
  }),

  getAvdelinger: (state: IStoreState): IAvdeling[] =>
    state[STORE_NAME].avdelinger,
}
