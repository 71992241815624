import { initialState } from './initialState'
import { uiActions, uiActionTypes } from '../actions'
import { commonRedux } from '@mattilsynet/mt-common'
import { IUiActions, IUiState } from '../types'

export const uiReducer = commonRedux.createReducer<IUiState, IUiActions>(
  initialState,
  {
    [uiActionTypes.SET_PAGE_TITLE]: (
      state: IUiState,
      { title }: { title: string }
    ): IUiState => ({ ...state, title }),

    [uiActionTypes.SET_ENVIRONMENT]: (
      state: IUiState,
      { environment }: { environment: IUiState['environment'] }
    ): IUiState => ({ ...state, environment }),

    [uiActionTypes.INITIALIZE]: (state: IUiState): IUiState => ({
      ...state,
      initializationStatus: {
        loading: true,
        error: null,
        loaded: false,
      },
    }),

    [uiActionTypes.INITIALIZE_SUCCESS]: (state: IUiState): IUiState => ({
      ...state,
      initializationStatus: {
        loading: false,
        error: null,
        loaded: true,
      },
    }),

    [uiActionTypes.INITIALIZE_FAILED]: (
      state: IUiState,
      { error }
    ): IUiState => ({
      ...state,
      initializationStatus: {
        loading: false,
        error: error,
        loaded: false,
      },
    }),

    [uiActionTypes.SET_FILTER_PARAMS]: (
      state: IUiState,
      { filterParams }: ReturnType<typeof uiActions.setFilterParams>
    ): IUiState => ({
      ...state,
      filterParams,
    }),
  }
)
