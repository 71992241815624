import { IStoreState } from '../../../reducers/types'
import { commonRedux, CommonReduxTypes } from '@mattilsynet/mt-common'

export const STORE_NAME = 'orgenhet'

const { fetchWithIndexHandlers, fetchActionTypes, fetchActions } =
  commonRedux.fetchWithIndexHelpers<IOrgenhet, typeof STORE_NAME>(
    STORE_NAME,
    'username'
  )

export const orgenhetActionsTypes = fetchActionTypes

export const orgenhetActions = fetchActions

export const orgenhetReducer = {
  [STORE_NAME]: commonRedux.createReducer<IOrgenhetState, IOrgenhetActions>(
    commonRedux.initialListState,
    fetchWithIndexHandlers
  ),
}

// types
export type IOrgenhet = IOrgenhetRemote.Orgenhet

export type IOrgenhetState = CommonReduxTypes.IStateDataList<IOrgenhet>

export type IOrgenhetActions = CommonReduxTypes.IGetActionTypesFromObject<
  typeof orgenhetActions
>

// selectors
export const orgenhetSelectors = {
  getOrgenhetByUsername: (username: string) => (state: IStoreState) =>
    state[STORE_NAME].data[username],
}
