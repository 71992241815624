import { Observable, of } from 'rxjs'
import dayjs from 'dayjs'
import * as R from 'ramda'
import { createQueryStringFromObject } from '@mattilsynet/mt-common/lib/utils/createQueryStringFromObject'
import PopulertMattilsynMelding = IMeldingTilLokaltMattilsynRemote.PopulertMattilsynMelding
import { ICommonApi } from '../../../api'
import { IStoreState } from '../../../reducers/types'
import { IAmFunn, IMelding, IPmFunn } from '../../melding/types'
import { API_ROUTES } from '../../../epics'
import { IMeldingFilterParams } from '../../../common/melding-helpers'

export const getMeldingListByEftanummer = (
  populertMattilsynMeldingList:
    | PopulertMattilsynMelding[]
    | IMelding[]
    | undefined
) =>
  (populertMattilsynMeldingList || []).reduce((acc, melding) => {
    if (melding.amFunnIds?.length === 0 && melding.pmFunnIds?.length === 0) {
      return acc
    }
    if (!acc[melding.eftanummer]) {
      acc[melding.eftanummer] = []
    }
    acc[melding.eftanummer].push(melding)
    return acc
  }, {})

export const getFunnRequests = (
  meldingListByEftanummer,
  commonApi: ICommonApi,
  state: IStoreState
) => {
  const requests: Observable<any>[] = []

  Object.entries(meldingListByEftanummer).forEach(
    ([eftanummer, meldingList]) => {
      const amFunnIds = R.pipe(
        R.reduce(
          (acc, { amFunnIds }) => (amFunnIds ? [...acc, ...amFunnIds] : acc),
          []
        ),
        R.uniq
      )(meldingList)
      const pmFunnIds = R.pipe(
        R.reduce(
          (acc, { pmFunnIds }) => (pmFunnIds ? [...acc, ...pmFunnIds] : acc),
          []
        ),
        R.uniq
      )(meldingList)

      if (amFunnIds.length > 0) {
        requests.push(
          commonApi.get(
            `${
              API_ROUTES.KJOETTKONTROLL_FUNN
            }/v2/am/funn/${createQueryStringFromObject('?')({
              slakteDatoFrom: dayjs()
                .subtract(365, 'days')
                .format('YYYY-MM-DD'),
              eftanummer,
              funnIds: amFunnIds.join(','),
              expand: ['individer', 'observasjoner', 'bildeIds'],
              size: 100,
            })}`,
            state
          )(of(state))
        )
      }
      if (pmFunnIds.length > 0) {
        requests.push(
          commonApi.get(
            `${
              API_ROUTES.KJOETTKONTROLL_FUNN
            }/v2/pm/funn/${createQueryStringFromObject('?')({
              slakteDatoFrom: dayjs()
                .subtract(365, 'days')
                .format('YYYY-MM-DD'),
              eftanummer: eftanummer,
              funnIds: pmFunnIds.join(','),
              expand: ['observasjoner', 'bildeIds'],
              size: 100,
            })}`,
            state
          )(of(state))
        )
      }
    }
  )

  return requests
}

export const populateMeldingsHistorikkWithFunnResponse = (
  funnResponse,
  populertMattilsynMeldingList
): IMelding[] => {
  let amFunnList: (IAmFunn | IPmFunn)[] = []
  let pmFunnList: (IAmFunn | IPmFunn)[] = []

  funnResponse.forEach((r) => {
    if (r?._embedded?.amFunnList) {
      amFunnList = [...amFunnList, ...r._embedded.amFunnList]
    } else if (r?._embedded?.pmFunnList) {
      pmFunnList = [...pmFunnList, ...r._embedded.pmFunnList]
    }
  })

  amFunnList = amFunnList.map((funn) => ({
    ...funn,
    isAmFunn: true,
  }))
  pmFunnList = pmFunnList.map((funn) => ({
    ...funn,
    isAmFunn: false,
  }))

  return populertMattilsynMeldingList.map((melding) => {
    const amFunn = melding.amFunnIds.map((id) =>
      amFunnList.find((funn) => funn.funnId === id)
    )
    const pmFunn = melding.pmFunnIds.map((id) =>
      pmFunnList.find((funn) => funn.funnId === id)
    )
    const funnList = [...amFunn, ...pmFunn]
    return {
      ...melding,
      sistRedigert: dayjs(melding.sistRedigert),
      funnList: funnList.filter((funn) => funn),
    }
  })
}

interface IParams {
  'filter.mottakere'?: string[]
  'filter.oppfoelginger'?: string[]
  'filter.begrunnelser'?: string[]
  'filter.aktiviteter'?: string[]
  'filter.fraDato'?: string
  'filter.tilDato'?: string
  'filter.kategorier'?: ('MERKEAVVIK_DYR' | 'DYREVELFERD')[]
}

// TODO: aktiviteter
export const convertMeldingFilterParams = (
  filterParams?: IMeldingFilterParams
): IParams => {
  if (!filterParams) return {}
  const params: IParams = {}
  if (filterParams.avdelinger.length > 0) {
    params['filter.mottakere'] = filterParams.avdelinger.map((a) => a.value)
  }
  if (filterParams.oppfoelginger.length > 0) {
    params['filter.oppfoelginger'] = filterParams.oppfoelginger.map(
      (o) => o.value
    )
  }
  if (filterParams.begrunnelser.length > 0) {
    params['filter.begrunnelser'] = filterParams.begrunnelser.map(
      (o) => o.value
    )
  }
  if (filterParams.aktiviteter.length > 0) {
    params['filter.aktivitetIds'] = filterParams.aktiviteter.map((aktivitet) =>
      aktivitet.value.split('$').pop()
    )
  }
  if (filterParams.mottattDatoFrom) {
    params['filter.fraDato'] = filterParams.mottattDatoFrom
  }
  if (filterParams.mottattDatoTo) {
    params['filter.tilDato'] = filterParams.mottattDatoTo
  }
  if (filterParams.merkeavvik || filterParams.dyrevelferd) {
    params['filter.kategorier'] = []
    if (filterParams.merkeavvik) {
      params['filter.kategorier'].push('MERKEAVVIK_DYR')
    }
    if (filterParams.dyrevelferd) {
      params['filter.kategorier'].push('DYREVELFERD')
    }
  }
  return params
}
