import { initialState } from './initialState'
import { userActionTypes } from '../actions'
import { IUserActions, IUserState } from '../types'
import { commonRedux } from '@mattilsynet/mt-common'

export const userReducer = commonRedux.createReducer<IUserState, IUserActions>(
  initialState,
  {
    [userActionTypes.SET_LOGGED_IN_USER]: (
      state: IUserState,
      { authUser }: { authUser: IUserState['authUser'] }
    ): IUserState => ({
      ...state,
      authUser,
    }),

    [userActionTypes.FETCH_USER_ORGENHET_OK]: (
      state: IUserState,
      { me }: { me: IUserState['me'] }
    ): IUserState => ({
      ...state,
      me,
    }),

    [userActionTypes.FETCH_USER_AVDELING_OK]: (
      state: IUserState,
      { avdeling }: { avdeling: IUserState['avdeling'] }
    ): IUserState => ({
      ...state,
      avdeling,
    }),

    [userActionTypes.FETCH_USER_REGION_OK]: (
      state: IUserState,
      { region }: { region: IUserState['region'] }
    ): IUserState => ({
      ...state,
      region,
    }),

    [userActionTypes.UPDATE_TOKEN]: (
      state: IUserState,
      { accessToken }: { accessToken: IUserState['authUser']['accessToken'] }
    ): IUserState => ({
      ...state,
      authUser: {
        ...state.authUser,
        accessToken,
      },
    }),

    [userActionTypes.USER_EXPIRED]: (state: IUserState): IUserState => ({
      ...state,
      authUser: { ...initialState.authUser },
    }),

    [userActionTypes.USER_SIGNED_OUT]: (): IUserState => ({
      ...initialState,
    }),
  }
)
