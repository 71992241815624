import * as R from 'ramda'
import { Observable } from 'rxjs'
import { ajax, AjaxRequest } from 'rxjs/ajax'
import { filter, map, mapTo, mergeMap, withLatestFrom } from 'rxjs/operators'

import { IStoreState } from '../reducers/types'
import { StateObservable } from 'redux-observable'
import { IUserState } from '../ducks/user/types'

type ReqMethodType = 'GET' | 'POST' | 'DELETE' | 'PUT'

export interface ICommonApi {
  get(
    url: string,
    state: IStoreState
  ): (obs$: Observable<any>) => Observable<any>
  delete(
    url: string,
    state: IStoreState
  ): (obs$: Observable<any>) => Observable<any>
  post(
    url: string,
    state: IStoreState,
    data: any
  ): (obs$: Observable<any>) => Observable<any>
  put(
    url: string,
    state: IStoreState,
    data: any
  ): (obs$: Observable<any>) => Observable<any>
  default(
    url: string,
    method: ReqMethodType,
    state: IStoreState,
    data?: any
  ): (obs$: Observable<any>) => Observable<any>
}

export type IAjaxWithAuth = (
  state$: StateObservable<IStoreState>
) => (obs$: Observable<AjaxRequest>) => any

export const getAuthTokenSelector =
  (user: IUserState) =>
  (obs$: Observable<any>): Observable<string> =>
    obs$.pipe(
      filter(() => R.has('authUser', user)),
      mapTo(R.path(['authUser', 'accessToken'], user))
    )

const getAuthTokenHeaderSelector = (user: IUserState): AjaxRequest =>
  R.pipe(
    R.filter(() => R.has('authUser')),
    R.path(['authUser', 'accessToken']),
    R.concat('Bearer '),
    R.objOf('Authorization'),
    R.objOf('headers')
  )(user)

const withAuthentication =
  (url: string, body: any, method: ReqMethodType = 'GET') =>
  (obs$: Observable<any>) =>
    obs$.pipe(
      map((accessToken: string) =>
        ajax({
          headers: {
            Authorization: `Bearer ${accessToken}`,
            Accept: 'application/hal+json,application/json',
            'Content-Type': 'application/json',
          },
          method,
          url,
          body,
        })
      )
    )

export const ajaxWithAuth: IAjaxWithAuth = (state$) => (obs$) =>
  obs$.pipe(
    withLatestFrom(state$),
    map(([ajaxObj, { user }]) =>
      R.mergeRight(getAuthTokenHeaderSelector(user), ajaxObj)
    ),
    mergeMap(ajax)
  )

export const commonApi: ICommonApi = {
  get: (url, state) => (obs$) =>
    obs$.pipe(
      getAuthTokenSelector(state.user),
      withAuthentication(url, undefined),
      mergeMap((resp$) =>
        resp$.pipe(
          filter((resp) => resp.response as any),
          map((resp) => resp.response)
        )
      )
    ),

  delete: (url, state) => commonApi.default(url, 'DELETE', state),

  post: (url, state, data) => commonApi.default(url, 'POST', state, data),

  put: (url, state, data) => commonApi.default(url, 'PUT', state, data),

  default:
    (url, method, state, data = undefined) =>
    (obs$) =>
      obs$.pipe(
        getAuthTokenSelector(state.user),
        withAuthentication(url, data, method),
        mergeMap((resp$) => resp$.pipe(map((resp) => resp)))
      ),
}
