import React from 'react'
import { Switch, Route } from 'react-router-dom'
import { Melding } from '../melding'
import { MeldingsHistorikkForVirksomhet } from '../meldingshistorikk-for-virksomhet'
import { MeldingsHistorikkForRolle } from '../meldingshistorikk-for-rolle'

const HomeRoute = () => {
  return (
    <Switch>
      <Route path="/melding/:meldingId?" component={Melding} />
      <Route
        path="/meldinger/:produsentTilsynsobjektId"
        component={MeldingsHistorikkForVirksomhet}
      />
      <Route
        exact
        path={['/', '/mine-meldinger']}
        component={MeldingsHistorikkForRolle}
      />
    </Switch>
  )
}

export default HomeRoute
