import React, { ReactNode, useCallback, useEffect, useState } from 'react'
import {
  Column,
  ErrorBox,
  LoadingSpinner,
  Row,
  SearchInput,
  SelectList,
  Text,
} from '@mattilsynet/mt-ui'
import styled from 'styled-components'
import { useMediaQuery } from 'react-responsive'
import classNames from 'classnames'
import { ModalWrapper } from '../modal-wrapper'
import { IMeldingsHistorikkState } from '../../ducks/meldingshistorikk/types'
import { useDispatch } from 'react-redux'
import { useDebounce, useTypedSelector } from '../../common/custom-hooks'
import { ansatteActions, ansatteSelectors } from '../../ducks/ansatte'
import { IRemoteVurdering } from '../../ducks/melding/types'

const StyledRow = styled(Row)`
  & {
    width: 100%;
  }
  & .Text.ansatt-navn {
    min-width: 250px;
  }
`

export const FordelTilModal = ({
  onCancel,
  isOpen,
  accessType,
  vurdering,
  meldingId,
}: {
  onCancel: () => void
  isOpen: boolean
  accessType: IMeldingsHistorikkState['accessType']
  vurdering?: IRemoteVurdering
  meldingId?: string
}) => {
  const dispatch = useDispatch()
  const isTinyScreen = useMediaQuery({ query: '(max-width: 767px)' })
  const [searchInput, setSearchInput] = useState('')
  const debouncedSearchInput = useDebounce(searchInput, 200)
  const [selectedAnsattBrukernavn, setSelectedAnsattBrukernavn] = useState<
    string | undefined
  >(undefined)

  const loadingStatus = useTypedSelector(ansatteSelectors.getLoadingStatus)
  const ansatte = useTypedSelector(ansatteSelectors.getAnsatte)
  const kontor = useTypedSelector(ansatteSelectors.getKontor)
  const [dataList, setDataList] = useState<
    { value: string; label: ReactNode }[]
  >([])

  const fetchData = useCallback(() => {
    dispatch(ansatteActions.fetchAnsatte(accessType))
  }, [accessType, dispatch])

  useEffect(() => {
    fetchData()
  }, [fetchData])

  const handleSearchChange = (e) => {
    const value = e.target.value
    setSearchInput(value)
  }

  const handleFordelMelding = () => {
    const ansattNavn = ansatte.find(
      (ansatt) => ansatt.brukernavn === selectedAnsattBrukernavn
    )?.navn
    dispatch(
      ansatteActions.updateFordeltTil(
        String(meldingId),
        {
          ...vurdering,
          fordeltTil: selectedAnsattBrukernavn,
        },
        ansattNavn
      )
    )
    onCancel()
  }

  useEffect(() => {
    const searchedAnsatte = ansatte.filter((ansatt) =>
      String(ansatt.navn)
        .toLowerCase()
        .includes(debouncedSearchInput.toLowerCase())
    )

    const prevFordeltTil = searchedAnsatte.find(
      (ansatt) => ansatt.brukernavn === vurdering?.fordeltTil
    )?.brukernavn
    const fordeltTil = selectedAnsattBrukernavn ?? prevFordeltTil
    if (!selectedAnsattBrukernavn && fordeltTil) {
      setSelectedAnsattBrukernavn(fordeltTil)
    }

    const updatedDataList = (searchedAnsatte || []).map((ansatt) => ({
      value: ansatt.brukernavn,
      label: (
        <StyledRow justify="space-between" key={ansatt.brukernavn} smColumn>
          <Text
            className={classNames({ 'ansatt-navn': !isTinyScreen })}
            margin={isTinyScreen ? [0, 1, 1, 0] : [0, 1, 0, 0]}
            weight={fordeltTil === ansatt.brukernavn ? 'bold' : 'regular'}
          >
            {ansatt.navn}
          </Text>
          <Text
            weight={fordeltTil === ansatt.brukernavn ? 'bold' : 'regular'}
            margin={isTinyScreen ? [0, 1, 0, 0] : [0, 1, 0, 0]}
          >
            {ansatt.kontorNavn}
          </Text>
        </StyledRow>
      ),
    }))

    setDataList(updatedDataList)
  }, [
    ansatte,
    debouncedSearchInput,
    selectedAnsattBrukernavn,
    vurdering?.fordeltTil,
    isTinyScreen,
  ])

  const renderContent = () => {
    if (loadingStatus?.loading && !loadingStatus?.loaded) {
      return <LoadingSpinner title="Laster ansatte" />
    }

    if (loadingStatus?.error) {
      return (
        <ErrorBox
          errorText="Kunne ikke hente ansatte"
          errorAction={() => fetchData()}
          errorActionText="Prøv på nytt"
        />
      )
    }

    return (
      <Column spacing={4}>
        <Column padding={[1, 3]} spacing={2}>
          <Text>
            Når du fordeler meldingen til en kollega vil den havne i oversikten
            til den du fordeler til.
          </Text>
          <SearchInput
            onChange={handleSearchChange}
            placeholder="Søk etter kollega"
            value={searchInput}
          />
        </Column>

        <SelectList
          dataList={dataList}
          onClick={setSelectedAnsattBrukernavn}
          selected={[String(selectedAnsattBrukernavn)]}
        />
      </Column>
    )
  }

  return (
    <ModalWrapper
      title={`Fordel melding til kollega i ${kontor?.name?.[0]?.toLowerCase()}${kontor?.name?.slice(
        1
      )}`}
      onCancel={onCancel}
      onOk={handleFordelMelding}
      isOpen={isOpen}
      paddingHorizontal={0}
      buttonOkText="Fordel melding"
    >
      {renderContent()}
    </ModalWrapper>
  )
}
