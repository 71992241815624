import React, { useState } from 'react'
import { ModalWrapper } from '../modal-wrapper'
import {
  Column,
  ErrorBox,
  LoadingSpinner,
  Row,
  SelectList,
  TextLink,
} from '@mattilsynet/mt-ui'
import { useAvdelinger } from '../../ducks/avdelinger/hooks'
import { FilterList, IDisplayAndValue } from '../filter-list'
import { IAvdeling } from '../../ducks/avdelinger/types'
import { IMeldingsHistorikkState } from '../../ducks/meldingshistorikk/types'

const getAvdelingNavn = (avdeling: IAvdeling) => {
  const nameWithoutAvdeling = `${avdeling.navn}`.split('Avdeling ').pop() || ''
  const uppercasedFirstChar =
    nameWithoutAvdeling.charAt(0).toUpperCase() + nameWithoutAvdeling.slice(1)
  return uppercasedFirstChar
}

export const AvdelingerFilterModal = ({
  isOpen,
  onCancel,
  avdelingerFilter,
  onSetAvdelingerFilter,
  accessType,
}: {
  isOpen: boolean
  onCancel: () => void
  avdelingerFilter: IDisplayAndValue[]
  onSetAvdelingerFilter: (data: IDisplayAndValue[]) => void
  accessType: IMeldingsHistorikkState['accessType']
}) => {
  const {
    fetchAvdelinger: fetchData,
    avdelingerLoadingStatus: loadingStatus,
    avdelingerGroupedByRegionId,
    avdelingerInUsersRegion,
  } = useAvdelinger()
  const [selectedAvdelinger, setSelectedAvdelinger] =
    useState<IDisplayAndValue[]>(avdelingerFilter)
  const regionOgAvdelingerTreeData = Object.values(avdelingerGroupedByRegionId)
    .filter((avdelinger) => !!avdelinger[0].regionId)
    .map((avdelinger) => {
      const regionNavn = avdelinger[0].region?.navn
      const regionId = avdelinger[0].region?.id

      const avdelingerTreeData = avdelinger.map((avdeling) => ({
        value: avdeling.id,
        display: getAvdelingNavn(avdeling),
      }))
      return {
        value: regionId,
        display: regionNavn,
        children: avdelingerTreeData,
      }
    })
  const avdelingerWithoutRegion = Object.values(avdelingerGroupedByRegionId)
    .filter((avdelinger) => !avdelinger[0].regionId)
    .flat()
    .map((avdeling) => ({
      value: avdeling.id,
      display: getAvdelingNavn(avdeling),
    }))

  const avdelingerInUsersRegionValueDisplay = avdelingerInUsersRegion.map(
    (avdeling) => ({ value: avdeling.id, display: getAvdelingNavn(avdeling) })
  )

  const renderContent = () => {
    if (loadingStatus?.loading && !loadingStatus?.loaded) {
      return <LoadingSpinner title="Laster avdelinger" />
    }
    if (loadingStatus?.error) {
      return (
        <ErrorBox
          errorText="Kunne ikke hente avdelinger"
          errorAction={() => fetchData()}
          errorActionText="Prøv på nytt"
        />
      )
    }

    if (accessType === 'REGION') {
      return (
        <Column>
          <Row justify="flex-end" padding={[1, 2]}>
            <TextLink small onClick={() => setSelectedAvdelinger([])}>
              Fjern alle valg
            </TextLink>
          </Row>
          <SelectList
            dataList={avdelingerInUsersRegionValueDisplay.map((avdeling) => ({
              ...avdeling,
              label: avdeling.display,
            }))}
            onClick={(value) => {
              const avdeling = avdelingerInUsersRegionValueDisplay.find(
                (a) => a.value === value
              )
              const isInList = !!selectedAvdelinger.find(
                (a) => a.value === value
              )
              if (isInList) {
                setSelectedAvdelinger(
                  selectedAvdelinger.filter((a) => a.value !== value)
                )
              } else if (!isInList && avdeling) {
                setSelectedAvdelinger([...(selectedAvdelinger || []), avdeling])
              }
            }}
            selected={selectedAvdelinger.map((avdeling) => avdeling.value)}
          />
        </Column>
      )
    }

    return (
      <>
        <FilterList
          treeData={regionOgAvdelingerTreeData}
          onChange={(data) => setSelectedAvdelinger(data)}
          selectedNodes={selectedAvdelinger}
        />
        <FilterList
          treeData={avdelingerWithoutRegion}
          onChange={(data) => setSelectedAvdelinger(data)}
          selectedNodes={selectedAvdelinger}
          hideRemoveAll
        />
      </>
    )
  }

  return (
    <ModalWrapper
      isOpen={isOpen}
      onCancel={onCancel}
      paddingHorizontal={0}
      title="Region og avdeling"
      buttonOkText="Bruk valgte filter"
      onOk={() => {
        onSetAvdelingerFilter(selectedAvdelinger)
        onCancel()
      }}
    >
      {renderContent()}
    </ModalWrapper>
  )
}
