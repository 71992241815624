import React, { useState, useRef, FocusEvent, RefObject } from 'react'
import { UserMenu as UserMenuMtUi, TextLink } from '@mattilsynet/mt-ui'
import { useTypedSelector } from '../../common/custom-hooks'
import { userSelectors } from '../../ducks/user/selectors'
import { pushState } from '../../common/common-router'
import { useDispatch } from 'react-redux'

const UserMenu = () => {
  const dispatch = useDispatch()

  const { name, title } = useTypedSelector(userSelectors.getMe)
  const { name: avdelingName } = useTypedSelector(userSelectors.getAvdeling)

  const userMenuRef: RefObject<HTMLDivElement> = useRef(null)

  const [isOpen, setIsOpen] = useState(false)

  const toggleMenu = () => setIsOpen(!isOpen)
  const logout = () => dispatch(pushState('/logout'))

  const goToAbout = () => {
    setIsOpen(!isOpen)
    dispatch(pushState('/about'))
  }

  const onCloseMenuOnBlur = (event: FocusEvent) => {
    if (
      !event.relatedTarget ||
      (userMenuRef.current &&
        !userMenuRef.current.contains(event.relatedTarget as HTMLDivElement))
    ) {
      return setIsOpen(false)
    }
  }

  return (
    <div onBlur={onCloseMenuOnBlur} ref={userMenuRef}>
      <UserMenuMtUi
        isOpen={isOpen}
        onClick={toggleMenu}
        name={name || ''}
        title={title || ''}
        division={avdelingName || ''}
      >
        <div className="link-group">
          <TextLink href="https://makuba.mattilsynet.no/" linkOutside>
            Finn hjelp i MAKUBA
          </TextLink>
          <TextLink onClick={goToAbout}>Om applikasjonen</TextLink>
        </div>
        <TextLink onClick={logout}>Logg ut</TextLink>
      </UserMenuMtUi>
    </div>
  )
}

export default UserMenu
