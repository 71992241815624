import React, { ReactChild } from 'react'
import {
  ErrorBox,
  LoadingSpinner,
  TextLink,
  TreeNode,
} from '@mattilsynet/mt-ui'
import * as R from 'ramda'
import './style.css'

export interface IDisplayAndValue {
  display: string
  value: string
}

export interface ITreeData {
  display?: string
  displayFunc?: (value: string) => ReactChild
  value: string
  children?: ITreeData[]
}

interface IOwnProps {
  onChange: (selectedNodes: IDisplayAndValue[]) => void
  treeData: ITreeData[]
  selectedNodes: IDisplayAndValue[]
  loading?: boolean
  error?: any
  fetchAction?: () => void
  hideRemoveAll?: boolean
}

export const getLeafsValueAndDisplay = (data: ITreeData): IDisplayAndValue[] =>
  data.children && data.children.length > 0
    ? R.flatten(data.children.map((child) => getLeafsValueAndDisplay(child)))
    : [{ value: data.value, display: data.display }]

export const FilterList = ({
  error,
  fetchAction,
  loading,
  onChange,
  selectedNodes,
  treeData,
  hideRemoveAll,
}: IOwnProps) => {
  const onClick = (data: ITreeData, selected: boolean) => {
    return onChange(
      selected
        ? R.uniq(selectedNodes.concat(getLeafsValueAndDisplay(data)))
        : R.without(getLeafsValueAndDisplay(data), selectedNodes)
    )
  }

  if (loading) return <LoadingSpinner title="laster.." />

  if (error) {
    return (
      <ErrorBox
        errorText="Kunne ikke laste innhold..."
        errorAction={() => fetchAction?.()}
        errorActionText="Prøv på nytt"
      />
    )
  }

  return (
    <div className="filter-list">
      {!hideRemoveAll && (
        <div className="remove-filters">
          <TextLink onClick={() => onChange([])}>Fjern alle valg</TextLink>
        </div>
      )}
      {treeData.map((data, index) => (
        <TreeNode
          key={data.value}
          onClick={onClick}
          data={data}
          selectedNodes={selectedNodes.map((node) => node.value)}
          index={index + 1}
        />
      ))}
    </div>
  )
}
