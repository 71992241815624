import { commonRedux } from '@mattilsynet/mt-common'
import {
  AccessType,
  IMeldingsHistorikkActions,
  IMeldingsHistorikkState,
} from '../types'
import { meldingsHistorikkActionTypes } from '../index'
import { IAnsatteActions } from '../../ansatte/types'
import { ansatteActionTypes } from '../../ansatte'
import { IMelding, IRemoteVurdering } from '../../melding/types'

const initialState: IMeldingsHistorikkState = {
  loading: false,
  loaded: false,
  error: null,
  meldingsHistorikkForVirksomhet: undefined,
  meldingsHistorikkForRolle: undefined,
  mineMeldinger: undefined,
  openRows: [],
  virksomhet: undefined,
  accessType: AccessType.INGEN,
  meldingsHistorikkForRollePageMetaData: {},
  mineMeldingerPageMetaData: {},
  funnLoaded: false,
  tilsynUtfoertLoaded: false,
  filtering: false,
}

export const meldingsHistorikkReducer = commonRedux.createReducer<
  IMeldingsHistorikkState,
  IMeldingsHistorikkActions | IAnsatteActions
>(initialState, {
  [ansatteActionTypes.UPDATE_FORDELT_TIL_OK]: (
    state: IMeldingsHistorikkState,
    { meldingId, vurdering }: { meldingId: string; vurdering: IRemoteVurdering }
  ) => {
    const meldingsHistorikkForRolle = state.meldingsHistorikkForRolle?.map(
      (melding) => {
        if (melding.meldingId === meldingId) {
          return {
            ...melding,
            vurdering: {
              ...melding.vurdering,
              fordeltTil: vurdering.fordeltTil,
            },
          }
        }
        return melding
      }
    )

    const mineMeldingerFordeltTil =
      state.mineMeldinger?.[0]?.vurdering?.fordeltTil
    const mineMeldinger = state.mineMeldinger
      ?.map((melding) => {
        if (melding.meldingId === meldingId) {
          return {
            ...melding,
            vurdering: {
              ...melding.vurdering,
              fordeltTil: vurdering.fordeltTil,
            },
          }
        }
        return melding
      })
      .filter(
        (melding) => melding.vurdering?.fordeltTil === mineMeldingerFordeltTil
      )
    return { ...state, meldingsHistorikkForRolle, mineMeldinger }
  },
  [meldingsHistorikkActionTypes.FETCH_HISTORIKK_FOR_VIRKSOMHET]: (
    state: IMeldingsHistorikkState
  ) => ({
    ...state,
    loading: true,
  }),

  [meldingsHistorikkActionTypes.FETCH_HISTORIKK_FOR_VIRKSOMHET_OK]: (
    state: IMeldingsHistorikkState,
    {
      meldingsHistorikk,
      virksomhet,
    }: {
      meldingsHistorikk: IMeldingsHistorikkState['meldingsHistorikkForVirksomhet']
      virksomhet: IMeldingsHistorikkState['virksomhet']
    }
  ) => ({
    ...state,
    loading: false,
    loaded: true,
    error: null,
    funnLoaded: false,
    tilsynUtfoertLoaded: false,
    meldingsHistorikkForVirksomhet: meldingsHistorikk,
    virksomhet,
    openRows: state.openRows,
  }),

  [meldingsHistorikkActionTypes.FETCH_HISTORIKK_FOR_VIRKSOMHET_FAIL]: (
    state: IMeldingsHistorikkState,
    { err }: { err: string }
  ) => ({
    ...state,
    loading: false,
    error: err,
  }),

  [meldingsHistorikkActionTypes.FETCH_HISTORIKK_FOR_ROLLE]: (
    state: IMeldingsHistorikkState,
    { filtering }: { filtering: boolean }
  ) => ({
    ...state,
    loading: true,
    filtering,
  }),

  [meldingsHistorikkActionTypes.FETCH_HISTORIKK_FOR_ROLLE_OK]: (
    state: IMeldingsHistorikkState,
    {
      meldingsHistorikk,
      accessType,
      fetchMore,
      pageMetaData,
    }: {
      meldingsHistorikk: IMeldingsHistorikkState['meldingsHistorikkForRolle']
      accessType: IMeldingsHistorikkState['accessType']
      fetchMore: boolean
      pageMetaData: IMeldingsHistorikkState['meldingsHistorikkForRollePageMetaData']
    }
  ) => ({
    ...state,
    loading: false,
    loaded: true,
    error: null,
    funnLoaded: false,
    tilsynUtfoertLoaded: false,
    filtering: false,
    meldingsHistorikkForRolle: fetchMore
      ? [
          ...(state.meldingsHistorikkForRolle || []),
          ...(meldingsHistorikk || []),
        ]
      : meldingsHistorikk,
    accessType,
    openRows: state.openRows,
    meldingsHistorikkForRollePageMetaData: pageMetaData,
  }),

  [meldingsHistorikkActionTypes.SET_SORTED_LIST]: (
    state: IMeldingsHistorikkState,
    {
      sortedList,
    }: {
      sortedList: IMelding[]
    }
  ) => ({
    ...state,
    sortedList,
  }),

  [meldingsHistorikkActionTypes.FETCH_HISTORIKK_FOR_ROLLE_FAIL]: (
    state: IMeldingsHistorikkState,
    { err }: { err: string }
  ) => ({
    ...state,
    loading: false,
    error: err,
  }),

  [meldingsHistorikkActionTypes.FETCH_FUNN_OK]: (
    state: IMeldingsHistorikkState,
    {
      meldingsHistorikk,
      sourceType,
    }: {
      meldingsHistorikk:
        | IMeldingsHistorikkState['meldingsHistorikkForVirksomhet']
        | IMeldingsHistorikkState['meldingsHistorikkForRolle']
        | IMeldingsHistorikkState['mineMeldinger']
      sourceType:
        | typeof meldingsHistorikkActionTypes.FETCH_HISTORIKK_FOR_VIRKSOMHET_OK
        | typeof meldingsHistorikkActionTypes.FETCH_HISTORIKK_FOR_ROLLE_OK
        | typeof meldingsHistorikkActionTypes.FETCH_MINE_MELDINGER_OK
    }
  ) => {
    let stateHistorikk = state.meldingsHistorikkForRolle
    if (sourceType === meldingsHistorikkActionTypes.FETCH_MINE_MELDINGER_OK) {
      stateHistorikk = state.mineMeldinger
    } else if (
      sourceType ===
      meldingsHistorikkActionTypes.FETCH_HISTORIKK_FOR_VIRKSOMHET_OK
    ) {
      stateHistorikk = state.meldingsHistorikkForVirksomhet
    }

    const updatedHistorikk = stateHistorikk?.map((melding) => {
      const updatedMelding = meldingsHistorikk?.find(
        (mld) => mld.meldingId === melding.meldingId
      )
      if (updatedMelding) {
        return { ...melding, ...updatedMelding }
      }
      return melding
    })

    const updatedState = {
      ...state,
      funnLoaded: true,
    }

    if (sourceType === meldingsHistorikkActionTypes.FETCH_MINE_MELDINGER_OK) {
      updatedState.mineMeldinger = updatedHistorikk
    } else if (
      sourceType ===
      meldingsHistorikkActionTypes.FETCH_HISTORIKK_FOR_VIRKSOMHET_OK
    ) {
      updatedState.meldingsHistorikkForVirksomhet = updatedHistorikk
    } else {
      updatedState.meldingsHistorikkForRolle = updatedHistorikk
    }
    return updatedState
  },

  [meldingsHistorikkActionTypes.FETCH_TILSYN_UTFOERT_OK]: (
    state: IMeldingsHistorikkState,
    {
      meldingsHistorikk,
      sourceType,
    }: {
      meldingsHistorikk:
        | IMeldingsHistorikkState['meldingsHistorikkForVirksomhet']
        | IMeldingsHistorikkState['meldingsHistorikkForRolle']
        | IMeldingsHistorikkState['mineMeldinger']
      sourceType:
        | typeof meldingsHistorikkActionTypes.FETCH_HISTORIKK_FOR_VIRKSOMHET_OK
        | typeof meldingsHistorikkActionTypes.FETCH_HISTORIKK_FOR_ROLLE_OK
        | typeof meldingsHistorikkActionTypes.FETCH_MINE_MELDINGER_OK
    }
  ) => {
    let stateHistorikk = state.meldingsHistorikkForRolle
    if (sourceType === meldingsHistorikkActionTypes.FETCH_MINE_MELDINGER_OK) {
      stateHistorikk = state.mineMeldinger
    } else if (
      sourceType ===
      meldingsHistorikkActionTypes.FETCH_HISTORIKK_FOR_VIRKSOMHET_OK
    ) {
      stateHistorikk = state.meldingsHistorikkForVirksomhet
    }

    const updatedHistorikk = stateHistorikk?.map((melding) => {
      const updatedMelding = meldingsHistorikk?.find(
        (mld) => mld.meldingId === melding.meldingId
      )
      if (updatedMelding) {
        return { ...melding, ...updatedMelding }
      }
      return melding
    })

    const updatedState = {
      ...state,
      tilsynUtfoertLoaded: true,
    }
    if (sourceType === meldingsHistorikkActionTypes.FETCH_MINE_MELDINGER_OK) {
      updatedState.mineMeldinger = updatedHistorikk
    } else if (
      sourceType ===
      meldingsHistorikkActionTypes.FETCH_HISTORIKK_FOR_VIRKSOMHET_OK
    ) {
      updatedState.meldingsHistorikkForVirksomhet = updatedHistorikk
    } else {
      updatedState.meldingsHistorikkForRolle = updatedHistorikk
    }

    if (
      sourceType ===
      meldingsHistorikkActionTypes.FETCH_HISTORIKK_FOR_VIRKSOMHET_OK
    ) {
      updatedState.meldingsHistorikkForVirksomhet = updatedHistorikk
    } else {
      updatedState.meldingsHistorikkForRolle = updatedHistorikk
    }
    return updatedState
  },

  [meldingsHistorikkActionTypes.SET_OPEN_ROWS]: (
    state: IMeldingsHistorikkState,
    { openRows }: { openRows: string[] }
  ) => ({
    ...state,
    openRows: openRows,
  }),

  [meldingsHistorikkActionTypes.FETCH_MINE_MELDINGER]: (
    state: IMeldingsHistorikkState
  ) => ({
    ...state,
    loading: true,
  }),

  [meldingsHistorikkActionTypes.FETCH_MINE_MELDINGER_OK]: (
    state: IMeldingsHistorikkState,
    {
      meldingsHistorikk,
      fetchMore,
      pageMetaData,
    }: {
      meldingsHistorikk: IMeldingsHistorikkState['mineMeldinger']
      fetchMore: boolean
      pageMetaData: IMeldingsHistorikkState['mineMeldingerPageMetaData']
    }
  ) => ({
    ...state,
    loading: false,
    loaded: true,
    error: null,
    funnLoaded: false,
    tilsynUtfoertLoaded: false,
    mineMeldinger: fetchMore
      ? [...(state.mineMeldinger || []), ...(meldingsHistorikk || [])]
      : meldingsHistorikk,
    mineMeldingerPageMetaData: pageMetaData,
  }),

  [meldingsHistorikkActionTypes.FETCH_MINE_MELDINGER_FAIL]: (
    state: IMeldingsHistorikkState,
    { error }: { error: string }
  ) => ({
    ...state,
    loading: false,
    loaded: true,
    error,
  }),
})
