import { useCallback, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import * as R from 'ramda'
import { useTypedSelector } from '../../common/custom-hooks'
import { avdelingerActions, avdelingerSelectors } from './index'
import { IAvdeling } from './types'
import { userSelectors } from '../user/selectors'

export const useAvdelinger = () => {
  const dispatch = useDispatch()
  const avdelingerLoadingStatus = useTypedSelector(
    avdelingerSelectors.getLoadingStatus
  )
  const userRegion = useTypedSelector(userSelectors.getRegion)
  const avdelinger = useTypedSelector(avdelingerSelectors.getAvdelinger)
  const avdelingerGroupedByRegionId: { [regionId: string]: IAvdeling[] } =
    R.groupBy(R.prop('regionId'), avdelinger)
  const avdelingerInUsersRegion = avdelinger.filter(
    (avdeling) => avdeling.regionId === userRegion.id
  )

  const fetchAvdelinger = useCallback(() => {
    dispatch(avdelingerActions.fetchAvdelinger())
  }, [dispatch])

  useEffect(() => {
    fetchAvdelinger()
  }, [fetchAvdelinger])

  return {
    avdelinger,
    avdelingerLoadingStatus,
    fetchAvdelinger,
    avdelingerGroupedByRegionId,
    avdelingerInUsersRegion,
  }
}
