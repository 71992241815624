import React, { useCallback, useContext, useEffect, useState } from 'react'
import {
  Column,
  ErrorBox,
  LoadingSpinner,
  ShadowBox,
  Table,
  Text,
  Row,
  Button,
  ThemeContext,
} from '@mattilsynet/mt-ui'
import { useDispatch } from 'react-redux'
import * as R from 'ramda'
import styled from 'styled-components'
import { useTypedSelector } from '../../common/custom-hooks'
import {
  meldingsHistorikkActions,
  meldingsHistorikkSelectors,
} from '../../ducks/meldingshistorikk'
import { kodeverk } from '@mattilsynet/mt-common'
import { IMelding, IMeldingState } from '../../ducks/melding/types'
import { getSortableTableHeader, sortByPath } from '../../common/sorting'
import { AccessType } from '../../ducks/meldingshistorikk/types'
import { MeldingRow } from './melding-row'
import { IThemeColors } from '@mattilsynet/mt-ui/dist/assets/colors'
import { meldingActions } from '../../ducks/melding/actions'
import { Filtrering } from '../../components/filtrering'
import { uiSelectors } from '../../ducks/ui/selectors'
import { IMeldingFilterParams } from '../../common/melding-helpers'

export const StyledMeldingsHistorikkTable = styled(Table)<{
  theme: IThemeColors
}>`
  table > tbody > .data-row.show-more-row {
    padding: 0;
  }
  table > tbody > .data-row.show-more-row .show-more-content {
    padding: 0;
  }
  .bg-white {
    background-color: ${({ theme }) => theme.white};
  }
  .bg-gray {
    background-color: ${({ theme }) => theme.gray7};
  }
  .show-more-content {
    border-top: 1px solid ${({ theme }) => theme.gray4};
  }
  .show-more-content .melding-box {
    max-width: 400px;
  }
  .show-more-content .funn-box {
    max-width: 500px;
  }
  .show-more-content .oppfoelging-box {
    min-width: 300px;
  }
`

export const MeldingsHistorikkForRolleView = ({
  allowedStatuses,
  allowedStatusBegrunnelser,
  fetchData,
}: {
  allowedStatuses: IMeldingState['allowedStatuses']
  allowedStatusBegrunnelser: IMeldingState['allowedStatusBegrunnelser']
  fetchData: (
    fetchMore?: boolean,
    fetchSilentUntilPage?: number,
    filterParams?: IMeldingFilterParams
  ) => void
}) => {
  const dispatch = useDispatch()
  const theme = useContext(ThemeContext)

  const { loading, error, loaded, funnLoaded, tilsynUtfoertLoaded, filtering } =
    useTypedSelector(meldingsHistorikkSelectors.getLoadingStatus)
  const meldingsHistorikk = useTypedSelector(
    meldingsHistorikkSelectors.getMeldingsHistorikkForRolle
  )
  const sortedList = useTypedSelector(meldingsHistorikkSelectors.getSortedList)
  const pageMetaData = useTypedSelector(
    meldingsHistorikkSelectors.getMeldingsHistorikkForRollePageMetaData
  )
  const accessType = useTypedSelector(meldingsHistorikkSelectors.getAccessType)
  const openRows = useTypedSelector(meldingsHistorikkSelectors.getOpenRows)
  const filterParams = useTypedSelector(uiSelectors.getFilterParams)

  const { data: aktivitetKodeverk } = kodeverk.hooks.useKodeverk('AKTIVITET', {
    rootCode: 'DYR_MAT',
  })

  const [sortColumn, setSortColumn] = useState('sistRedigertDayjs')
  const [sortArrow, setSortArrow] = useState<'DOWN' | 'UP'>('DOWN')

  const fetchAllowedStatuses = useCallback(() => {
    dispatch(meldingActions.fetchAllowedStatuses())
  }, [dispatch])

  const fetchAllowedStatusBegrunnelser = useCallback(() => {
    dispatch(meldingActions.fetchAllowedBegrunnelser())
  }, [dispatch])

  useEffect(() => {
    fetchAllowedStatuses()
    fetchAllowedStatusBegrunnelser()
  }, [fetchAllowedStatuses, fetchAllowedStatusBegrunnelser])

  useEffect(() => {
    let historikk
    if (aktivitetKodeverk?.length && meldingsHistorikk?.length) {
      const updatedListSorted = R.pipe(
        R.map((melding) => {
          return {
            ...melding,
            aktivitet: melding?.tilsynsobjekt?.aktivitet?.beskrivelse,
          }
        }),
        sortByPath(sortColumn.split('.'), sortArrow !== 'UP')
      )(meldingsHistorikk)
      historikk = updatedListSorted
    } else {
      historikk = meldingsHistorikk
    }
    dispatch(meldingsHistorikkActions.setSortedList(historikk))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    meldingsHistorikk,
    aktivitetKodeverk?.length,
    sortColumn,
    sortArrow,
    funnLoaded,
    tilsynUtfoertLoaded,
  ])

  const toggleOpenRow = (meldingId) => {
    const updatedOpenRows = openRows.includes(meldingId)
      ? openRows.filter((id) => id !== meldingId)
      : [...openRows, meldingId]
    dispatch(meldingsHistorikkActions.setOpenRows(updatedOpenRows))
  }

  if (error) {
    return (
      <ErrorBox
        errorText="Kunne ikke laste inn meldingshistorikk."
        errorAction={() => fetchData()}
        errorActionText="Prøv å laste innhold på nytt"
      />
    )
  }
  if ((loading && !loaded) || !meldingsHistorikk || !sortedList) {
    return <LoadingSpinner title="Laster meldingshistorikk" />
  }

  const tableHeader = getSortableTableHeader(
    [
      { display: 'Mottatt' },
      {
        display: 'Virksomhet',
      },
      { display: 'Produsentnr.' },
      { display: 'Aktivitet' },
      { display: 'Kategori' },
      { display: 'Oppfølging' },
      { display: 'Ansvarlig' },
      { display: ' ' },
    ],
    sortColumn,
    sortArrow,
    setSortColumn,
    setSortArrow
  )

  const renderContent = () => {
    if (filtering) {
      return <LoadingSpinner title="Filtrerer meldinger" />
    }
    if (loaded && meldingsHistorikk?.length === 0) {
      return (
        <Column padding={3} align="center">
          <Text>
            {accessType === AccessType.ALLE
              ? 'Ingen meldinger'
              : accessType === AccessType.REGION
              ? 'Regionen har ingen meldinger'
              : 'Avdelingen har ingen meldinger'}
          </Text>
        </Column>
      )
    }

    return (
      <>
        <StyledMeldingsHistorikkTable header={tableHeader} theme={theme}>
          {sortedList?.map((melding: IMelding, index: number) => (
            <MeldingRow
              key={melding.meldingId}
              index={index}
              melding={melding}
              openRows={openRows}
              toggleOpenRow={toggleOpenRow}
              allowedStatuses={allowedStatuses}
              allowedStatusBegrunnelser={allowedStatusBegrunnelser}
              tableHeader={tableHeader}
              accessType={accessType}
            />
          ))}
        </StyledMeldingsHistorikkTable>

        {pageMetaData.number !== undefined &&
          pageMetaData.totalPages !== undefined &&
          pageMetaData?.number < pageMetaData?.totalPages - 1 && (
            <Row justify="center" padding={1}>
              <Button
                onClick={() => fetchData(true, undefined, filterParams)}
                loading={loading}
              >
                Last inn neste 50 meldinger
              </Button>
            </Row>
          )}
      </>
    )
  }

  return (
    <>
      <Filtrering fetchData={fetchData} pageMetaData={pageMetaData} />
      <ShadowBox>{renderContent()}</ShadowBox>
    </>
  )
}
