import React from 'react'
import { SelectList } from '@mattilsynet/mt-ui'
import { ModalWrapper } from '../modal-wrapper'
import { MeldingStatus } from '../../ducks/melding/helpers'
import { IStatus } from '../../ducks/melding/types'
import { useTypedSelector } from '../../common/custom-hooks'
import { meldingSelectors } from '../../ducks/melding/selectors'

interface IVurderingStatusModalProps {
  isOpen: boolean
  onCancel: () => void
  status: IStatus | undefined
  setStatus: (statusKey: IStatus) => void
}

export const VurderingVidereOppfoelgingModal = ({
  isOpen,
  onCancel,
  status,
  setStatus,
}: IVurderingStatusModalProps) => {
  const allowedStatuses = useTypedSelector(meldingSelectors.getAllowedStatuses)
  const allowedStatusesFiltered = Object.keys(allowedStatuses || {}).filter(
    (statusKey) =>
      statusKey !== MeldingStatus.NY && statusKey !== MeldingStatus.UTKAST
  )

  const dataList = allowedStatusesFiltered.map((statusKey) => ({
    value: statusKey,
    label: allowedStatuses?.[statusKey],
  }))

  return (
    <ModalWrapper
      onCancel={onCancel}
      isOpen={isOpen}
      title="Videre oppfølging"
      onOk={onCancel}
      paddingHorizontal={0}
    >
      <SelectList
        dataList={dataList}
        onClick={setStatus}
        selected={[String(status)]}
      />
    </ModalWrapper>
  )
}
