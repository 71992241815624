import { useCallback, useEffect, useState } from 'react'
import { useTypedSelector } from '../../common/custom-hooks'
import { useDispatch } from 'react-redux'
import { tilsynsobjektSelectors } from './selectors'
import { tilsynsobjektActions } from './store'
import * as R from 'ramda'

export const useTilsynsobjektHooks = (id: string | undefined) => {
  const tilsynsobjekt = useTypedSelector(
    tilsynsobjektSelectors.getData(id),
    R.equals
  )
  const tilsynsobjektLoadingStatus = useTypedSelector(
    tilsynsobjektSelectors.getLoadingStatus(id),
    R.equals
  )

  const dispatch = useDispatch()
  const fetchTilsynsobjekt = useCallback(() => {
    if (id) {
      dispatch(tilsynsobjektActions.fetchId(id!))
    }
  }, [dispatch, id])

  const [hasRetriedOnError, setHasRetriedOnError] = useState(false)

  const { loading, error } = tilsynsobjektLoadingStatus || {}
  useEffect(() => {
    if (id && R.isNil(tilsynsobjekt) && !loading && !error) {
      fetchTilsynsobjekt()
    }
    if (!hasRetriedOnError && !!error && !loading) {
      setHasRetriedOnError(true)
      fetchTilsynsobjekt()
    }
  }, [id, loading, error, tilsynsobjekt, fetchTilsynsobjekt, hasRetriedOnError])

  return { tilsynsobjekt, tilsynsobjektLoadingStatus, fetchTilsynsobjekt }
}
