import { ICommonApi } from '../../../api'
import { combineEpics, Epic } from 'redux-observable'
import { IStoreState } from '../../../reducers/types'
import {
  catchError,
  filter,
  map,
  mergeMap,
  switchMap,
  withLatestFrom,
} from 'rxjs/operators'
import { userSelectors } from '../../user/selectors'
import { concat, of } from 'rxjs'
import { API_ROUTES } from '../../../epics'
import { createQueryStringFromObject } from '@mattilsynet/mt-common/lib/utils/createQueryStringFromObject'
import { isOfType } from '@mattilsynet/mt-common/lib/common-redux/helpers'
import { ansatteActions, ansatteActionTypes } from '../index'
import { IAnsatteActions } from '../types'
import { AccessType } from '../../meldingshistorikk/types'
import { sortByKey } from '../../../common/sorting'
import { dangerToast, successToast } from '../../common/toast-actions'

export const updateFordeltTilEpic =
  (commonApi: ICommonApi): Epic<any, any, IStoreState> =>
  (action$, state$) =>
    action$.pipe(
      filter(isOfType(ansatteActionTypes.UPDATE_FORDELT_TIL)),
      withLatestFrom(state$),
      switchMap(([{ meldingId, vurdering, ansattNavn }, state]) => {
        return of(state).pipe(
          commonApi.put(
            `${API_ROUTES.MELDING_TIL_LOKALT_MATTILSYN}/v1/vurderinger/${meldingId}`,
            state,
            vurdering
          ),
          mergeMap(() => {
            return concat(
              of(ansatteActions.updateFordeltTilOk(meldingId, vurdering)),
              of(
                successToast(
                  `Meldingen er fordelt til ${
                    ansattNavn || vurdering.fordeltTil
                  }.`
                )
              )
            )
          }),
          catchError((err) => {
            let errorMessage = 'Kunne ikke fordele melding'
            const details = err?.response?.details
            if (err.status === 400 && details) {
              if (details === 'paaminnelseDato kan ikke være i fortiden') {
                errorMessage =
                  'Kan ikke fordele melding som har påminnelsesdato i fortiden. Endre eller fjern påminnelsesdatoen og prøv på nytt.'
              } else {
                errorMessage = details
              }
            }

            return concat(
              of(ansatteActions.updateFordeltFail(errorMessage)),
              of(dangerToast(errorMessage))
            )
          })
        )
      })
    )

export const fetchAnsatteEpic =
  (
    commonApi: ICommonApi
  ): Epic<IAnsatteActions, IAnsatteActions, IStoreState> =>
  (action$, state$) =>
    action$.pipe(
      filter(isOfType(ansatteActionTypes.FETCH_ANSATTE)),
      withLatestFrom(state$),
      switchMap(([{ accessType, ansatte, pageMetaData }, state]) => {
        const region = userSelectors.getRegion(state)
        const avdeling = userSelectors.getAvdeling(state)

        let kontor = avdeling
        if (
          (accessType === AccessType.REGION ||
            accessType === AccessType.ALLE) &&
          region.id
        ) {
          kontor = region
        }

        const pageNumber =
          pageMetaData?.number != null ? pageMetaData?.number + 1 : 0
        const pageSize = pageMetaData?.size != null ? pageMetaData?.size : 1000

        return of(state).pipe(
          commonApi.get(
            `${API_ROUTES.ORGENHET}/kontorer/${
              kontor.id
            }/ansatte${createQueryStringFromObject('?')({
              'page.size': pageSize,
              'page.number': pageNumber,
            })}`,
            state
          ),
          map((data) => {
            const ansattList = data?._embedded?.ansattList
            const metaData = data?.page
            if (metaData.number < metaData.totalPages - 1) {
              return ansatteActions.fetchAnsatte(
                accessType,
                [...ansatte, ...ansattList],
                metaData
              )
            }

            const sortedAnsatte = sortByKey(
              'navn',
              false
            )([...ansatte, ...ansattList])
            return ansatteActions.fetchAnsatteOk(sortedAnsatte, kontor)
          }),
          catchError((err) =>
            concat(of(ansatteActions.fetchAnsatteFail(err.message)))
          )
        )
      })
    )

export default (commonApi: ICommonApi) =>
  combineEpics(fetchAnsatteEpic(commonApi), updateFordeltTilEpic(commonApi))
