import React from 'react'
import { TextLink, ShadowBox, Column, Text, Header } from '@mattilsynet/mt-ui'
import styled from 'styled-components'

const StyledText = styled(Text)`
  .TextLink {
    display: inline-flex;
  }
`

const AccessDeniedRoute = () => {
  return (
    <ShadowBox>
      <Column align="center">
        <Column spacing={3} padding={4}>
          <Column spacing={1}>
            <Text>Du har ikke tilgang til Melding til lokalt Mattilsyn.</Text>
            <StyledText as="div">
              Kontakt brukerstøtte og{' '}
              <TextLink href="/login">prøv på nytt</TextLink>.
            </StyledText>
          </Column>

          <Column spacing={1}>
            <Header as="h1" size="heading2">
              Brukerstøtte
            </Header>
            <StyledText>
              Opprett{' '}
              <TextLink href="https://makuba.mattilsynet.no/" linkOutside>
                brukerstøttesak
              </TextLink>
            </StyledText>
            <Text>
              Du kan også kontakte IKT-brukerstøtte på telefon eller e-post:
            </Text>
            <StyledText>
              Telefon: <TextLink href="tel:22 77 88 99">22 77 88 99</TextLink>
            </StyledText>
            <StyledText>
              E-post:{' '}
              <TextLink href="mailto:99@mattilsynet.no">
                99@mattilsynet.no
              </TextLink>
            </StyledText>
          </Column>
        </Column>
      </Column>
    </ShadowBox>
  )
}

export default AccessDeniedRoute
