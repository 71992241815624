import { IMelding, IMeldingState, IVurdering } from './types'

export const meldingActionTypes = {
  FETCH_MELDING: <const>'FETCH_MELDING',
  FETCH_MELDING_OK: <const>'FETCH_MELDING_OK',
  FETCH_MELDING_FAIL: <const>'FETCH_MELDING_FAIL',
  UPDATE_VURDERING_LOCAL: <const>'UPDATE_VURDERING_LOCAL',
  UPDATE_VURDERING: <const>'UPDATE_VURDERING',
  UPDATE_VURDERING_OK: <const>'UPDATE_VURDERING_OK',
  UPDATE_VURDERING_FAIL: <const>'UPDATE_VURDERING_FAIL',
  FETCH_ALLOWED_STATUSES: <const>'FETCH_ALLOWED_STATUSES',
  FETCH_ALLOWED_STATUSES_OK: <const>'FETCH_ALLOWED_STATUSES_OK',
  FETCH_ALLOWED_STATUSES_FAIL: <const>'FETCH_ALLOWED_STATUSES_FAIL',
  FETCH_ALLOWED_BEGRUNNELSER: <const>'FETCH_ALLOWED_BEGRUNNELSER',
  FETCH_ALLOWED_BEGRUNNELSER_OK: <const>'FETCH_ALLOWED_BEGRUNNELSER_OK',
  FETCH_ALLOWED_BEGRUNNELSER_FAIL: <const>'FETCH_ALLOWED_BEGRUNNELSER_FAIL',
  CLEAR_MELDING_STATE: <const>'CLEAR_MELDING_STATE',
}

export const meldingActions = {
  fetchMelding: (meldingId: string) => ({
    type: meldingActionTypes.FETCH_MELDING,
    meldingId,
  }),

  fetchMeldingOk: (melding: IMelding) => ({
    type: meldingActionTypes.FETCH_MELDING_OK,
    melding,
  }),

  fetchMeldingFail: (err: string) => ({
    type: meldingActionTypes.FETCH_MELDING_FAIL,
    err,
  }),

  updateVurderingLocal: (partialVurdering: Partial<IVurdering>) => ({
    type: meldingActionTypes.UPDATE_VURDERING_LOCAL,
    partialVurdering,
  }),

  updateVurdering: () => ({
    type: meldingActionTypes.UPDATE_VURDERING,
  }),

  updateVurderingOk: (vurdering: IVurdering) => ({
    type: meldingActionTypes.UPDATE_VURDERING_OK,
    vurdering,
  }),

  updateVurderingFail: (error: string) => ({
    type: meldingActionTypes.UPDATE_VURDERING_FAIL,
    error,
  }),

  fetchAllowedStatuses: () => ({
    type: meldingActionTypes.FETCH_ALLOWED_STATUSES,
  }),

  fetchAllowedStatusesOk: (
    allowedStatuses: IMeldingState['allowedStatuses']
  ) => ({
    type: meldingActionTypes.FETCH_ALLOWED_STATUSES_OK,
    allowedStatuses,
  }),

  fetchAllowedStatusesFail: (error: string) => ({
    type: meldingActionTypes.FETCH_ALLOWED_STATUSES_FAIL,
    error,
  }),

  fetchAllowedBegrunnelser: (status?: string) => ({
    type: meldingActionTypes.FETCH_ALLOWED_BEGRUNNELSER,
    status,
  }),

  fetchAllowedBegrunnelserOk: (
    allowedStatusBegrunnelser: IMeldingState['allowedStatusBegrunnelser']
  ) => ({
    type: meldingActionTypes.FETCH_ALLOWED_BEGRUNNELSER_OK,
    allowedStatusBegrunnelser,
  }),

  fetchAllowedBegrunnelserFail: (error: string) => ({
    type: meldingActionTypes.FETCH_ALLOWED_BEGRUNNELSER_FAIL,
    error,
  }),

  clearMeldingState: () => ({
    type: meldingActionTypes.CLEAR_MELDING_STATE,
  }),
}
