import React, { ChangeEvent, useState } from 'react'
import { Column } from '@mattilsynet/mt-ui'
import { ModalWrapper } from '../modal-wrapper'
import { SakSearch } from '../sak-search'
import SakCardInfo from '../sak-card-info'
import { useTypedSelector } from '../../common/custom-hooks'
import { sakSelectors } from '../../ducks/sak'

interface IVurderingAddSakModalProps {
  isOpen: boolean
  onCancel: () => void
  saksnummerValue: string
  onChange: (e: ChangeEvent<HTMLInputElement>) => void
  onAddSak: () => void
  onCancelSak: () => void
}

export const VurderingAddSakModal = ({
  isOpen,
  onCancel,
  saksnummerValue,
  onChange,
  onAddSak,
  onCancelSak,
}: IVurderingAddSakModalProps) => {
  const [showSak, setShowSak] = useState(false)
  const {
    loading,
    sakNumber,
    title,
    sakFail,
    sakNotFound,
    sakClosed,
    sakTilgang,
    sakHjemmel,
  } = useTypedSelector(sakSelectors.getSakState)

  const handleAddSak = () => {
    onAddSak()
    setShowSak(true)
  }
  return (
    <ModalWrapper
      onCancel={onCancel}
      isOpen={isOpen}
      title="Knytt til eksisterende sak"
      onOk={onCancel}
      buttonOkText="Lukk"
    >
      <Column spacing={2}>
        <SakSearch
          onAddSak={handleAddSak}
          onChange={onChange}
          sakNumberValue={saksnummerValue}
          errorText={''}
        />

        {showSak && (
          <SakCardInfo
            sakNumber={sakNumber}
            title={title}
            sakFail={sakFail}
            sakNotFound={sakNotFound}
            sakClosed={sakClosed}
            sakTilgang={sakTilgang}
            sakHjemmel={sakHjemmel}
            onCancelSak={() => {
              onCancelSak()
              setShowSak(false)
            }}
            isLoading={loading}
            showSak={showSak}
          />
        )}
      </Column>
    </ModalWrapper>
  )
}
