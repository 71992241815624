import history from '../history'

export const useScrollAnchor = (timeout = 1000) => {
  let observer
  let timeoutId

  if (!window?.MutationObserver) {
    return
  }

  const reset = () => {
    if (timeoutId) {
      clearTimeout(timeoutId)
      timeoutId = null
    }

    if (observer) {
      observer.disconnect()
    }
  }

  const createScrollToElement = (id: string) => {
    return () => {
      const element = document.getElementById(id)

      if (element) {
        window.scrollTo(0, element.offsetTop - 80)
        reset()
        return true
      }
      return false
    }
  }

  history.listen((location, action) => {
    if (timeoutId) {
      reset()
    }

    if (action !== 'PUSH') {
      return
    }

    const elementId = location?.hash?.slice(1)

    window.scrollTo(0, 0)

    if (!elementId) {
      return
    }

    const scrollToElement = createScrollToElement(elementId)

    setTimeout(() => {
      if (scrollToElement()) {
        return
      }

      observer = new MutationObserver(scrollToElement)

      observer.observe(document, {
        attributes: true,
        childList: true,
        subtree: true,
      })

      timeoutId = setTimeout(reset, timeout)
    })
  })
}
