import { STORE_NAME } from './store'
import { ITilsynsobjekt } from './types'
import { IStoreState } from '../../reducers/types'
import { getProdusentNummerFromProdusent } from './helpers'

export const tilsynsobjektSelectors = {
  getData:
    (id: string | undefined) =>
    (state: IStoreState): ITilsynsobjekt | undefined =>
      id ? state[STORE_NAME].data[id]?.data : undefined,

  getLoadingStatus: (id: string | undefined) => (state: IStoreState) =>
    id ? state[STORE_NAME].data[id] : undefined,

  getProdusentNr:
    (id: string) =>
    (state: IStoreState): string | undefined => {
      if (!id) return undefined
      return getProdusentNummerFromProdusent(state[STORE_NAME].data[id]?.data)
    },
}
