import React, { useCallback, useEffect } from 'react'
import {
  Column,
  ErrorBox,
  GridItem,
  LoadingSpinner,
  Grid,
  TabBar,
} from '@mattilsynet/mt-ui'
import { useDispatch } from 'react-redux'
import { Switch, Route } from 'react-router-dom'
import { useTypedSelector } from '../../common/custom-hooks'

import {
  meldingsHistorikkActions,
  meldingsHistorikkSelectors,
} from '../../ducks/meldingshistorikk'
import { meldingSelectors } from '../../ducks/melding/selectors'
import { meldingActions } from '../../ducks/melding/actions'
import { AccessType } from '../../ducks/meldingshistorikk/types'
import { userSelectors } from '../../ducks/user/selectors'
import { NoAccess } from './no-access'
import { MeldingsHistorikkForRolleView } from './historikk'
import { MineMeldinger } from '../mine-meldinger'
import { pushState } from '../../common/common-router'
import { IMeldingFilterParams } from '../../common/melding-helpers'
import { uiSelectors } from '../../ducks/ui/selectors'

enum Tabs {
  HISTORIKK = '/',
  MINE_MELDINGER = '/mine-meldinger',
}

export const MeldingsHistorikkForRolle = ({ match }: { match: any }) => {
  const dispatch = useDispatch()

  const { loading, error, loaded } = useTypedSelector(
    meldingsHistorikkSelectors.getLoadingStatus
  )
  const meldingsHistorikk = useTypedSelector(
    meldingsHistorikkSelectors.getMeldingsHistorikkForRolle
  )
  const pageMetadata = useTypedSelector(
    meldingsHistorikkSelectors.getMeldingsHistorikkForRollePageMetaData
  )
  const filterParams = useTypedSelector(uiSelectors.getFilterParams)
  const accessType = useTypedSelector(meldingsHistorikkSelectors.getAccessType)
  const avdeling = useTypedSelector(userSelectors.getAvdeling)
  const region = useTypedSelector(userSelectors.getRegion)

  const allowedStatuses = useTypedSelector(meldingSelectors.getAllowedStatuses)
  const allowedStatusBegrunnelser = useTypedSelector(
    meldingSelectors.getAllowedStatusBegrunnelser
  )

  const fetchData = useCallback(
    (
      fetchMore = false,
      fetchSilentUntilPage?: number,
      filterParams?: IMeldingFilterParams,
      filtering?: boolean
    ) => {
      dispatch(
        meldingsHistorikkActions.fetchMeldingsHistorikkForRolle(
          fetchMore,
          fetchSilentUntilPage,
          filterParams,
          filtering
        )
      )
    },
    [dispatch]
  )

  useEffect(() => {
    const silent = pageMetadata?.number != null && pageMetadata?.number > 0
    const fetchSilentUntilPage = silent ? pageMetadata?.number : undefined
    fetchData(false, fetchSilentUntilPage, filterParams)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetchData, match.path])

  const goToPath = (path: string) => dispatch(pushState(path))

  const fetchAllowedStatuses = useCallback(() => {
    dispatch(meldingActions.fetchAllowedStatuses())
  }, [dispatch])

  const fetchAllowedStatusBegrunnelser = useCallback(() => {
    dispatch(meldingActions.fetchAllowedBegrunnelser())
  }, [dispatch])

  useEffect(() => {
    fetchAllowedStatuses()
    fetchAllowedStatusBegrunnelser()
  }, [fetchAllowedStatuses, fetchAllowedStatusBegrunnelser])

  if (error) {
    return (
      <ErrorBox
        errorText="Kunne ikke laste inn meldingshistorikk."
        errorAction={() => fetchData()}
        errorActionText="Prøv å laste innhold på nytt"
      />
    )
  }
  if ((loading && !loaded) || !meldingsHistorikk) {
    return <LoadingSpinner title="Laster meldingshistorikk" />
  }
  if (accessType === AccessType.INGEN) {
    return <NoAccess />
  }

  let headerText = ''
  if (accessType === AccessType.ALLE) {
    headerText = 'Siste meldinger for hele landet'
  } else if (accessType === AccessType.REGION) {
    headerText = `${region.name}`.split('Region ').pop() || ''
  } else {
    headerText = `${avdeling.name}`.split('Avdeling ').pop() || ''
  }

  const tabOptions = [
    { display: headerText, value: Tabs.HISTORIKK },
    { display: 'Mine meldinger', value: Tabs.MINE_MELDINGER },
  ]

  return (
    <Grid>
      <GridItem xl={[1, -1]} lg={[1, -1]} md={[1, -1]} sm={[1, -1]}>
        <Column spacing={4}>
          <Column padding={[1, 0, 0, 0]}>
            <TabBar
              options={tabOptions}
              selectedValue={match.path}
              onClick={goToPath}
              forceShowAsDropdown={false}
              size="normal"
            />
          </Column>

          <Switch>
            <Route
              exact
              path={Tabs.HISTORIKK}
              render={(props) => (
                <MeldingsHistorikkForRolleView
                  {...props}
                  allowedStatuses={allowedStatuses}
                  allowedStatusBegrunnelser={allowedStatusBegrunnelser}
                  fetchData={fetchData}
                />
              )}
            />
            <Route
              exact
              path={Tabs.MINE_MELDINGER}
              render={(props) => (
                <MineMeldinger
                  {...props}
                  allowedStatuses={allowedStatuses}
                  allowedStatusBegrunnelser={allowedStatusBegrunnelser}
                />
              )}
            />
          </Switch>
        </Column>
      </GridItem>
    </Grid>
  )
}
