import React, { useState } from 'react'
import { IDisplayAndValue } from '../filter-list'
import { ModalWrapper } from '../modal-wrapper'
import { Column, Row, SelectList, TextLink } from '@mattilsynet/mt-ui'
import { IMeldingFilterParams } from '../../common/melding-helpers'

const aktivitetOptions = [
  { value: 'AKTIVITET$GEITEHOLD', display: 'Geitehold' },
  { value: 'AKTIVITET$SVINEHOLD', display: 'Svinehold' },
  { value: 'AKTIVITET$SAUEHOLD', display: 'Sauehold' },
  { value: 'AKTIVITET$STORFEHOLD', display: 'Storfehold' },
]

export const AktivitetFilterModal = ({
  isOpen,
  onCancel,
  filterParams,
  setFilterParams,
}: {
  isOpen: boolean
  onCancel: () => void
  filterParams: IMeldingFilterParams
  setFilterParams: (params: IMeldingFilterParams) => void
}) => {
  const [selectedAktiviteter, setSelectedAkiviteter] = useState<
    IDisplayAndValue[]
  >(filterParams.aktiviteter)

  const handleUpdateFilters = () => {
    setFilterParams({ ...filterParams, aktiviteter: selectedAktiviteter })
    onCancel()
  }

  const renderContent = () => {
    return (
      <Column>
        <Row justify="flex-end" padding={[1, 2]}>
          <TextLink small onClick={() => setSelectedAkiviteter([])}>
            Fjern alle valg
          </TextLink>
        </Row>
        <SelectList
          dataList={aktivitetOptions.map((a) => ({ ...a, label: a.display }))}
          onClick={(value) => {
            const aktivitet = aktivitetOptions.find((a) => a.value === value)
            const isInList = !!selectedAktiviteter.find(
              (a) => a.value === value
            )
            if (isInList) {
              setSelectedAkiviteter(
                selectedAktiviteter.filter((a) => a.value !== value)
              )
            } else if (!isInList && aktivitet) {
              setSelectedAkiviteter([...(selectedAktiviteter || []), aktivitet])
            }
          }}
          selected={selectedAktiviteter.map((aktivitet) => aktivitet.value)}
        />
      </Column>
    )
  }
  return (
    <ModalWrapper
      isOpen={isOpen}
      onCancel={onCancel}
      paddingHorizontal={0}
      title="Aktivitet"
      buttonOkText="Bruk valgte filter"
      onOk={handleUpdateFilters}
    >
      <Column padding={[3, 0]}>{renderContent()}</Column>
    </ModalWrapper>
  )
}
