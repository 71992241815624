import { Column, Tag } from '@mattilsynet/mt-ui'
import React, { SyntheticEvent } from 'react'

export const SelectedFilter = ({
  children,
  marginRight = false,
  onClickRemove,
}: {
  children: string
  marginRight?: boolean
  onClickRemove: (e: SyntheticEvent) => void
}) => {
  return (
    <Column margin={marginRight ? [1, 3, 0, 0] : [1, 1, 0, 0]}>
      <Tag onClickRemove={onClickRemove}>{children}</Tag>
    </Column>
  )
}
