import { ICommonApi } from '../../../api'
import { combineEpics, Epic } from 'redux-observable'
import { IStoreState } from '../../../reducers/types'
import {
  catchError,
  filter,
  map,
  mergeMap,
  switchMap,
  withLatestFrom,
} from 'rxjs/operators'
import { concat, EMPTY, forkJoin, of } from 'rxjs'
import dayjs from 'dayjs'
import * as R from 'ramda'
import { isOfType } from '@mattilsynet/mt-common/lib/common-redux/helpers'
import { API_ROUTES } from '../../../epics'
import { createQueryStringFromObject } from '@mattilsynet/mt-common/lib/utils/createQueryStringFromObject'
import {
  meldingsHistorikkActions,
  meldingsHistorikkActionTypes,
} from '../index'
import { AccessType, IMeldingsHistorikkActions } from '../types'
import { IMelding } from '../../melding/types'
import { sortByKey } from '../../../common/sorting'
import {
  convertMeldingFilterParams,
  getFunnRequests,
  getMeldingListByEftanummer,
  populateMeldingsHistorikkWithFunnResponse,
} from './helpers'
import { ITilsynsobjekt } from '../../tilsynsobjekt/types'
import { dangerToast } from '../../common/toast-actions'

const PAGE_SIZE = 50

const getMeldingsHistorikkForRolle =
  (
    commonApi: ICommonApi
  ): Epic<IMeldingsHistorikkActions, IMeldingsHistorikkActions, IStoreState> =>
  (action$, state$) =>
    action$.pipe(
      filter(isOfType(meldingsHistorikkActionTypes.FETCH_HISTORIKK_FOR_ROLLE)),
      withLatestFrom(state$),
      switchMap(
        ([
          {
            fetchMore,
            fetchSilentUntilPage,
            filterParams,
            pageMetaData,
            meldingsHistorikk,
          },
          state,
        ]) =>
          of(state).pipe(
            commonApi.get(`${API_ROUTES.MELDING_TIL_LOKALT_MATTILSYN}/`, state),
            mergeMap(({ _links: links }) => {
              const alleMeldinger = links?.['Oversikt alle meldinger']
              const minRegion = links?.['Oversikt meldinger min region']
              const minAvdeling = links?.['Oversikt meldinger min avdeling']
              let accessType = AccessType.INGEN
              let link
              if (alleMeldinger) {
                link = alleMeldinger
                accessType = AccessType.ALLE
              } else if (minRegion) {
                link = minRegion
                accessType = AccessType.REGION
              } else if (minAvdeling) {
                link = minAvdeling
                accessType = AccessType.AVDELING
              }

              if (!link) {
                return of(
                  meldingsHistorikkActions.fetchMeldingsHistorikkForRolleOk(
                    [],
                    accessType,
                    fetchMore,
                    {}
                  )
                )
              }

              let meldingsHistorikkStateCount = 0
              if (fetchMore) {
                meldingsHistorikkStateCount =
                  state.meldingsHistorikk?.meldingsHistorikkForRolle?.length ||
                  0
              }

              let pageNumber = meldingsHistorikkStateCount / PAGE_SIZE

              if (fetchSilentUntilPage != null) {
                pageNumber =
                  pageMetaData?.number != null ? pageMetaData?.number + 1 : 0
              }

              const meldingFilterParams =
                convertMeldingFilterParams(filterParams)
              const urlWithoutParams = link.href.split('?').shift()
              const mottakereParams = new URLSearchParams(
                link.href.split('?').pop()
              )

              const originalMottakere =
                mottakereParams.getAll('filter.mottakere')
              let queryObject = {
                'page.size': PAGE_SIZE,
                'page.number': pageNumber,
                'sort.property': 'sistRedigert',
                'sort.direction': 'DESC',
              }
              if (originalMottakere.length > 0) {
                queryObject['filter.mottakere'] = originalMottakere
              }
              queryObject = { ...queryObject, ...meldingFilterParams }
              const params = createQueryStringFromObject('?')(queryObject)

              return of(state).pipe(
                commonApi.get(`${urlWithoutParams}${params}`, state),
                mergeMap((resp) => {
                  const populertMattilsynMeldingList =
                    resp?._embedded?.populertMattilsynMeldingList || []
                  const metaData = resp?.page

                  const meldingList = [
                    ...(meldingsHistorikk || []),
                    ...populertMattilsynMeldingList,
                  ]

                  if (
                    fetchSilentUntilPage &&
                    metaData.number < fetchSilentUntilPage
                  ) {
                    return of(
                      meldingsHistorikkActions.fetchMeldingsHistorikkForRolle(
                        false,
                        fetchSilentUntilPage,
                        filterParams,
                        false,
                        metaData,
                        meldingList
                      )
                    )
                  }

                  if (meldingList.length === 0) {
                    return of(
                      meldingsHistorikkActions.fetchMeldingsHistorikkForRolleOk(
                        [],
                        accessType,
                        fetchMore,
                        metaData
                      )
                    )
                  }

                  const tilsynsobjektIds = R.pipe(
                    R.map((melding) => melding.produsentTilsynsobjektId),
                    R.uniq
                  )(meldingList)
                  const tilsynsobjektRequests = tilsynsobjektIds.map((id) =>
                    commonApi.get(
                      `${API_ROUTES.TILSYNSOBJEKT}/tilsynsobjekter/id/${id}`,
                      state
                    )(of(state))
                  )

                  return forkJoin([...tilsynsobjektRequests]).pipe(
                    mergeMap((tilsynsobjekterArray) => {
                      const tilsynsobjekter: ITilsynsobjekt[] =
                        tilsynsobjekterArray.map(
                          (tilsynsobjekt) => tilsynsobjekt as ITilsynsobjekt
                        )
                      const meldingListWithTilsynsobjekt = meldingList.map(
                        (melding: IMelding) => {
                          const tilsynsobjekt = tilsynsobjekter.find(
                            (tilsynsobjekt) =>
                              tilsynsobjekt.idstring ===
                              melding?.produsentTilsynsobjektId
                          )
                          return { ...melding, tilsynsobjekt }
                        }
                      )

                      const meldingsHistorikkList: IMelding[] =
                        meldingListWithTilsynsobjekt.map((melding) => ({
                          ...melding,
                          sistRedigertDayjs: dayjs(melding.sistRedigert),
                          funnList: [],
                        }))

                      return of(
                        meldingsHistorikkActions.fetchMeldingsHistorikkForRolleOk(
                          sortByKey(
                            'sistRedigert',
                            true
                          )(meldingsHistorikkList),
                          accessType,
                          fetchMore,
                          metaData
                        )
                      )
                    })
                  )
                })
              )
            }),
            catchError((err) =>
              of(
                meldingsHistorikkActions.fetchMeldingsHistorikkForRolleFail(
                  err.message
                )
              )
            )
          )
      )
    )

const getMeldingsHistorikkForVirksomhet =
  (
    commonApi: ICommonApi
  ): Epic<IMeldingsHistorikkActions, IMeldingsHistorikkActions, IStoreState> =>
  (action$, state$) =>
    action$.pipe(
      filter(
        isOfType(meldingsHistorikkActionTypes.FETCH_HISTORIKK_FOR_VIRKSOMHET)
      ),
      withLatestFrom(state$),
      switchMap(([{ produsentTilsynsobjektId }, state]) =>
        of(state).pipe(
          commonApi.get(
            `${
              API_ROUTES.TILSYNSOBJEKT
            }/v2/virksomheter/${createQueryStringFromObject('?')({
              'filter.tilsynsobjekter.idstring': [produsentTilsynsobjektId],
              expand: ['tilsynsobjekter'],
              includeInactive: true,
            })}`,
            state
          ),
          mergeMap((resp) => {
            const virksomhetList = resp?._embedded?.virksomhetList || []
            const virksomhet = virksomhetList[0] || {}
            const tilsynsobjekter = virksomhet.tilsynsobjekter || []

            return of(state).pipe(
              commonApi.get(
                `${
                  API_ROUTES.MELDING_TIL_LOKALT_MATTILSYN
                }/v2/mattilsynmelding/${createQueryStringFromObject('?')({
                  'filter.produsentTilsynsobjektIds': tilsynsobjekter.map(
                    (tilsynsobjekt) => tilsynsobjekt.idstring
                  ),
                  'page.size': 100,
                })}`,
                state
              ),
              mergeMap((resp) => {
                const populertMattilsynMeldingList =
                  resp?._embedded?.populertMattilsynMeldingList || []

                const meldingsHistorikk = populertMattilsynMeldingList.map(
                  (melding) => ({
                    ...melding,
                    sistRedigert: dayjs(melding.sistRedigert),
                    funnList: [],
                  })
                )
                return of(
                  meldingsHistorikkActions.fetchMeldingsHistorikkForVirksomhetOk(
                    sortByKey('sistRedigert', true)(meldingsHistorikk),
                    virksomhet
                  )
                )
              }),
              catchError((err) =>
                of(
                  meldingsHistorikkActions.fetchMeldingsHistorikkForVirksomhetFail(
                    err.message
                  )
                )
              )
            )
          })
        )
      )
    )

export const fetchMineMeldingerEpic =
  (
    commonApi: ICommonApi
  ): Epic<IMeldingsHistorikkActions, IMeldingsHistorikkActions, IStoreState> =>
  (action$, state$) =>
    action$.pipe(
      filter(isOfType(meldingsHistorikkActionTypes.FETCH_MINE_MELDINGER)),
      withLatestFrom(state$),
      switchMap(
        ([
          { fetchMore, fetchSilentUntilPage, pageMetaData, meldingsHistorikk },
          state,
        ]) => {
          let meldingsHistorikkStateCount = 0
          if (fetchMore) {
            meldingsHistorikkStateCount =
              state.meldingsHistorikk?.mineMeldinger?.length || 0
          }
          let pageNumber = meldingsHistorikkStateCount / PAGE_SIZE
          if (fetchSilentUntilPage != null) {
            pageNumber =
              pageMetaData?.number != null ? pageMetaData?.number + 1 : 0
          }
          const params = createQueryStringFromObject('?')({
            'page.size': PAGE_SIZE,
            'page.number': pageNumber,
            'sort.property': 'sistRedigert',
            'sort.direction': 'DESC',
          })

          return of(state).pipe(
            commonApi.get(
              `${API_ROUTES.MELDING_TIL_LOKALT_MATTILSYN}/v2/mattilsynmelding/mine${params}`,
              state
            ),
            mergeMap((data) => {
              const populertMattilsynMeldingList =
                data?._embedded?.populertMattilsynMeldingList || []
              const metaData = data?.page

              const meldingList = [
                ...(meldingsHistorikk || []),
                ...populertMattilsynMeldingList,
              ]

              if (
                fetchSilentUntilPage &&
                metaData.number < fetchSilentUntilPage
              ) {
                return of(
                  meldingsHistorikkActions.fetchMineMeldinger(
                    false,
                    fetchSilentUntilPage,
                    metaData,
                    meldingList
                  )
                )
              }

              if (meldingList.length === 0) {
                return of(
                  meldingsHistorikkActions.fetchMineMeldingerOk(
                    [],
                    fetchMore,
                    metaData
                  )
                )
              }

              const tilsynsobjektIds = R.pipe(
                R.map((melding) => melding.produsentTilsynsobjektId),
                R.uniq
              )(meldingList)
              const tilsynsobjektRequests = tilsynsobjektIds.map((id) =>
                commonApi.get(
                  `${API_ROUTES.TILSYNSOBJEKT}/tilsynsobjekter/id/${id}`,
                  state
                )(of(state))
              )

              return forkJoin([...tilsynsobjektRequests]).pipe(
                mergeMap((tilsynsobjekterArray) => {
                  const tilsynsobjekter: ITilsynsobjekt[] =
                    tilsynsobjekterArray.map(
                      (tilsynsobjekt) => tilsynsobjekt as ITilsynsobjekt
                    )
                  const meldingListWithTilsynsobjekt = meldingList.map(
                    (melding: IMelding) => {
                      const tilsynsobjekt = tilsynsobjekter.find(
                        (tilsynsobjekt) =>
                          tilsynsobjekt.idstring ===
                          melding?.produsentTilsynsobjektId
                      )
                      return { ...melding, tilsynsobjekt }
                    }
                  )

                  const meldingsHistorikk = meldingListWithTilsynsobjekt.map(
                    (melding) => ({
                      ...melding,
                      sistRedigertDayjs: dayjs(melding.sistRedigert),
                      funnList: [],
                    })
                  )
                  return of(
                    meldingsHistorikkActions.fetchMineMeldingerOk(
                      sortByKey('sistRedigert', true)(meldingsHistorikk),
                      fetchMore,
                      metaData
                    )
                  )
                })
              )
            })
          )
        }
      ),
      catchError((err) =>
        of(meldingsHistorikkActions.fetchMineMeldingerFail(err.message))
      )
    )

const getMeldingsHistorikkFunn =
  (commonApi: ICommonApi): Epic<any, any, IStoreState> =>
  (action$, state$) =>
    action$.pipe(
      filter(
        isOfType([
          meldingsHistorikkActionTypes.FETCH_HISTORIKK_FOR_VIRKSOMHET_OK,
          meldingsHistorikkActionTypes.FETCH_HISTORIKK_FOR_ROLLE_OK,
          meldingsHistorikkActionTypes.FETCH_MINE_MELDINGER_OK,
        ])
      ),
      withLatestFrom(state$),
      switchMap(
        ([{ meldingsHistorikk: meldingsHistorikkUtenFunn, type }, state]) => {
          const meldingListByEftanummer = getMeldingListByEftanummer(
            meldingsHistorikkUtenFunn
          )

          if (Object.values(meldingListByEftanummer).length === 0) {
            return EMPTY
          }
          const requests = getFunnRequests(
            meldingListByEftanummer,
            commonApi,
            state
          )

          return forkJoin([...requests]).pipe(
            map((res) => {
              const meldingsHistorikk =
                populateMeldingsHistorikkWithFunnResponse(
                  res,
                  meldingsHistorikkUtenFunn
                )
              return meldingsHistorikkActions.fetchFunnOk(
                sortByKey('sistRedigert', true)(meldingsHistorikk),
                type
              )
            }),
            catchError((error) => {
              return concat(
                of(meldingsHistorikkActions.fetchFunnFail(error)),
                of(dangerToast('Kunne ikke hente funn')),
                of(
                  meldingsHistorikkActions.fetchTilsynUtfoert(
                    sortByKey('sistRedigert', true)(meldingsHistorikkUtenFunn),
                    type
                  )
                )
              )
            })
          )
        }
      )
    )

const getMeldingsHistorikkTilsynUtfoert =
  (
    commonApi: ICommonApi
  ): Epic<IMeldingsHistorikkActions, IMeldingsHistorikkActions, IStoreState> =>
  (action$, state$) =>
    action$.pipe(
      filter(
        isOfType([
          meldingsHistorikkActionTypes.FETCH_FUNN_OK,
          meldingsHistorikkActionTypes.FETCH_TILSYN_UTFOERT,
        ])
      ),
      withLatestFrom(state$),
      switchMap(
        ([
          { meldingsHistorikk: meldingsHistorikkUtenTilsynUtfoert, sourceType },
          state,
        ]) => {
          const vurderingSaksNummerList = R.pipe(
            R.map((melding) => melding?.vurdering?.tilknyttetSaksnummer),
            R.filter((saksnummer) => !!saksnummer),
            R.uniq
          )(meldingsHistorikkUtenTilsynUtfoert)

          if (
            meldingsHistorikkUtenTilsynUtfoert?.length === 0 ||
            !vurderingSaksNummerList.length
          ) {
            return EMPTY
          }

          return of(state).pipe(
            commonApi.get(
              `${
                API_ROUTES.TILSYNSKVITTERING
              }/v1/tilsynskvitteringstatuser${createQueryStringFromObject('?')({
                'filter.has.noarksak': vurderingSaksNummerList,
              })}`,
              state
            ),
            map((resp) => {
              const tilsynskvitteringStatuser =
                resp?._embedded?.tilsynskvitteringStatuser || []
              const meldingsHistorikk = meldingsHistorikkUtenTilsynUtfoert?.map(
                (melding) => {
                  const tilknyttetSaksnummer =
                    melding?.vurdering?.tilknyttetSaksnummer
                  const tilsynskvitteringStatus =
                    tilsynskvitteringStatuser.find(
                      (status) => status.noarksak === tilknyttetSaksnummer
                    )
                  return {
                    ...melding,
                    tilsynskvitteringStatus,
                  }
                }
              )
              return meldingsHistorikkActions.fetchTilsynUtfoertOk(
                sortByKey('sistRedigert', true)(meldingsHistorikk),
                sourceType
              )
            }),
            catchError((err) => {
              return concat(
                of(meldingsHistorikkActions.fetchTilsynUtfoertFail(err))
              )
            })
          )
        }
      )
    )

export default (commonApi: ICommonApi) =>
  combineEpics(
    getMeldingsHistorikkForVirksomhet(commonApi),
    getMeldingsHistorikkForRolle(commonApi),
    getMeldingsHistorikkFunn(commonApi),
    getMeldingsHistorikkTilsynUtfoert(commonApi),
    fetchMineMeldingerEpic(commonApi)
  )
