import { IImage, IImagesActions, IImageSizes, IImagesState } from './types'
import { commonRedux } from '@mattilsynet/mt-common'
import { addOrUpdateImage } from './helpers'
import { IStoreState } from '../../../reducers/types'

export const STORE_NAME = 'images'

export const imagesActionTypes = {
  FETCH_IMAGE_THUMBNAIL: <const>`${STORE_NAME}/FETCH_IMAGE_THUMBNAIL`,
  FETCH_IMAGE_THUMBNAIL_OK: <const>`${STORE_NAME}/FETCH_IMAGE_THUMBNAIL_OK`,
  FETCH_IMAGE_THUMBNAIL_FAIL: <const>`${STORE_NAME}/FETCH_IMAGE_THUMBNAIL_FAIL`,
}

export const imagesActions = {
  fetchImage: (imageId: string, size: IImageSizes = 'tiny') => ({
    type: imagesActionTypes.FETCH_IMAGE_THUMBNAIL,
    imageId,
    size,
  }),
  fetchImageOk: (image: IImage) => ({
    type: imagesActionTypes.FETCH_IMAGE_THUMBNAIL_OK,
    image,
  }),
  fetchImageFail: (err: string) => ({
    type: imagesActionTypes.FETCH_IMAGE_THUMBNAIL_FAIL,
    err,
  }),
}

export const initialState: IImagesState = {
  imagesList: [],
  loaded: false,
  loading: false,
  error: '',
}

const customReducer = commonRedux.createReducer<IImagesState, IImagesActions>(
  initialState,
  {
    [imagesActionTypes.FETCH_IMAGE_THUMBNAIL_OK]: (
      state,
      { image }: { image: IImage }
    ): IImagesState => ({
      ...state,
      imagesList: addOrUpdateImage(state.imagesList, image),
    }),
  }
)

export const imagesReducer = {
  [STORE_NAME]: customReducer,
}

export const imagesSelectors = {
  getImage:
    (id: string | undefined) =>
    (state: IStoreState): IImage | undefined =>
      id ? state.images.imagesList.find((image) => image.id === id) : undefined,

  getLoadingStatus:
    () =>
    (state: IStoreState): { loading: boolean; error: string } => ({
      loading: state.images.loading,
      error: state.images.error,
    }),
}
