import { IAnsatt, IAnsatteState, IPageMetaData } from './types'
import { IStoreState } from '../../reducers/types'
import { IRemoteVurdering } from '../melding/types'
import { AccessType } from '../meldingshistorikk/types'

export const STORE_NAME = 'ansatte'

export const ansatteActionTypes = {
  FETCH_ANSATTE: <const>`${STORE_NAME}/FETCH_ANSATTE`,
  FETCH_ANSATTE_OK: <const>`${STORE_NAME}/FETCH_ANSATTE_OK`,
  FETCH_ANSATTE_FAIL: <const>`${STORE_NAME}/FETCH_ANSATTE_FAIL`,

  UPDATE_FORDELT_TIL: <const>`${STORE_NAME}/UPDATE_FORDELT_TIL`,
  UPDATE_FORDELT_TIL_OK: <const>`${STORE_NAME}/UPDATE_FORDELT_TIL_OK`,
  UPDATE_FORDELT_TIL_FAIL: <const>`${STORE_NAME}/UPDATE_FORDELT_TIL_FAIL`,
}

export const ansatteActions = {
  fetchAnsatte: (
    accessType: keyof typeof AccessType = AccessType.AVDELING,
    ansatte: IAnsatt[] = [],
    pageMetaData?: IPageMetaData
  ) => ({
    type: ansatteActionTypes.FETCH_ANSATTE,
    accessType,
    ansatte,
    pageMetaData,
  }),

  fetchAnsatteOk: (ansatte: IAnsatt[], kontor: IAnsatteState['kontor']) => ({
    type: ansatteActionTypes.FETCH_ANSATTE_OK,
    ansatte,
    kontor,
  }),

  fetchAnsatteFail: (error: string) => ({
    type: ansatteActionTypes.FETCH_ANSATTE_FAIL,
    error,
  }),

  updateFordeltTil: (
    meldingId: string,
    vurdering: IRemoteVurdering,
    ansattNavn?: string
  ) => ({
    type: ansatteActionTypes.UPDATE_FORDELT_TIL,
    vurdering,
    meldingId,
    ansattNavn,
  }),
  updateFordeltTilOk: (meldingId: string, vurdering: IRemoteVurdering) => ({
    type: ansatteActionTypes.UPDATE_FORDELT_TIL_OK,
    meldingId,
    vurdering,
  }),
  updateFordeltFail: (error: string) => ({
    type: ansatteActionTypes.UPDATE_FORDELT_TIL_FAIL,
    error,
  }),
}

export const ansatteSelectors = {
  getLoadingStatus: (
    state: IStoreState
  ): { loading: boolean; loaded: boolean; error: string | null } => ({
    loading: state[STORE_NAME].loading,
    loaded: state[STORE_NAME].loaded,
    error: state[STORE_NAME].error,
  }),

  getAnsatte: (state: IStoreState): IAnsatteState['ansatte'] =>
    state[STORE_NAME].ansatte,

  getKontor: (state: IStoreState): IAnsatteState['kontor'] =>
    state[STORE_NAME].kontor,
}
