import { IStoreState } from '../../reducers/types'
import { IMeldingsHistorikkState } from './types'
import { IMelding } from '../melding/types'
import { IMeldingFilterParams } from '../../common/melding-helpers'

export const STORE_NAME = 'meldingsHistorikk'

export const meldingsHistorikkActionTypes = {
  FETCH_HISTORIKK_FOR_VIRKSOMHET: <const>(
    `${STORE_NAME}/FETCH_HISTORIKK_FOR_VIRKSOMHET`
  ),
  FETCH_HISTORIKK_FOR_VIRKSOMHET_OK: <const>(
    `${STORE_NAME}/FETCH_HISTORIKK_FOR_VIRKSOMHET_OK`
  ),
  FETCH_HISTORIKK_FOR_VIRKSOMHET_FAIL: <const>(
    `${STORE_NAME}/FETCH_HISTORIKK_FOR_VIRKSOMHET_FAIL`
  ),
  FETCH_HISTORIKK_FOR_ROLLE: <const>`${STORE_NAME}/FETCH_HISTORIKK_FOR_ROLLE`,
  FETCH_HISTORIKK_FOR_ROLLE_OK: <const>(
    `${STORE_NAME}/FETCH_HISTORIKK_FOR_ROLLE_OK`
  ),
  FETCH_HISTORIKK_FOR_ROLLE_FAIL: <const>(
    `${STORE_NAME}/FETCH_HISTORIKK_FOR_ROLLE_FAIL`
  ),
  SET_SORTED_LIST: <const>`${STORE_NAME}/SET_SORTED_LIST`,

  SET_OPEN_ROWS: <const>'SET_OPEN_ROWS',

  FETCH_FUNN_OK: <const>`${STORE_NAME}/FETCH_FUNN_OK`,
  FETCH_FUNN_FAIL: <const>`${STORE_NAME}/FETCH_FUNN_FAIL`,

  FETCH_TILSYN_UTFOERT: <const>`${STORE_NAME}/FETCH_TILSYN_UTFOERT`,
  FETCH_TILSYN_UTFOERT_OK: <const>`${STORE_NAME}/FETCH_TILSYN_UTFOERT_OK`,
  FETCH_TILSYN_UTFOERT_FAIL: <const>`${STORE_NAME}/FETCH_TILSYN_UTFOERT_FAIL`,

  FETCH_MINE_MELDINGER: <const>`${STORE_NAME}/FETCH_MINE_MELDINGER`,
  FETCH_MINE_MELDINGER_OK: <const>`${STORE_NAME}/FETCH_MINE_MELDINGER_OK`,
  FETCH_MINE_MELDINGER_FAIL: <const>`${STORE_NAME}/FETCH_MINE_MELDINGER_FAIL`,
}

export const meldingsHistorikkActions = {
  fetchMeldingsHistorikkForVirksomhet: (produsentTilsynsobjektId: string) => ({
    type: meldingsHistorikkActionTypes.FETCH_HISTORIKK_FOR_VIRKSOMHET,
    produsentTilsynsobjektId,
  }),

  fetchMeldingsHistorikkForVirksomhetOk: (
    meldingsHistorikk: IMeldingsHistorikkState['meldingsHistorikkForVirksomhet'],
    virksomhet: IMeldingsHistorikkState['virksomhet']
  ) => ({
    type: meldingsHistorikkActionTypes.FETCH_HISTORIKK_FOR_VIRKSOMHET_OK,
    meldingsHistorikk,
    virksomhet,
  }),

  fetchMeldingsHistorikkForVirksomhetFail: (err: string) => ({
    type: meldingsHistorikkActionTypes.FETCH_HISTORIKK_FOR_VIRKSOMHET_FAIL,
    err,
  }),

  fetchMeldingsHistorikkForRolle: (
    fetchMore = false,
    fetchSilentUntilPage?: number,
    filterParams?: IMeldingFilterParams,
    filtering = false,
    pageMetaData?: IMeldingsHistorikkState['meldingsHistorikkForRollePageMetaData'],
    meldingsHistorikk?: IMeldingsHistorikkState['meldingsHistorikkForRolle']
  ) => ({
    type: meldingsHistorikkActionTypes.FETCH_HISTORIKK_FOR_ROLLE,
    fetchMore,
    fetchSilentUntilPage,
    filterParams,
    pageMetaData,
    meldingsHistorikk,
    filtering,
  }),

  fetchMeldingsHistorikkForRolleOk: (
    meldingsHistorikk: IMeldingsHistorikkState['meldingsHistorikkForRolle'],
    accessType: IMeldingsHistorikkState['accessType'],
    fetchMore = false,
    pageMetaData: IMeldingsHistorikkState['meldingsHistorikkForRollePageMetaData']
  ) => ({
    type: meldingsHistorikkActionTypes.FETCH_HISTORIKK_FOR_ROLLE_OK,
    meldingsHistorikk,
    accessType,
    fetchMore,
    pageMetaData,
  }),

  fetchMeldingsHistorikkForRolleFail: (err: string) => ({
    type: meldingsHistorikkActionTypes.FETCH_HISTORIKK_FOR_ROLLE_FAIL,
    err,
  }),

  setSortedList: (sortedList: IMelding[]) => ({
    type: meldingsHistorikkActionTypes.SET_SORTED_LIST,
    sortedList,
  }),

  fetchFunnOk: (
    meldingsHistorikk:
      | IMeldingsHistorikkState['meldingsHistorikkForRolle']
      | IMeldingsHistorikkState['meldingsHistorikkForVirksomhet']
      | IMeldingsHistorikkState['mineMeldinger'],
    sourceType:
      | typeof meldingsHistorikkActionTypes.FETCH_HISTORIKK_FOR_VIRKSOMHET_OK
      | typeof meldingsHistorikkActionTypes.FETCH_HISTORIKK_FOR_ROLLE_OK
      | typeof meldingsHistorikkActionTypes.FETCH_MINE_MELDINGER_OK
  ) => ({
    type: meldingsHistorikkActionTypes.FETCH_FUNN_OK,
    meldingsHistorikk,
    sourceType,
  }),

  fetchFunnFail: (err: string) => ({
    type: meldingsHistorikkActionTypes.FETCH_FUNN_FAIL,
    err,
  }),

  fetchTilsynUtfoert: (
    meldingsHistorikk:
      | IMeldingsHistorikkState['meldingsHistorikkForRolle']
      | IMeldingsHistorikkState['meldingsHistorikkForVirksomhet']
      | IMeldingsHistorikkState['mineMeldinger'],
    sourceType:
      | typeof meldingsHistorikkActionTypes.FETCH_HISTORIKK_FOR_VIRKSOMHET_OK
      | typeof meldingsHistorikkActionTypes.FETCH_HISTORIKK_FOR_ROLLE_OK
      | typeof meldingsHistorikkActionTypes.FETCH_MINE_MELDINGER_OK
  ) => ({
    type: meldingsHistorikkActionTypes.FETCH_TILSYN_UTFOERT,
    meldingsHistorikk,
    sourceType,
  }),
  fetchTilsynUtfoertOk: (
    meldingsHistorikk:
      | IMeldingsHistorikkState['meldingsHistorikkForRolle']
      | IMeldingsHistorikkState['meldingsHistorikkForVirksomhet']
      | IMeldingsHistorikkState['mineMeldinger'],
    sourceType:
      | typeof meldingsHistorikkActionTypes.FETCH_HISTORIKK_FOR_VIRKSOMHET_OK
      | typeof meldingsHistorikkActionTypes.FETCH_HISTORIKK_FOR_ROLLE_OK
      | typeof meldingsHistorikkActionTypes.FETCH_MINE_MELDINGER_OK
  ) => ({
    type: meldingsHistorikkActionTypes.FETCH_TILSYN_UTFOERT_OK,
    meldingsHistorikk,
    sourceType,
  }),
  fetchTilsynUtfoertFail: (err: string) => ({
    type: meldingsHistorikkActionTypes.FETCH_TILSYN_UTFOERT_FAIL,
    err,
  }),

  setOpenRows: (openRows: string[]) => ({
    type: meldingsHistorikkActionTypes.SET_OPEN_ROWS,
    openRows,
  }),

  fetchMineMeldinger: (
    fetchMore = false,
    fetchSilentUntilPage?: number,
    pageMetaData?: IMeldingsHistorikkState['mineMeldingerPageMetaData'],
    meldingsHistorikk?: IMeldingsHistorikkState['mineMeldinger']
  ) => ({
    type: meldingsHistorikkActionTypes.FETCH_MINE_MELDINGER,
    fetchMore,
    fetchSilentUntilPage,
    pageMetaData,
    meldingsHistorikk,
  }),
  fetchMineMeldingerOk: (
    meldingsHistorikk: IMeldingsHistorikkState['mineMeldinger'],
    fetchMore = false,
    pageMetaData: IMeldingsHistorikkState['mineMeldingerPageMetaData']
  ) => ({
    type: meldingsHistorikkActionTypes.FETCH_MINE_MELDINGER_OK,
    meldingsHistorikk,
    fetchMore,
    pageMetaData,
  }),
  fetchMineMeldingerFail: (error: string) => ({
    type: meldingsHistorikkActionTypes.FETCH_MINE_MELDINGER_FAIL,
    error,
  }),
}

export const meldingsHistorikkSelectors = {
  getLoadingStatus: (
    state: IStoreState
  ): {
    loading: boolean
    loaded: boolean
    error: string | null
    funnLoaded: boolean
    tilsynUtfoertLoaded: boolean
    filtering: boolean
  } => ({
    loading: state.meldingsHistorikk.loading,
    loaded: state.meldingsHistorikk.loaded,
    error: state.meldingsHistorikk.error,
    funnLoaded: state.meldingsHistorikk.funnLoaded,
    tilsynUtfoertLoaded: state.meldingsHistorikk.tilsynUtfoertLoaded,
    filtering: !!state.meldingsHistorikk.filtering,
  }),

  getMeldingsHistorikkForVirksomhet: (
    state: IStoreState
  ): IMeldingsHistorikkState['meldingsHistorikkForVirksomhet'] =>
    state.meldingsHistorikk?.meldingsHistorikkForVirksomhet,

  getVirksomhet: (state: IStoreState): IMeldingsHistorikkState['virksomhet'] =>
    state.meldingsHistorikk?.virksomhet,

  getMeldingsHistorikkForRolle: (
    state: IStoreState
  ): IMeldingsHistorikkState['meldingsHistorikkForRolle'] =>
    state.meldingsHistorikk?.meldingsHistorikkForRolle,

  getMeldingsHistorikkForRollePageMetaData: (
    state: IStoreState
  ): IMeldingsHistorikkState['meldingsHistorikkForRollePageMetaData'] =>
    state.meldingsHistorikk?.meldingsHistorikkForRollePageMetaData,

  getAccessType: (state: IStoreState): IMeldingsHistorikkState['accessType'] =>
    state.meldingsHistorikk?.accessType,

  getOpenRows: (state: IStoreState): string[] =>
    state.meldingsHistorikk.openRows,

  getSortedList: (state: IStoreState): IMelding[] | undefined =>
    state.meldingsHistorikk.sortedList,

  getMineMeldinger: (
    state: IStoreState
  ): IMeldingsHistorikkState['mineMeldinger'] =>
    state[STORE_NAME].mineMeldinger,

  getMineMeldingerPageMetaData: (
    state: IStoreState
  ): IMeldingsHistorikkState['mineMeldingerPageMetaData'] =>
    state.meldingsHistorikk?.mineMeldingerPageMetaData,
}
