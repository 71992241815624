import { ajaxWithAuth } from '../../../api'
import { of, Observable } from 'rxjs'
import { map } from 'rxjs/operators'

export const fetchImageFile =
  (url: string) =>
  (state$): Observable<Blob> =>
    of({
      url,
      responseType: 'blob',
    }).pipe(
      ajaxWithAuth(state$) as any,
      map((resp: any) => resp.response)
    )
