import React, { useCallback, useContext, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import dayjs from 'dayjs'
import { useMediaQuery } from 'react-responsive'
import {
  Button,
  Checkmark,
  Column,
  ErrorBox,
  Grid,
  GridItem,
  Header,
  LetterIcon,
  LoadingSpinner,
  RoundIndicator,
  Row,
  ShadowBox,
  Text,
  TextLink,
  ThemeContext,
} from '@mattilsynet/mt-ui'
import { meldingActions } from '../../ducks/melding/actions'
import { useTypedSelector } from '../../common/custom-hooks'
import { meldingSelectors } from '../../ducks/melding/selectors'
import { getValidIdentitetListWithAktivitet } from '../../ducks/tilsynsobjekt/helpers'
import { goBack, pushStateWithSource } from '../../common/common-router'
import { FunnBox } from '../../components/funn-box'
import { Vurdering } from '../../components/vurdering'
import { useTilsynsobjektHooks } from '../../ducks/tilsynsobjekt'
import {
  getVurderingError,
  MeldingStatus,
  meldingStatusIsOneOf,
} from '../../ducks/melding/helpers'
import { dangerToast } from '../../ducks/common/toast-actions'
import { uiSelectors } from '../../ducks/ui/selectors'
import { AvsenderCard } from './AvsenderCard'

const InfoMessage = () => (
  <GridItem backgroundColor="#FFF">
    <Row align="center" padding={3}>
      <RoundIndicator>i</RoundIndicator>
      <Text size="medium" margin={[0, 0, 0, 3]}>
        Dette er en melding om at kjøttkontrollen har observert forhold på
        slaktedyr, som gir grunn til bekymring for dyreholdet. Mottaker må
        vurdere innholdet i meldingen sammen med annen informasjon om
        dyreholdet, og vurdere behov for offentlig kontroll eller annen
        oppfølging. Eventuelle vedtak må først og fremst bygge på egne
        observasjoner i dyreholdet.
      </Text>
    </Row>
  </GridItem>
)

interface IContentWithHeader {
  children: React.ReactNode
  title: string
  small?: boolean
}
const ContentWithHeader = ({ children, small, title }: IContentWithHeader) => (
  <Column as="section" spacing={2}>
    <Header size={small ? 'heading3' : 'heading2'} as="h2">
      {title}
    </Header>
    {children}
  </Column>
)

export const Melding = ({ match }: any) => {
  const dispatch = useDispatch()
  const theme = useContext(ThemeContext)
  const isWideScreen = useMediaQuery({ query: '(min-width: 1400px)' })

  const meldingId = match.params.meldingId
  const { loading, error, loaded } = useTypedSelector(
    meldingSelectors.getLoadingStatus
  )
  const melding = useTypedSelector(meldingSelectors.getMelding)
  const { saved } = useTypedSelector(meldingSelectors.getSavingStatus)
  const environment = useTypedSelector(uiSelectors.getEnvironment)
  const source = new URLSearchParams(location.search).get('source')

  const {
    tilsynsobjekt: produsent,
    tilsynsobjektLoadingStatus: produsentLoadingStatus,
    fetchTilsynsobjekt: fetchProdusent,
  } = useTilsynsobjektHooks(melding?.produsentTilsynsobjektId)

  const funnList = melding?.funnList

  const fetchMelding = useCallback(() => {
    dispatch(meldingActions.fetchMelding(meldingId))
  }, [dispatch, meldingId])

  const fetchAllowedStatuses = useCallback(() => {
    dispatch(meldingActions.fetchAllowedStatuses())
  }, [dispatch])

  const fetchAllowedStatusBegrunnelser = useCallback(() => {
    dispatch(meldingActions.fetchAllowedBegrunnelser())
  }, [dispatch])

  useEffect(() => {
    fetchMelding()
  }, [fetchMelding])

  useEffect(() => {
    fetchProdusent()
  }, [fetchProdusent])

  useEffect(() => {
    fetchAllowedStatuses()
    fetchAllowedStatusBegrunnelser()
  }, [fetchAllowedStatuses, fetchAllowedStatusBegrunnelser])

  const onSubmit = () => {
    const error = getVurderingError(melding?.vurderingLocal)

    if (error) {
      return dispatch(dangerToast(error))
    }

    dispatch(meldingActions.updateVurdering())
  }

  const goToTilsynskvittering = () => {
    if (environment === 'dev') {
      return window.open('http://localhost:3003', '_blank')
    }
    if (environment === 'utv') {
      return window.open(
        'https://tilsynskvittering.inspektor-utv.mattilsynet.io',
        '_blank'
      )
    }
    if (environment === 'test') {
      return window.open(
        'https://tilsynskvittering.inspektor-test.mattilsynet.io',
        '_blank'
      )
    }
    return window.open(
      'https://tilsynskvittering.inspektor.mattilsynet.io',
      '_blank'
    )
  }

  const goBackdispatch = () => {
    dispatch(goBack('/'))
  }

  if (error) {
    return (
      <ErrorBox
        errorText="Kunne ikke laste inn melding."
        errorAction={fetchMelding}
        errorActionText="Prøv å laste innhold på nytt"
      />
    )
  }
  if ((loading && !loaded) || !melding) {
    return <LoadingSpinner title="Laster melding" />
  }

  const renderProdusentCard = () => {
    if (!produsentLoadingStatus || produsentLoadingStatus?.loading)
      return (
        <Column align={'center'} spacing={1} padding={[3, 1]}>
          <LoadingSpinner title="Laster tilsynsobjekt.." />
        </Column>
      )
    if (produsentLoadingStatus.error || !produsent)
      return (
        <Column align={'center'} spacing={1} padding={[3, 1]}>
          <ErrorBox
            errorAction={fetchProdusent}
            errorActionText="Last innhold på nytt"
            errorText="Kunne ikke hente tilsynsobjekt..."
          />
        </Column>
      )

    return (
      <Column align={'center'} spacing={1} padding={[3, 1]}>
        <Text
          size="heading3"
          as="h3"
          uppercase
          margin={[0, 0, 0.5, 0]}
          weight="bold"
        >
          {produsent.virksomhetsNavn}
        </Text>
        <Text size="heading3">{produsent.navn}</Text>
        <Column align={'center'} spacing={0.5}>
          {getValidIdentitetListWithAktivitet(produsent).map(
            ({ label, value }) => (
              <Text
                textAlign="center"
                key={`${label}-${value}`}
                weight="light"
                size="heading3"
              >
                {label}: {value}
              </Text>
            )
          )}

          <Row padding={[3, 0, 0, 0]}>
            <Button
              secondary
              onClick={() =>
                dispatch(
                  pushStateWithSource(
                    `/meldinger/${melding?.produsentTilsynsobjektId}`
                  )
                )
              }
              arrow
              allowMultiLine
            >
              Se virksomhetens meldingshistorikk
            </Button>
          </Row>
        </Column>
      </Column>
    )
  }

  return (
    <Grid>
      {source && (
        <GridItem as="nav">
          <Row margin={[0, 0, 1.5, 0]}>
            <TextLink onClick={goBackdispatch} leftArrow>
              Tilbake
            </TextLink>
          </Row>
        </GridItem>
      )}
      <GridItem xl={[3, -3]} lg={[1, -1]} md={[1, -1]} sm={[1, -1]}>
        <Column spacing={4}>
          <InfoMessage />
          <GridItem backgroundColor="#FFF">
            <Column padding={isWideScreen ? [4, 15, 4, 15] : [4, 2, 4, 2]}>
              <Column spacing={4}>
                <Header as="h1" size="heading1">
                  Melding fra inspektør i kjøttkontrollen
                </Header>

                <Column spacing={2.5}>
                  <Row align="center">
                    <LetterIcon
                      size="large"
                      color={theme.primary}
                      margin={[0, 1, 0, 0]}
                    />
                    <Header size="heading2">
                      Melding mottatt{' '}
                      {dayjs(melding.sistRedigert).format('D. MMM YYYY')}
                    </Header>
                  </Row>

                  <Column spacing={1}>
                    <Header as="h2" size="heading2">
                      Saksnummer for meldingen
                    </Header>
                    <Text>{melding.saksnummer}</Text>
                  </Column>
                </Column>

                <ContentWithHeader title="Avsender">
                  <ShadowBox>
                    <AvsenderCard melding={melding} />
                  </ShadowBox>
                </ContentWithHeader>

                <ContentWithHeader title="Meldingen gjelder">
                  <ShadowBox>{renderProdusentCard()}</ShadowBox>
                </ContentWithHeader>

                <ContentWithHeader title="Begrunnelse for bekymring">
                  <Text>{melding.begrunnelse}</Text>
                </ContentWithHeader>

                <ContentWithHeader title="Kategori">
                  <Text>
                    {melding.kategorier.map((k) => k.beskrivelse).join(', ')}
                  </Text>
                </ContentWithHeader>

                <ContentWithHeader title="Relevante funn">
                  <Column as="ul" spacing={2}>
                    {funnList?.map((funn) => (
                      <Column as="li" key={funn.funnId}>
                        <FunnBox funn={funn} />
                      </Column>
                    ))}
                  </Column>
                </ContentWithHeader>

                <ContentWithHeader title="Vurdering">
                  <Vurdering />
                </ContentWithHeader>

                <Row justify="center">
                  <Button
                    large
                    onClick={onSubmit}
                    width="300px"
                    icon={saved ? <Checkmark /> : undefined}
                  >
                    {saved
                      ? 'Videre oppfølging er lagret'
                      : 'Lagre videre oppfølging'}
                  </Button>
                </Row>

                {saved && (
                  <Row justify="center">
                    <Text>
                      Du er ferdig med å behandle meldingen og kan nå lukke
                      fanen.
                    </Text>
                  </Row>
                )}
                {saved &&
                  meldingStatusIsOneOf(melding?.vurderingLocal?.status, [
                    MeldingStatus.INSPEKSJON,
                  ]) && (
                    <Row justify="center">
                      <Text margin={[0, 0.5, 0, 0]}>
                        Planlegge tilsynet med en gang? Gå til Tilsynskvittering
                        for å
                      </Text>
                      <TextLink linkOutside onClick={goToTilsynskvittering}>
                        forberede et nytt tilsyn
                      </TextLink>
                    </Row>
                  )}
                {saved &&
                  melding.vurderingLocal?.paaminnelseDato &&
                  meldingStatusIsOneOf(melding?.vurderingLocal?.status, [
                    MeldingStatus.SENERE_OPPFØLGING,
                  ]) && (
                    <Row justify="center">
                      <Text margin={[0, 0.5, 0, 0]}>
                        Du vil motta en påminnelse på e-post om å følge opp
                        meldingen{' '}
                        {dayjs(melding.vurderingLocal.paaminnelseDato).format(
                          'DD. MMM YYYY'
                        )}
                        .
                      </Text>
                    </Row>
                  )}
              </Column>
            </Column>
          </GridItem>
        </Column>
      </GridItem>
    </Grid>
  )
}
