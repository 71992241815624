import { combineEpics, Epic } from 'redux-observable'
import {
  catchError,
  filter,
  map,
  mergeMap,
  withLatestFrom,
} from 'rxjs/operators'
import { of } from 'rxjs'
import { IStoreState } from '../../../reducers/types'
import { fetchImageFile } from './helper'
import { imagesActions, imagesActionTypes } from '../store'
import { IImagesActions } from '../store/types'
import { createQueryStringFromObject } from '@mattilsynet/mt-common/lib/utils/createQueryStringFromObject'
import { commonRedux } from '@mattilsynet/mt-common'
import { API_ROUTES } from '../../../epics'

const { isOfType } = commonRedux

export const fetchImageEpic =
  (): Epic<IImagesActions, IImagesActions, IStoreState> => (action$, state$) =>
    action$.pipe(
      filter(isOfType(imagesActionTypes.FETCH_IMAGE_THUMBNAIL)),
      withLatestFrom(state$),
      mergeMap(([{ imageId, size }, state]) => {
        return of(state).pipe(
          fetchImageFile(
            `${
              API_ROUTES.BILDE
            }/kategorier/bilder/${imageId}/${size}${createQueryStringFromObject(
              '?'
            )({
              'filter.app': ['MAKKS'],
            })}`
          ),
          map((res) => {
            const imageBlob = new File([res], imageId)
            const imageUrl = URL.createObjectURL(imageBlob)
            return imagesActions.fetchImageOk({ id: imageId, imageUrl, size })
          }),
          catchError((err) => {
            return of(imagesActions.fetchImageFail(err.message))
          })
        )
      })
    )

const imageEpics = () => combineEpics(fetchImageEpic())

export default imageEpics
