import { IStoreState } from '../../reducers/types'
import { IMelding, IMeldingState, IVurdering } from './types'
import Vurdering = IMeldingTilLokaltMattilsynRemote.Vurdering

export const meldingSelectors = {
  getLoadingStatus: (
    state: IStoreState
  ): { loading: boolean; loaded: boolean; error: string | null } => ({
    loading: state.melding.loading,
    loaded: state.melding.loaded,
    error: state.melding.error,
  }),

  getSavingStatus: (
    state: IStoreState
  ): { saving: boolean; saved: boolean; saveError: string | null } => ({
    saving: state.melding.saving,
    saved: state.melding.saved,
    saveError: state.melding.saveError,
  }),

  getMelding: (state: IStoreState): IMelding | undefined =>
    state.melding?.melding,

  getVurdering: (state: IStoreState): Vurdering | undefined =>
    state.melding?.melding?.vurdering,

  getVurderingLocal: (state: IStoreState): Partial<IVurdering> | undefined =>
    state.melding?.melding?.vurderingLocal,

  getAllowedStatuses: (state: IStoreState): IMeldingState['allowedStatuses'] =>
    state?.melding?.allowedStatuses,

  getAllowedStatusBegrunnelser: (
    state: IStoreState
  ): IMeldingState['allowedStatusBegrunnelser'] =>
    state?.melding?.allowedStatusBegrunnelser,
}
