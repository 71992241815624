import React, { useCallback, useEffect } from 'react'
import styled from 'styled-components'
import { IImage, IImageSizes } from '../../ducks/images/store/types'
import { IThemeColors } from '@mattilsynet/mt-ui/dist/assets/colors'
import { useDispatch } from 'react-redux'
import { imagesActions, imagesSelectors } from '../../ducks/images/store'
import { useTypedSelector } from '../../common/custom-hooks'
import * as R from 'ramda'

const StyledImageDiv = styled.div<{
  theme: IThemeColors
  clickable: boolean
  image: IImage
}>`
  & {
    background-image: url(${({ image }) => image.imageUrl});
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    height: 100%;
    width: 100%;
    border-radius: 0.25em;
  }
`

const StyledImageWrapper = styled.div`
  cursor: pointer;
  height: 3em;
  width: 3em;
  border-radius: 0.25em;
  padding-top: 0.5em;
  padding-right: 0.5em;
`

interface IImageThumbnailProps {
  id: string
  imageId: string
  onClick?: (id: string) => void
  preferredImageSize: IImageSizes
}

export const getImageViewUrl = (imageId: string) => `/image-view/${imageId}`

export const ImageThumbnail = ({
  id,
  imageId,
  onClick,
  preferredImageSize = 'small',
}: IImageThumbnailProps) => {
  const dispatch = useDispatch()
  const image = useTypedSelector(imagesSelectors.getImage(imageId), R.equals)

  const fetchImage = useCallback(() => {
    if (!image?.id) {
      dispatch(imagesActions.fetchImage(imageId, preferredImageSize))
    }
  }, [dispatch, imageId, image?.id, preferredImageSize])

  useEffect(() => {
    fetchImage()
  }, [fetchImage])

  if (!image) return null

  return (
    <StyledImageWrapper id={id}>
      <StyledImageDiv image={image} onClick={() => onClick?.(imageId)} />{' '}
    </StyledImageWrapper>
  )
}
