import React from 'react'
import { useDispatch } from 'react-redux'
import { push } from 'connected-react-router'
import { Button, ShadowBox, Text, Column } from '@mattilsynet/mt-ui'

const NoRoute = () => {
  const dispatch = useDispatch()
  const goToHome = () => dispatch(push('/'))
  return (
    <ShadowBox>
      <Column
        className="page-content"
        minHeight="15em"
        align="center"
        justify="center"
      >
        <Text size="heading2" margin={[1, 1, 1, 1]}>
          Kunne ikke finne siden
        </Text>
        <Button onClick={goToHome}>Gå til hovedsiden</Button>
      </Column>
    </ShadowBox>
  )
}

export default NoRoute
