import React from 'react'
import {
  Column,
  Header,
  InfoBox,
  LoadingSpinner,
  Row,
  Text,
} from '@mattilsynet/mt-ui'
import { ModalWrapper } from '../modal-wrapper'
import { useTypedSelector } from '../../common/custom-hooks'
import { meldingSelectors } from '../../ducks/melding/selectors'
import { sakSelectors } from '../../ducks/sak'
import {
  MeldingStatus,
  meldingStatusIsOneOf,
} from '../../ducks/melding/helpers'
import { useTilsynsobjektHooks } from '../../ducks/tilsynsobjekt'

interface IVurderingCreateSakModalProps {
  isOpen: boolean
  onCancel: () => void
  onCreateSak: () => void
}

export const VurderingCreateSakModal = ({
  isOpen,
  onCancel,
  onCreateSak,
}: IVurderingCreateSakModalProps) => {
  const melding = useTypedSelector(meldingSelectors.getMelding)
  const vurdering = useTypedSelector(meldingSelectors.getVurderingLocal)
  const { tilsynsobjekt: produsent } = useTilsynsobjektHooks(
    melding?.produsentTilsynsobjektId
  )
  const { loading, error } = useTypedSelector(sakSelectors.getSakState)

  const tittel = `${produsent?.virksomhetsNavn} - ${
    meldingStatusIsOneOf(vurdering?.status || 'NY', [
      MeldingStatus.SAKSBEHANDLING,
    ])
      ? 'Saksbehandling'
      : 'Inspeksjon'
  }`

  const handleCreateSak = () => {
    onCreateSak()
  }

  return (
    <ModalWrapper
      onCancel={onCancel}
      isOpen={isOpen}
      title="Opprett ny sak"
      onOk={handleCreateSak}
      buttonOkText="Opprett ny sak"
    >
      <Column spacing={3} padding={[2.38, 0, 10, 0]}>
        <Header as="h2" size="heading3">
          Du er i ferd med å opprette en ny sak
        </Header>
        <Text>Sakstittel: {tittel}</Text>

        {loading && (
          <Row center>
            <LoadingSpinner title={'Oppretter sak...'} small />
          </Row>
        )}
        {error && (
          <InfoBox
            danger
            title="En feil oppstod under opprettelse av sak"
            buttonText="Fjern"
            onClick={onCancel}
          >
            Kunne ikke opprette sak, vennligst prøv igjen.
          </InfoBox>
        )}
      </Column>
    </ModalWrapper>
  )
}
