import { CommonReduxTypes } from '@mattilsynet/mt-common'
import { IMelding } from '../melding/types'
import { meldingsHistorikkActions } from './index'
import Virksomhet = ITilsynsobjektRemote.Virksomhet
import PageMetadata = IKjoettkontrollFunnRemote.PageMetadata

export enum AccessType {
  SEKSJON = 'SEKSJON',
  AVDELING = 'AVDELING',
  REGION = 'REGION',
  ALLE = 'ALLE',
  INGEN = 'INGEN',
}

export interface IMeldingsHistorikkState {
  loading: boolean
  loaded: boolean
  error: string | null
  meldingsHistorikkForVirksomhet?: IMelding[]
  meldingsHistorikkForRolle?: IMelding[]
  mineMeldinger?: IMelding[]
  meldingsHistorikkForRollePageMetaData: PageMetadata
  mineMeldingerPageMetaData: PageMetadata
  openRows: string[]
  virksomhet?: Virksomhet
  accessType: keyof typeof AccessType
  funnLoaded: boolean
  tilsynUtfoertLoaded: boolean
  sortedList?: IMelding[]
  filtering?: boolean
}

export type IMeldingsHistorikkActions =
  CommonReduxTypes.IGetActionTypesFromObject<typeof meldingsHistorikkActions>
