import { ICommonApi } from '../../../api'
import { combineEpics, Epic } from 'redux-observable'
import { IStoreState } from '../../../reducers/types'
import {
  catchError,
  filter,
  map,
  mergeMap,
  switchMap,
  withLatestFrom,
} from 'rxjs/operators'
import { concat, forkJoin, Observable, of } from 'rxjs'
import dayjs from 'dayjs'
import { isOfType } from '@mattilsynet/mt-common/lib/common-redux/helpers'
import { meldingActions, meldingActionTypes } from '../actions'
import { IAmFunn, IMelding, IMeldingActions, IPmFunn } from '../types'
import { createQueryStringFromObject } from '@mattilsynet/mt-common/lib/utils/createQueryStringFromObject'
import { API_ROUTES } from '../../../epics'
import { dangerToast } from '../../common/toast-actions'
import { convertStatusArrayToObject, formatVurderingToBody } from '../helpers'
import { AnyAction } from 'redux'

export const updateMeldingsVurdering =
  (commonApi: ICommonApi): Epic<IMeldingActions, AnyAction, IStoreState> =>
  (action$, state$) =>
    action$.pipe(
      filter(isOfType(meldingActionTypes.UPDATE_VURDERING)),
      withLatestFrom(state$),
      switchMap(([, state]) => {
        const meldingId = state.melding?.melding?.meldingId
        const vurderingState = state.melding?.melding?.vurdering || {}
        const vurderingLocalState = state.melding?.melding?.vurderingLocal || {}
        const vurdering = { ...vurderingState, ...vurderingLocalState }

        return of(state).pipe(
          commonApi.put(
            `${API_ROUTES.MELDING_TIL_LOKALT_MATTILSYN}/v1/vurderinger/${meldingId}`,
            state,
            formatVurderingToBody(vurdering)
          ),
          map(() => {
            return meldingActions.updateVurderingOk(vurdering)
          }),
          catchError((err) => {
            return concat(
              of(meldingActions.updateVurderingFail(err.message)),
              of(dangerToast('Kunne ikke lagre oppfølging')),
              of(meldingActions.fetchMelding(String(meldingId)))
            )
          })
        )
      })
    )

export const getMelding =
  (
    commonApi: ICommonApi
  ): Epic<IMeldingActions, IMeldingActions, IStoreState> =>
  (action$, state$) =>
    action$.pipe(
      filter(isOfType(meldingActionTypes.FETCH_MELDING)),
      withLatestFrom(state$),
      switchMap(([{ meldingId }, state]) =>
        of(state).pipe(
          commonApi.get(
            `${API_ROUTES.MELDING_TIL_LOKALT_MATTILSYN}/v2/mattilsynmelding/${meldingId}`,
            state
          ),
          mergeMap((melding: IMelding) => {
            const amFunnIds = melding.amFunnIds || []
            const pmFunnIds = melding.pmFunnIds || []

            if (amFunnIds.length === 0 && pmFunnIds.length === 0) {
              return of(
                meldingActions.fetchMeldingOk({
                  ...melding,
                  funnList: [],
                })
              )
            }

            const requests: Observable<any>[] = []

            if (amFunnIds.length > 0) {
              requests.push(
                commonApi.get(
                  `/api/v2/am/funn/${createQueryStringFromObject('?')({
                    slakteDatoFrom: dayjs()
                      .subtract(365, 'days')
                      .format('YYYY-MM-DD'),
                    eftanummer: melding.eftanummer,
                    funnIds: amFunnIds.join(','),
                    expand: ['individer', 'observasjoner', 'bildeIds'],
                  })}`,
                  state
                )(of(state))
              )
            }
            if (pmFunnIds.length > 0) {
              requests.push(
                commonApi.get(
                  `/api/v2/pm/funn/${createQueryStringFromObject('?')({
                    slakteDatoFrom: dayjs()
                      .subtract(365, 'days')
                      .format('YYYY-MM-DD'),
                    eftanummer: melding.eftanummer,
                    funnIds: pmFunnIds.join(','),
                    expand: ['observasjoner', 'bildeIds'],
                  })}`,
                  state
                )(of(state))
              )
            }

            return forkJoin([...requests]).pipe(
              map((res) => {
                let amFunnList: (IAmFunn | IPmFunn)[] = []
                let pmFunnList: (IAmFunn | IPmFunn)[] = []
                res.forEach((r) => {
                  if (r?._embedded?.amFunnList) {
                    amFunnList = [...amFunnList, ...r._embedded.amFunnList]
                  } else if (r?._embedded?.pmFunnList) {
                    pmFunnList = [...pmFunnList, ...r._embedded.pmFunnList]
                  }
                })

                amFunnList = amFunnList.map((funn) => ({
                  ...funn,
                  isAmFunn: true,
                }))
                pmFunnList = pmFunnList.map((funn) => ({
                  ...funn,
                  isAmFunn: false,
                }))

                return meldingActions.fetchMeldingOk({
                  ...melding,
                  funnList: amFunnList.concat(pmFunnList),
                })
              })
            )
          }),
          catchError((err) => of(meldingActions.fetchMeldingFail(err.message)))
        )
      )
    )

export const getAllowedStatuses =
  (
    commonApi: ICommonApi
  ): Epic<IMeldingActions, IMeldingActions, IStoreState> =>
  (action$, state$) =>
    action$.pipe(
      filter(isOfType(meldingActionTypes.FETCH_ALLOWED_STATUSES)),
      withLatestFrom(state$),
      switchMap(([, state]) =>
        of(state).pipe(
          commonApi.get(
            `${API_ROUTES.MELDING_TIL_LOKALT_MATTILSYN}/v1/vurderinger/statuser`,
            state
          ),
          mergeMap((resp: any) => {
            const allowedStatuses = convertStatusArrayToObject(
              resp?._embedded?.statusList
            )
            return of(meldingActions.fetchAllowedStatusesOk(allowedStatuses))
          }),
          catchError((err) =>
            of(meldingActions.fetchAllowedStatusesFail(err.message))
          )
        )
      )
    )

export const getAllowedStatusBegrunnelser =
  (
    commonApi: ICommonApi
  ): Epic<IMeldingActions, IMeldingActions, IStoreState> =>
  (action$, state$) =>
    action$.pipe(
      filter(isOfType(meldingActionTypes.FETCH_ALLOWED_BEGRUNNELSER)),
      withLatestFrom(state$),
      switchMap(([{ status }, state]) =>
        of(state).pipe(
          commonApi.get(
            status
              ? `${API_ROUTES.MELDING_TIL_LOKALT_MATTILSYN}/v1/statusbegrunnelser?status=${status}`
              : `${API_ROUTES.MELDING_TIL_LOKALT_MATTILSYN}/v1/statusbegrunnelser`,
            state
          ),
          mergeMap((resp: any) => {
            return of(
              meldingActions.fetchAllowedBegrunnelserOk(
                resp?._embedded?.statusList
              )
            )
          }),
          catchError((err) =>
            of(meldingActions.fetchAllowedBegrunnelserFail(err.message))
          )
        )
      )
    )

export default (commonApi: ICommonApi) =>
  combineEpics(
    getMelding(commonApi),
    updateMeldingsVurdering(commonApi),
    getAllowedStatuses(commonApi),
    getAllowedStatusBegrunnelser(commonApi)
  )
