import React from 'react'
import { Row } from '@mattilsynet/mt-ui'
import { Notifications } from '@mattilsynet/mt-common'
import UserMenu from '../user-menu'
import { useTypedSelector } from '../../common/custom-hooks'
import { userSelectors } from '../../ducks/user/selectors'
import './style.css'

const TopMenuElements = () => {
  const { username } = useTypedSelector(userSelectors.getAuthUser)

  return (
    <Row className="TopMenuElements" align="center">
      {username && (
        <>
          <Notifications />
          <UserMenu />
        </>
      )}
    </Row>
  )
}

export default TopMenuElements
