import { ITilsynsobjektActions, ITilsynsobjektState } from './types'
import { commonRedux } from '@mattilsynet/mt-common'

export const STORE_NAME = 'tilsynsobjekt'

const { fetchWithIndexHandlers, fetchActions, fetchActionTypes } =
  commonRedux.fetchWithIndexHelpers(STORE_NAME, 'idstring')

export const tilsynsobjektActionsTypes = {
  ...fetchActionTypes,
}

export const tilsynsobjektActions = {
  ...fetchActions,
}

const customReducer = commonRedux.createReducer<
  ITilsynsobjektState,
  ITilsynsobjektActions
>(commonRedux.initialListState, {
  ...fetchWithIndexHandlers,
})

export const tilsynsobjektReducer = {
  [STORE_NAME]: customReducer,
}
