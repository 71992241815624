import React, { useCallback, useEffect } from 'react'
import { useTypedSelector } from '../../common/custom-hooks'
import { imagesActions, imagesSelectors } from '../../ducks/images/store'
import { useDispatch } from 'react-redux'
import {
  ErrorBox,
  Grid,
  GridItem,
  LoadingSpinner,
  Row,
  TextLink,
} from '@mattilsynet/mt-ui'
import { goBack } from '../../common/common-router'
import { IMatchParams, IRouteComponentProps } from '../types'
import * as R from 'ramda'
import './style.css'

const ImageViewRoute = ({ match }: IRouteComponentProps<IMatchParams>) => {
  const imageId = match.params.id

  const image = useTypedSelector(imagesSelectors.getImage(imageId), R.equals)
  const { loading, error } = useTypedSelector(
    imagesSelectors.getLoadingStatus()
  )

  const dispatch = useDispatch()
  const fetchImage = useCallback(() => {
    if (!image?.id) {
      dispatch(imagesActions.fetchImage(imageId, 'original'))
    }
  }, [dispatch, imageId, image?.id])

  useEffect(() => {
    fetchImage()
  }, [fetchImage])

  const goBackdispatch = () => {
    dispatch(goBack('/'))
  }

  const renderImage = () => {
    if (!image && error) {
      return (
        <ErrorBox
          errorText="Kunne ikke hente bilde.."
          errorAction={fetchImage}
          errorActionText="Præv på nytt"
        />
      )
    }

    if (!image && loading) {
      return <LoadingSpinner title="Henter bilde..." />
    }

    if (!image) return null
    return (
      <Row justify="center">
        <img src={image.imageUrl} className="image-view-display" />
      </Row>
    )
  }

  return (
    <Grid id="image-view">
      <GridItem as="nav">
        <Row>
          <TextLink onClick={goBackdispatch} leftArrow>
            Tilbake
          </TextLink>
        </Row>
      </GridItem>
      <GridItem>{renderImage()}</GridItem>
    </Grid>
  )
}

export default ImageViewRoute
