import { uiActions, uiActionTypes } from '../actions'
import { combineEpics, Epic } from 'redux-observable'
import { concat, of } from 'rxjs'
import { catchError, filter, mergeMap, withLatestFrom } from 'rxjs/operators'

import { getUserOrgenhet } from '../../user/epic'

// Types
import { AnyAction } from 'redux'
import { IStoreState } from '../../../reducers/types'
import { ICommonApi } from '../../../api'
import { IUiActions } from '../types'
import { commonRedux } from '@mattilsynet/mt-common'
import { userActionTypes } from '../../user/actions'
import { IUserActions } from '../../user/types'

const { isOfType } = commonRedux

export const initialLoadingEpic =
  (
    commonApi: ICommonApi
  ): Epic<IUiActions | IUserActions, AnyAction, IStoreState> =>
  (action$, state$) =>
    action$.pipe(
      filter(
        isOfType([userActionTypes.SET_LOGGED_IN_USER, uiActionTypes.INITIALIZE])
      ),
      withLatestFrom(state$),
      mergeMap(([, state]) =>
        concat(
          getUserOrgenhet(commonApi)(state),
          of(uiActions.initializeSuccess())
        ).pipe(catchError((err) => of(uiActions.initializeFailed(err.message))))
      )
    )

export default (commonApi: ICommonApi) =>
  combineEpics(initialLoadingEpic(commonApi))
