import React from 'react'
import { Column, Row, InputField, Button } from '@mattilsynet/mt-ui'

interface ISakSearchProps {
  onAddSak: () => void
  onChange: (e: any) => void
  sakNumberValue: string
  errorText: string
}

export const SakSearch = ({
  onAddSak,
  onChange,
  sakNumberValue,
  errorText,
}: ISakSearchProps) => {
  const onSubmitSearchSak = (e) => {
    if (e.key === 'Enter') {
      e.currentTarget.blur()
      onAddSak()
    }
  }
  return (
    <Row spacing={2}>
      <Column justify="flex-end">
        <InputField
          className="inputField"
          value={sakNumberValue}
          label="Saksnummer"
          onChange={onChange}
          pattern="[0-9]*"
          placeholder="Feks. 2018/342343"
          inputMode="decimal"
          status={Boolean(errorText) ? 'danger' : undefined}
          errorText={errorText}
          onKeyDown={onSubmitSearchSak}
        />
      </Column>
      <Column justify="flex-end">
        <Button secondary onClick={onAddSak}>
          Legg til sak
        </Button>
      </Column>
    </Row>
  )
}
