import React, { ComponentProps, useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import dayjs from 'dayjs'
import * as R from 'ramda'
import { useMediaQuery } from 'react-responsive'
import {
  Accordion,
  CheckboxItem,
  Column,
  Row,
  SelectableButton,
  ShadowBox,
  TextLink,
} from '@mattilsynet/mt-ui'
import { useTypedSelector } from '../../common/custom-hooks'
import { uiSelectors } from '../../ducks/ui/selectors'
import { uiActions } from '../../ducks/ui/actions'
import { AvdelingerFilterModal } from './avdelinger-filter-modal'
import { SelectedFilter } from './SelectedFilter'
import { IDisplayAndValue } from '../filter-list'
import { initialFilterParams } from '../../ducks/ui/reducer/initialState'
import { OppfoelgingFilterModal } from './oppfoelging-filter-modal'
import { AktivitetFilterModal } from './aktivitet-filter-modal'
import {
  BegrunnelseFilterModal,
  begrunnelserForStatusSenereOppfoelging,
  begrunnelserForStatusVurdert,
} from './begrunnelse-filter-modal'
import { DatoFilterModal } from './dato-filter-modal'
import {
  meldingsHistorikkActions,
  meldingsHistorikkSelectors,
} from '../../ducks/meldingshistorikk'
import { IMeldingFilterParams } from '../../common/melding-helpers'
import PageMetadata = IMeldingTilLokaltMattilsynRemote.PageMetadata
import { FilterAccordionHeader } from './filter-accordion-header'
import { MeldingStatus } from '../../ducks/melding/helpers'

export const hasBegrunnelserForStatusVurdert = (
  filterParams: IMeldingFilterParams
) => {
  const begrunnelser = filterParams.begrunnelser.map((b) => b.value)
  return begrunnelser.some((begrunnelse) =>
    begrunnelserForStatusVurdert.find((b) => b === begrunnelse)
  )
}
export const hasBegrunnelserForStatusSenereOppfoelging = (
  filterParams: IMeldingFilterParams
) => {
  const begrunnelser = filterParams.begrunnelser.map((b) => b.value)
  return begrunnelser.some((begrunnelse) =>
    begrunnelserForStatusSenereOppfoelging.find((b) => b === begrunnelse)
  )
}

const hasBegrunnelser = (filterParams: IMeldingFilterParams) => {
  return (
    hasBegrunnelserForStatusVurdert(filterParams) ||
    hasBegrunnelserForStatusSenereOppfoelging(filterParams)
  )
}

const hasOppfoelginger = (filterParams: IMeldingFilterParams) => {
  const oppfoelginger = filterParams.oppfoelginger.map((o) => o.value)
  return oppfoelginger.some(
    (oppfoelging) =>
      oppfoelging === MeldingStatus.SENERE_OPPFØLGING ||
      oppfoelging === MeldingStatus.VURDERT
  )
}

const isBegrunnelseFilterDisabled = (filterParams: IMeldingFilterParams) => {
  return !hasOppfoelginger(filterParams) && !hasBegrunnelser(filterParams)
}

export const Filtrering = ({
  fetchData,
  pageMetaData,
}: {
  fetchData: (
    fetchMore?: boolean,
    fetchSilentUntilPage?: number,
    filterParams?: IMeldingFilterParams,
    filtering?: boolean
  ) => void
  pageMetaData: PageMetadata
}) => {
  const dispatch = useDispatch()
  const isTinyScreen = useMediaQuery({ query: '(max-width: 767px)' })
  const filterParams = useTypedSelector(uiSelectors.getFilterParams)
  const [filterParamsLocal, setFilterParamsLocal] =
    useState<IMeldingFilterParams>(filterParams)
  const accessType = useTypedSelector(meldingsHistorikkSelectors.getAccessType)
  const [isOpen, setIsOpen] = useState<boolean>(false)
  const [isAvdelingFilterModalOpen, setIsAvdelingFilterModalOpen] =
    useState<boolean>(false)
  const [isOppfoelgingFilterModalOpen, setIsOppfoelgingFilterModalOpen] =
    useState<boolean>(false)
  const [isBegrunnelseFilterModalOpen, setIsBegrunnelseFilterModalOpen] =
    useState<boolean>(false)
  const [isAktivitetFilterModalOpen, setIsAktivitetFilterModalOpen] =
    useState<boolean>(false)
  const [isDatoFilterModalOpen, setIsDatoFilterModalOpen] =
    useState<boolean>(false)
  const isFiltered = R.pipe(
    R.values,
    R.all((el) => R.isNil(el) || R.isEmpty(el)),
    R.not
  )(filterParams)

  let antallMeldingerText = ''
  if (
    pageMetaData.size != null &&
    pageMetaData.number != null &&
    pageMetaData.totalElements != null
  ) {
    let antallMeldingerSomVises = pageMetaData.size * (pageMetaData.number + 1)
    const totaltAntallMeldinger = pageMetaData.totalElements
    if (totaltAntallMeldinger < antallMeldingerSomVises) {
      antallMeldingerSomVises = totaltAntallMeldinger
    }
    antallMeldingerText = `Viser ${antallMeldingerSomVises} av ${pageMetaData.totalElements} meldinger`
  }

  useEffect(() => {
    if (
      filterParams.mottattDatoFrom !== filterParamsLocal.mottattDatoFrom ||
      filterParams.mottattDatoTo !== filterParamsLocal.mottattDatoTo ||
      filterParams.avdelinger !== filterParamsLocal.avdelinger ||
      filterParams.oppfoelginger !== filterParamsLocal.oppfoelginger ||
      filterParams.begrunnelser !== filterParamsLocal.begrunnelser ||
      filterParams.aktiviteter !== filterParamsLocal.aktiviteter ||
      filterParams.merkeavvik !== filterParamsLocal.merkeavvik ||
      filterParams.dyrevelferd !== filterParamsLocal.dyrevelferd
    ) {
      setFilterParamsLocal(filterParams)
      dispatch(meldingsHistorikkActions.setOpenRows([]))
      fetchData(undefined, undefined, filterParams, true)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterParams])

  const RowOrColumn = (
    props: ComponentProps<typeof Column> | ComponentProps<typeof Row>
  ) => (isTinyScreen ? <Column {...props} /> : <Row {...props} />)

  return (
    <ShadowBox>
      <Accordion
        small
        open={isOpen}
        onClick={() => setIsOpen(!isOpen)}
        header={
          <FilterAccordionHeader
            isFiltered={isFiltered}
            antallMeldingerText={antallMeldingerText}
          />
        }
      >
        <Column spacing={1}>
          <RowOrColumn spacing={1}>
            {accessType !== 'AVDELING' && (
              <SelectableButton
                withPadding
                onClick={() => setIsAvdelingFilterModalOpen(true)}
              >
                {accessType === 'REGION' ? 'Avdeling' : 'Region og avdeling'}
              </SelectableButton>
            )}
            <SelectableButton
              withPadding
              onClick={() => setIsOppfoelgingFilterModalOpen(true)}
            >
              Oppfølging
            </SelectableButton>
            <SelectableButton
              withPadding
              onClick={() => setIsBegrunnelseFilterModalOpen(true)}
              disabled={isBegrunnelseFilterDisabled(filterParams)}
            >
              Begrunnelser
            </SelectableButton>
            <SelectableButton
              withPadding
              onClick={() => setIsAktivitetFilterModalOpen(true)}
            >
              Aktivitet
            </SelectableButton>
            <SelectableButton
              withPadding
              onClick={() => setIsDatoFilterModalOpen(true)}
            >
              Dato
            </SelectableButton>
            <CheckboxItem
              selected={!!filterParams.dyrevelferd}
              onClick={() =>
                dispatch(
                  uiActions.setFilterParams({
                    ...filterParams,
                    dyrevelferd: !filterParams.dyrevelferd,
                  })
                )
              }
            >
              Vis meldinger om dyrevelferd
            </CheckboxItem>
            <CheckboxItem
              selected={!!filterParams.merkeavvik}
              onClick={() =>
                dispatch(
                  uiActions.setFilterParams({
                    ...filterParams,
                    merkeavvik: !filterParams.merkeavvik,
                  })
                )
              }
            >
              Vis meldinger om merkeavvik
            </CheckboxItem>
          </RowOrColumn>

          <Row wrap>
            {filterParams.avdelinger.map(
              (avdeling: IDisplayAndValue, index) => {
                return (
                  <SelectedFilter
                    key={avdeling.value}
                    marginRight={index >= filterParams.avdelinger.length - 1}
                    onClickRemove={() =>
                      dispatch(
                        uiActions.setFilterParams({
                          ...filterParams,
                          avdelinger: filterParams.avdelinger.filter(
                            ({ value }) => value !== avdeling.value
                          ),
                        })
                      )
                    }
                  >
                    {avdeling.display}
                  </SelectedFilter>
                )
              }
            )}
            {filterParams.oppfoelginger.map(
              (oppfoelging: IDisplayAndValue, index) => {
                return (
                  <SelectedFilter
                    key={oppfoelging.value}
                    marginRight={index >= filterParams.oppfoelginger.length - 1}
                    onClickRemove={() =>
                      dispatch(
                        uiActions.setFilterParams({
                          ...filterParams,
                          oppfoelginger: filterParams.oppfoelginger.filter(
                            ({ value }) => value !== oppfoelging.value
                          ),
                        })
                      )
                    }
                  >
                    {oppfoelging.display}
                  </SelectedFilter>
                )
              }
            )}
            {filterParams.begrunnelser.map(
              (begrunnelse: IDisplayAndValue, index) => {
                return (
                  <SelectedFilter
                    key={begrunnelse.value}
                    marginRight={index >= filterParams.begrunnelser.length - 1}
                    onClickRemove={() =>
                      dispatch(
                        uiActions.setFilterParams({
                          ...filterParams,
                          begrunnelser: filterParams.begrunnelser.filter(
                            ({ value }) => value !== begrunnelse.value
                          ),
                        })
                      )
                    }
                  >
                    {begrunnelse.display}
                  </SelectedFilter>
                )
              }
            )}
            {filterParams.aktiviteter.map(
              (aktivitet: IDisplayAndValue, index) => {
                return (
                  <SelectedFilter
                    key={aktivitet.value}
                    marginRight={index >= filterParams.aktiviteter.length - 1}
                    onClickRemove={() =>
                      dispatch(
                        uiActions.setFilterParams({
                          ...filterParams,
                          aktiviteter: filterParams.aktiviteter.filter(
                            ({ value }) => value !== aktivitet.value
                          ),
                        })
                      )
                    }
                  >
                    {aktivitet.display}
                  </SelectedFilter>
                )
              }
            )}
            {filterParams.mottattDatoFrom && (
              <SelectedFilter
                marginRight={!filterParams.mottattDatoTo}
                onClickRemove={() =>
                  dispatch(
                    uiActions.setFilterParams({
                      ...filterParams,
                      mottattDatoFrom: null,
                    })
                  )
                }
              >
                {`Fra: ${dayjs(filterParams.mottattDatoFrom).format(
                  'D. MMM YYYY'
                )}`}
              </SelectedFilter>
            )}
            {filterParams.mottattDatoTo && (
              <SelectedFilter
                marginRight
                onClickRemove={() =>
                  dispatch(
                    uiActions.setFilterParams({
                      ...filterParams,
                      mottattDatoTo: null,
                    })
                  )
                }
              >
                {`Til: ${dayjs(filterParams.mottattDatoTo).format(
                  'D. MMM YYYY'
                )}`}
              </SelectedFilter>
            )}
          </Row>
        </Column>

        <Row justify="flex-end" margin={[1, 1]}>
          <TextLink
            small
            onClick={() =>
              dispatch(uiActions.setFilterParams(initialFilterParams))
            }
          >
            Fjern alle filtre
          </TextLink>
        </Row>
      </Accordion>

      {isAvdelingFilterModalOpen && (
        <AvdelingerFilterModal
          isOpen={isAvdelingFilterModalOpen}
          onCancel={() => setIsAvdelingFilterModalOpen(false)}
          onSetAvdelingerFilter={(avdelinger) =>
            dispatch(uiActions.setFilterParams({ ...filterParams, avdelinger }))
          }
          avdelingerFilter={filterParams.avdelinger}
          accessType={accessType}
        />
      )}
      {isOppfoelgingFilterModalOpen && (
        <OppfoelgingFilterModal
          isOpen={isOppfoelgingFilterModalOpen}
          onCancel={() => setIsOppfoelgingFilterModalOpen(false)}
          filterParams={filterParams}
          setFilterParams={(params) =>
            dispatch(uiActions.setFilterParams(params))
          }
        />
      )}
      {isBegrunnelseFilterModalOpen && (
        <BegrunnelseFilterModal
          isOpen={isBegrunnelseFilterModalOpen}
          onCancel={() => setIsBegrunnelseFilterModalOpen(false)}
          filterParams={filterParams}
          setFilterParams={(params) =>
            dispatch(uiActions.setFilterParams(params))
          }
        />
      )}
      {isAktivitetFilterModalOpen && (
        <AktivitetFilterModal
          isOpen={isAktivitetFilterModalOpen}
          onCancel={() => setIsAktivitetFilterModalOpen(false)}
          filterParams={filterParams}
          setFilterParams={(params) =>
            dispatch(uiActions.setFilterParams(params))
          }
        />
      )}
      {isDatoFilterModalOpen && (
        <DatoFilterModal
          isOpen={isDatoFilterModalOpen}
          onCancel={() => setIsDatoFilterModalOpen(false)}
          filterParams={filterParams}
          setFilterParams={(params) =>
            dispatch(uiActions.setFilterParams(params))
          }
        />
      )}
    </ShadowBox>
  )
}
