import React, { useEffect, useMemo, useState } from 'react'
import { useDispatch } from 'react-redux'
import dayjs from 'dayjs'
import classNames from 'classnames'
import { useMediaQuery } from 'react-responsive'
import { IHeader } from '@mattilsynet/mt-ui/dist/components/table'
import { IMelding, IMeldingState } from '../../ducks/melding/types'
import { IMeldingsHistorikkState } from '../../ducks/meldingshistorikk/types'
import {
  Button,
  Column,
  ExpandArrow,
  Row,
  ShadowBox,
  TableCell,
  TableRow,
  Text,
  TextLink,
} from '@mattilsynet/mt-ui'
import { pushStateWithSource } from '../../common/common-router'
import {
  MeldingStatus,
  meldingStatusIsOneOf,
} from '../../ducks/melding/helpers'
import { getProdusentNummerFromProdusent } from '../../ducks/tilsynsobjekt/helpers'
import { orgenhetActions, orgenhetSelectors } from '../../ducks/orgenhet'
import { useTypedSelector } from '../../common/custom-hooks'
import { FordelTilModal } from '../../components/fordel-til-modal'

export const MeldingRow = ({
  index,
  melding,
  openRows,
  toggleOpenRow,
  tableHeader,
  allowedStatuses,
  allowedStatusBegrunnelser,
  accessType,
}: {
  index: number
  melding: IMelding
  openRows: IMeldingsHistorikkState['openRows']
  toggleOpenRow: (meldingId?: string) => void
  tableHeader: IHeader[]
  allowedStatuses: IMeldingState['allowedStatuses']
  allowedStatusBegrunnelser: IMeldingState['allowedStatusBegrunnelser']
  accessType: IMeldingsHistorikkState['accessType']
}) => {
  const dispatch = useDispatch()
  const isSmallScreen = useMediaQuery({ query: '(max-width: 1023px)' })
  const fordeltTilOrgenhet = useTypedSelector(
    orgenhetSelectors.getOrgenhetByUsername(melding.vurdering?.fordeltTil || '')
  )
  const [isFordelTilModalOpen, setIsFordelTilModalOpen] = useState(false)
  const fordeltTil = fordeltTilOrgenhet?.data?.name
  const tilsynsobjekt = melding?.tilsynsobjekt
  const showMore = openRows.some((id) => id === melding.meldingId)
  const observasjoner = melding.funnList
    ?.map?.((funn) => funn.observasjoner)
    .flat()
  const backgroundColor = index % 2 === 0 ? 'bg-gray' : 'bg-white'
  const meldingRef: { [key: string]: any } = useMemo(() => [], [])
  const [onMount, setOnMount] = useState(true)
  const scrollToMeldingId = new URLSearchParams(location.search).get(
    'scrollToMeldingId'
  )

  const goToMelding = (meldingId: string) => {
    dispatch(
      pushStateWithSource(`/melding/${meldingId}`, {
        scrollToMeldingId: meldingId,
      })
    )
  }

  const handleOpenFordelMeldingModal = () => {
    setIsFordelTilModalOpen(true)
  }

  useEffect(() => {
    if (
      onMount &&
      melding?.meldingId &&
      scrollToMeldingId === melding?.meldingId
    ) {
      const activeRef = meldingRef[melding?.meldingId || '']
      setOnMount(false)
      if (activeRef) {
        activeRef.scrollIntoView({
          block: 'center',
          inline: 'center',
        })
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [onMount, meldingRef, scrollToMeldingId, melding?.meldingId])

  useEffect(() => {
    if (melding.vurdering?.fordeltTil) {
      dispatch(orgenhetActions.fetchId(melding.vurdering?.fordeltTil))
    }
  }, [melding.vurdering?.fordeltTil, dispatch])

  return (
    <React.Fragment key={melding.meldingId}>
      <TableRow
        key={melding.meldingId}
        header={tableHeader.map((item) => String(item.display))}
        className={backgroundColor}
      >
        <TableCell>
          {dayjs(melding.sistRedigert).format('D. MMM YYYY')}
        </TableCell>
        <TableCell>
          <TextLink
            onClick={() =>
              dispatch(
                pushStateWithSource(
                  `/meldinger/${melding.produsentTilsynsobjektId}`
                )
              )
            }
          >
            {tilsynsobjekt?.virksomhetsNavn || ''}
          </TextLink>
        </TableCell>
        <TableCell>{getProdusentNummerFromProdusent(tilsynsobjekt)}</TableCell>
        <TableCell>{melding.aktivitet}</TableCell>

        <TableCell>
          <Column spacing={1}>
            {melding.kategorier.map((kategori) => (
              <Column key={kategori.kode} spacing={1}>
                <Text>{kategori.beskrivelse}</Text>
              </Column>
            ))}
          </Column>
        </TableCell>

        <TableCell>
          <Column spacing={2}>
            <Text>{allowedStatuses?.[melding?.vurdering?.status || '']}</Text>
          </Column>
        </TableCell>
        <TableCell>{fordeltTil}</TableCell>
        <TableCell>
          <Row>
            <TextLink onClick={() => toggleOpenRow(melding.meldingId)} small>
              {showMore ? 'Skjul detaljer' : 'Vis detaljer'}
            </TextLink>
            <ExpandArrow
              rotateDirection={showMore ? 'UP' : 'DOWN'}
              size="medium"
              onClick={() => toggleOpenRow(melding.meldingId)}
              cursor="pointer"
            />
          </Row>
        </TableCell>
      </TableRow>
      {showMore && (
        <TableRow
          className={classNames(backgroundColor, 'show-more-row')}
          header={[' ', ' ', ' ']}
        >
          <TableCell colSpan={2} className="show-more-content">
            <Column
              spacing={4}
              padding={[3, 3, 0, 3]}
              className="melding-box"
              ref={(ref) => (meldingRef[melding.meldingId || ''] = ref)}
            >
              <Column spacing={2}>
                <Text as="h3" weight="bold" size="heading3">
                  Melding
                </Text>
                <Text as="p">
                  {'"'}
                  {melding.begrunnelse}
                  {'"'}
                </Text>
              </Column>
              <Column spacing={3}>
                <Column spacing={1}>
                  <Text>Avsender: {melding.avsenderNavn}</Text>
                  <Text>
                    {melding.slakteriNavn} - Efta-nummer: {melding.eftanummer}
                  </Text>
                </Column>
                <Text>Meldingens saksnummer: {melding.saksnummer}</Text>
              </Column>
            </Column>
          </TableCell>
          <TableCell
            colSpan={4}
            className={classNames({ 'show-more-content': !isSmallScreen })}
          >
            <Column
              spacing={1}
              padding={isSmallScreen ? [3, 3, 0, 3] : [3, 3, 0, 0]}
            >
              <Text as="h4" weight="bold">
                Relevante funn
              </Text>
              <Column className="funn-box">
                <ShadowBox>
                  <Column padding={2}>
                    {observasjoner?.map((observasjon) => (
                      <Column spacing={1} key={observasjon?.observasjonId}>
                        <Text weight="bold">
                          {observasjon?.diagnose?.beskrivelse}
                        </Text>
                        <Text>{observasjon?.beskrivelse}</Text>
                      </Column>
                    ))}
                  </Column>
                </ShadowBox>
              </Column>
            </Column>
          </TableCell>
          <TableCell
            colSpan={2}
            className={classNames({ 'show-more-content': !isSmallScreen })}
          >
            <Column
              spacing={4}
              padding={isSmallScreen ? [3, 3] : [3, 3, 3, 0]}
              className="oppfoelging-box"
            >
              <Column spacing={2}>
                <Text as="h3" weight="bold" size="heading3">
                  Oppfølging
                </Text>
                <Text weight="bold">
                  {allowedStatuses?.[melding?.vurdering?.status || '']}
                </Text>
                {meldingStatusIsOneOf(melding?.vurdering?.status, [
                  MeldingStatus.NY,
                ]) &&
                  (melding.vurdering?.fordeltTil ? (
                    <Text>{fordeltTil}</Text>
                  ) : (
                    <Text fontStyle="italic">Ikke fordelt</Text>
                  ))}

                {melding?.vurdering?.statusBegrunnelse && (
                  <div>
                    <Text as="span" margin={[0, 0.5, 0, 0]}>
                      Begrunnelse:
                    </Text>
                    <Text as="span">
                      {allowedStatusBegrunnelser?.find(
                        (begrunnelse) =>
                          begrunnelse.status ===
                          melding?.vurdering?.statusBegrunnelse
                      )?.beskrivelse || melding?.vurdering?.statusBegrunnelse}
                    </Text>
                  </div>
                )}

                {melding?.tilsynskvitteringStatus && (
                  <Text>
                    Tilsynskvittering:{' '}
                    {melding?.tilsynskvitteringStatus?.status}
                  </Text>
                )}

                {!meldingStatusIsOneOf(melding?.vurdering?.status, [
                  MeldingStatus.NY,
                ]) && (
                  <Column spacing={1} padding={[2, 0, 0, 0]}>
                    <Column>
                      <Text>
                        Status satt:{' '}
                        {dayjs(melding?.vurdering?.sistRedigert).format(
                          'DD.MM.YY'
                        )}
                      </Text>
                      {melding?.vurdering?.tilknyttetSaksnummer && (
                        <Text>
                          Sak for oppfølging:{' '}
                          {melding?.vurdering?.tilknyttetSaksnummer}
                        </Text>
                      )}
                    </Column>
                    <Text>
                      Behandlet av: {melding?.vurdering?.sistRedigertAv}
                    </Text>
                  </Column>
                )}
              </Column>
              <Column spacing={2} padding={[2, 0, 0, 0]}>
                <Button secondary onClick={handleOpenFordelMeldingModal}>
                  Fordel melding
                </Button>
                <Button
                  secondary
                  arrow
                  onClick={() => goToMelding(String(melding.meldingId))}
                >
                  Vurder melding
                </Button>
              </Column>
            </Column>
          </TableCell>
        </TableRow>
      )}

      {isFordelTilModalOpen && (
        <FordelTilModal
          onCancel={() => setIsFordelTilModalOpen(false)}
          isOpen={isFordelTilModalOpen}
          accessType={accessType}
          vurdering={melding.vurdering}
          meldingId={melding.meldingId}
        />
      )}
    </React.Fragment>
  )
}
