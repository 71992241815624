import { push, replace, goBack as routerGoBack } from 'connected-react-router'
import { createQueryStringFromObject } from '@mattilsynet/mt-common/lib/utils/createQueryStringFromObject'

export const goBack = (fallBackPath = '/') => {
  const goBackPath = window?.history?.state?.state?.goBackPath
  const fromPath = window?.history?.state?.state?.fromPath
  const source = new URLSearchParams(location.search).get('source')

  if (source) {
    return push(source)
  }

  if (goBackPath?.includes('/login') || fromPath?.includes('/login')) {
    return push(fallBackPath)
  }

  if (goBackPath) {
    return push(goBackPath)
  }
  if (fromPath) {
    return routerGoBack()
  }
  return push(fallBackPath)
}

export const pushState = (path: string) =>
  push(path, { fromPath: window?.location?.pathname })

export const pushStateWithSource = (
  path: string,
  extraParams?: { [key: string]: string }
) => {
  const sourceQuery = createQueryStringFromObject('?')({
    ...(extraParams || {}),
  })
  const query = createQueryStringFromObject('?')({
    source: `${window?.location?.pathname}${sourceQuery}`,
  })
  return push(`${path}${query}`, { fromPath: window?.location?.pathname })
}

export const replaceState = (path: string) =>
  replace(path, { fromPath: window?.location?.pathname })

export const pushQuery = (query: string) =>
  push(`${window?.location?.pathname}?${query}`, {
    fromPath: window?.location?.pathname,
  })

export const replaceUrlQuery = (query: string) =>
  window.history.replaceState(
    null,
    '',
    `${window?.location?.pathname}?${query}`
  )
