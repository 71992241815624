import React from 'react'
import { Column, Header, Row, ShadowBox, Text } from '@mattilsynet/mt-ui'
import dayjs from 'dayjs'
import { getImageViewUrl, ImageThumbnail } from '../image-thumbnail'
import { pushState } from '../../common/common-router'
import { IAmFunn, IPmFunn } from '../../ducks/melding/types'
import { useDispatch } from 'react-redux'

export const FunnBox = ({ funn }: { funn: IAmFunn | IPmFunn }) => {
  const dispatch = useDispatch()

  return (
    <ShadowBox key={funn.funnId}>
      <Column spacing={2} padding={3}>
        <Column spacing={1}>
          <Header as="h3" size="heading3">
            Kontrolldato
          </Header>
          <Text>{dayjs(funn.kontrollDato).format('DD.MM.YY')}</Text>
        </Column>

        {funn.isAmFunn && (
          <Column spacing={1}>
            <Header as="h3" size="heading3">
              Individer
            </Header>
            {funn?.individer?.length && funn?.individer?.length === 1
              ? 'Funnet gjelder ett individ'
              : 'Funnet gjelder en gruppe individer'}
          </Column>
        )}

        <Column spacing={1}>
          <Header as="h3" size="heading3">
            Observasjon(er)
          </Header>
          <Column spacing={1} as="ul">
            {funn.observasjoner?.map((observasjon) => {
              return (
                <Column as="li" key={observasjon.observasjonId} spacing={1}>
                  <Text weight="bold">{observasjon.diagnose?.beskrivelse}</Text>
                  <Text>{observasjon.beskrivelse}</Text>
                  <Row wrap={true}>
                    {(observasjon?.bildeIds ?? []).map((id) => {
                      return (
                        <ImageThumbnail
                          key={id}
                          id={`${observasjon.observasjonId}_${id}`}
                          imageId={id}
                          preferredImageSize="small"
                          onClick={(id) =>
                            dispatch(pushState(getImageViewUrl(id)))
                          }
                        />
                      )
                    })}
                  </Row>
                </Column>
              )
            })}
          </Column>
        </Column>
      </Column>
    </ShadowBox>
  )
}
